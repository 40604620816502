import React, {useEffect, useState, useMemo, useRef, useLayoutEffect} from 'react'
import clsx from 'clsx'
import {useGlobalState, useGlobalMutation} from '../utils/container'
import useRouter from '../utils/use-router'
import RTCClient from '../rtc-client'
import StreamPlayer from './meeting/stream-player'
import StreamMenu from './meeting/stream-menu'
import { createChannel, createClient} from 'agora-rtm-react'
import { Avatar, AvatarGroup, Tooltip ,Popover, Slider, Typography,Modal, Chip, TextField} from '@mui/material'
import { Box, Button, FormControl, Input, InputLabel, MenuItem, Select,Checkbox, FormControlLabel} from '@material-ui/core'
import Fab from '@material-ui/core/Fab'
import SendIcon from '@material-ui/icons/Send'
import { blue} from '@material-ui/core/colors'
import {useSymbol} from '../hooks/useSymbol'
import {useFunction} from '../hooks/useFunction'
import { useTimer } from 'react-timer-hook';
import CancelIcon from '@material-ui/icons/Cancel';
import useSound from 'use-sound';
import LinearProgress from '@mui/material/LinearProgress';
import AgoraRTC from 'agora-rtc-sdk-ng'
import AgoraRTM from 'agora-rtm-sdk';
import { useKey } from "react-use";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import VirtualBackgroundExtension from "agora-extension-virtual-background";
import {makeStyles} from '@material-ui/core/styles'
import {
    Address,
    Deadline,
    PlainMessage,
    PublicAccount,
    TransactionHttp,
    TransferTransaction,
    UInt64,
    Mosaic,
    MosaicId,
    RepositoryFactoryHttp,
    Convert
  } from 'symbol-sdk'
import axios from 'axios'
import { Bars } from 'react-loader-spinner'

import {InputAdornment, IconButton} from '@material-ui/core'
import AttachFileIcon from '@material-ui/icons/AttachFile';

const useStyles = makeStyles((theme) => ({
    grid: {
        margin: '0 !important'
    },
    textFieldReadOnly: {
        maxWidth: '430px',
        minWidth: '430px',
        alignSelf: 'center'
    },
}))

const sendGiftWalletModalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    height: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const MeetingPage = () => {
    const classes = useStyles()
    const routerCtx = useRouter()
    const stateCtx = useGlobalState()
    const mutationCtx = useGlobalMutation()
    const [rtcToken, setRtcToken] = useState("")
    const [isSymbolSync, setIsSymbolSync] = useState(false)
    const [isEnableMenu, setIsEnableMenu] = useState(false)
    const [isGuestInvite, setIsGuestInvite] = useState(false)
    const [playWithdrawal] = useSound("ding.ogg");
    const [playDeposit] = useSound("ding2.ogg");
    const [menberJoin] = useSound("menber_join.mp3");
    const [isLoggedInRTM, setLoggedInRTM] = useState(false) //rtmのログインフラグ
    const [isLoggedInRTC, setLoggedInRTC] = useState(false) //rtcのログインフラグ
    const [accountList, setAccountList] = useState([]) //rtmのアカウントリスト
    const [firstAccountAmount, setFirstAccountAmount] = useState(0) //rtmのアカウントリスト
    const botIconUrl = 'bot.png' //ここはローカルのパスではなく、サーバーにデプロイした時のURLにしなくてはいけない
    const [isSymbolMetaInfo,setIsSymbolMetaInfo] = useState(false)
    const [isSymbolSerialsInfo,setIsSymbolSerialsInfo] = useState(false)

    const [processor, setProcessor] = useState(null)

    ////////////////////////////////SYMBOL////////////////////////////////////
    const {
        symbolUserMosaics,
        getMosaicsFromUser,
        checkMosaic,
        getTransactionFee,
        symbolTransactionFee,
        getUserBalance,
        symbolUserBalance,
        getMetaInfoDemo,symbolMetaInfo,
        getMosaicsSerials,symbolMosaicsSerials,
    } = useSymbol()

    const {
        powerFloat,
        objectToText,
        textToObject,
        copyTextToClipboard
    } = useFunction()

    useEffect(() => {
        setTimeout(() => {
            if(stateCtx.config.loginType==="SSS"){
                window.requestSSS()
                if (!window.SSS || stateCtx.config.userAddress === "" ) {
                    console.log('not installed')
                    routerCtx.history.push("/");
                } else {
                    console.log('installed')
                    getMosaicsFromUser(stateCtx.config.userAddress)
                    getTransactionFee()
                    getUserBalance(stateCtx.config.userAddress)
                }
            }else if(stateCtx.config.loginType==="ACCOUNT"){
                if (stateCtx.config.userAddress === "" ) {
                    console.log('not loggedin')
                    routerCtx.history.push("/");
                } else {
                    console.log('loggedin')
                    getMosaicsFromUser(stateCtx.config.userAddress)
                    getTransactionFee()
                    getUserBalance(stateCtx.config.userAddress)
                }
            }else{
                console.log('not loggedin')
                routerCtx.history.push("/");
            }
        }, 2000) // SSSのプログラムがwindowに挿入されるよりも後に実行するために遅らせる
    }, [])

    useEffect(() => {
        if(isSymbolSync){
            if(stateCtx.config.host) menberJoin()
        }
    }, [accountList])

    useEffect(() => {
        if(isSymbolSync && !stateCtx.config.demo){
            console.log("===isSymbolSync===")
            console.log(symbolMetaInfo)
            console.log(symbolUserMosaics)
            console.log(symbolMosaicsSerials)
            console.log(Date.now())

            // 予約時間が過ぎている場合
            if(symbolMetaInfo.data.ed < Date.now()){
                console.log("===over time===")
                mutationCtx.toastError(
                    `予約時間を過ぎているためトークンルームに入れません`
                )
                doLeave()
            }
            // モザイクの所有と予約確認
            if(stateCtx.config.host){
                if (stateCtx.config.userAddress!==symbolMetaInfo.data.owner) {
                    doLeave()
                }
            }else{
                if(!checkMosaic(symbolMetaInfo.data.mosaic,symbolUserMosaics)){
                    doLeave()
                }
            }
            // 人数制限の確認
            if(isLoggedInRTM){
                console.log(firstAccountAmount)
                console.log(symbolMetaInfo.data.amount)
                if(firstAccountAmount > symbolMetaInfo.data.amount){
                    console.log("===over amount===")
                    //人数オーバーーしちゃったのをメッセージ
                    let dateTime = new Date().toLocaleTimeString()
                    const obj = objectToText(msgObj(`${stateCtx.config.userAddress}が入室しましたが上限人数(${symbolMetaInfo.data.amount}人)を超えたため参加できませんでした。ホストは右上の「トップ画面を別タブで開く」から人数の予約変更を行い、変更できたら再度右上の「画面のリロード」ボタンを押し変更を反映させて下さい`,{chatTime:dateTime,userName:"bot",iconUrl:botIconUrl,textFont:{nameFontColor:"blue",msgFontSize:"10px"}}))
                    let message = client.createMessage({ text:obj, messageType: 'TEXT' })
                    rtmChannel.sendMessage(message)
                    mutationCtx.toastError(
                        `ルームの予約人数を超えたため、ホストが上限人数を変更するまでトークンルームに入る事ができません、`
                    )
                    setTimeout(() => doLeave(), 1000)
                }
            }
        }
    }, [isSymbolSync,isLoggedInRTM,firstAccountAmount])

    const [isRequest, setIsRequest] = useState(false)
    const [isGiftEnable, setIsGiftEnable] = useState(true) //XYMアイコンのenable
    const [isGiftMessage, setIsGiftMessage] = useState(false)

    useEffect(() => {
        if (isRequest) {
            const timerId = setTimeout(() => {
                if(infoText==="SSSで署名をして下さい"){
                    mutationCtx.toastError('1分経過してもSSSにて署名がされなかったので送金が無効になりました。')
                    setIsRequest(false)
                    setIsGiftEnable(true)
                    setInfoText("")
                }
            }, 61000)
            window.SSS.requestSign().then((signedTx) => {
                clearTimeout(timerId)
                console.log(timerId)
                new TransactionHttp(stateCtx.config.nodeUrl)
                .announce(signedTx)
                .subscribe(
                    (x) => {
                    console.log('x', x)
                    console.log(signedTx)
                    playWithdrawal()
                    mutationCtx.toastInfo('送金しました。相手に届くまで30秒以上お待ちください。')
                    setInfoText("着金するのを待っています")
                    subscribeTransaction(signedTx.hash)
                    setIsRequest(false)
                    },
                    (err) => {
                    console.error(err)
                    setIsRequest(false)
                    setIsGiftEnable(true)
                    }
                )
            })
        }
      }, [isRequest])

      const subscribeTransaction = (transactionHash)=>{
        const repositoryFactory = new RepositoryFactoryHttp(stateCtx.config.nodeUrl, {
            websocketUrl: `${stateCtx.config.nodeUrl.replace('http', 'ws')}/ws`,
            websocketInjected: WebSocket
            });
        const listener = repositoryFactory.createListener()
        listener.open().then(() => {
            listener.newBlock();
            //TODO ここをハッシュ値で検索するようにして、さらに一度送金したら連続で送金はできないようにしておく、つまりサインして着金するまでボタンを押せないようにする
            listener.confirmed(Address.createFromRawAddress(stateCtx.config.userAddress),transactionHash)
            .subscribe(tx => {
                console.log(tx)
                console.log(tx.message.payload)
                const message = tx.message.payload
                console.log(tx.recipientAddress.address)
                console.log(powerFloat(parseFloat(tx.mosaics[0].amount.toString()),-6))
                const recipientPrice = powerFloat(parseFloat(tx.mosaics[0].amount.toString()),-6)
                if(giftTextInput === "") sendChat(`${guestName[tx.recipientAddress.address]}さんへ${recipientPrice}xymを送りました`,"gift",recipientPrice)
                if(giftTextInput !== "") sendChat(`${guestName[tx.recipientAddress.address]}さんへ${recipientPrice}xymを送りました　💌メッセージ💌　${giftTextInput}`,"gift",recipientPrice)
                setIsGiftEnable(true)
                mutationCtx.toastSuccess('相手のウォレットに着金しました。')
                depositGift(tx.recipientAddress.address)

                const newGiftTotal = parseInt(recipientPrice) + giftTotal
                setGiftTotal(newGiftTotal)
                mutationCtx.updateConfig({giftTotal:newGiftTotal})
            
                if(stateCtx.config.host){
                    updateGiftCount(symbolMetaInfo.data.owner,newGiftTotal)
                }else{
                    sendUpdateGiftCount(newGiftTotal)
                }

                playDeposit()
                listener.close()
            });
        });
      }

      const submitSSS = (giftAddress) => {
        const publicKey = window.SSS.activePublicKey
        const tx = TransferTransaction.create(
          Deadline.create(1615853185),
          Address.createFromRawAddress(giftAddress),
          [new Mosaic(new MosaicId(stateCtx.config.xymAddress), UInt64.fromUint(price*Math.pow(10, 6)))],
          PlainMessage.create((isGiftMessage)?giftTextInput:""),
          stateCtx.config.networkType,
          UInt64.fromUint(feeMultiplier*transactionSize)
        )
        window.SSS.setTransaction(tx)

        setInfoText(`SSSで署名をして下さい`)
        setIsRequest(true)
        setIsGiftEnable(false)
      }

    ////////////////////////////////Timer////////////////////////////////////
    const [roomTime, setRoomTime] = useState(Date.now());
    const [isScheduleStart, setIsScheduleStart] = useState(false);

    let {
        seconds:roomSeconds,
        minutes:roomMinutes,
        hours:roomHours,
        days:roomDays,
        restart:roomRestart,
        } = useTimer({ roomTime ,autoStart:false,onExpire: () => {
            if(roomTime === symbolMetaInfo.data.st){
                setRoomTime(symbolMetaInfo.data.ed)
                setIsScheduleStart(true)
            }else{
                if(!stateCtx.config.demo) doLeave()
            }
        } });

    const [backgroundColor, setBackgroundColor] = useState("#000");

    useEffect(() => { //時間によって背景色を変える
        if(isSymbolSync){
            if(symbolMetaInfo.data.st > Date.now()){ //開始前
                if(roomDays===0 && roomHours===0 && roomMinutes===0 && roomSeconds<51){//51秒以内なら
                    let num = (51 - roomSeconds)*2
                    let hex = num.toString(16).padStart(2, '0')
                    setBackgroundColor(`#${hex}${hex}${hex}`)
                }
            }else if(symbolMetaInfo.data.ed > Date.now()){//開始中
                if(roomDays===0 && roomHours===0 && roomMinutes===0 && roomSeconds<51 && roomSeconds>0){//51秒以内なら
                    let num = (roomSeconds)*2
                    let hex = num.toString(16).padStart(2, '0')
                    setBackgroundColor(`#${hex}${hex}${hex}`)
                }else{
                    setBackgroundColor("#666666")
                }
            }else{//終了後
                setBackgroundColor("#666666")
            }
        }
    }, [roomSeconds])

    useEffect(() => { //初期時のtimerの値を決める処理
        if(isSymbolSync){
            if(symbolMetaInfo.data.st > Date.now()){
                setRoomTime(symbolMetaInfo.data.st)
                roomRestart(symbolMetaInfo.data.st)
            }else{
                setIsScheduleStart(true)
                setRoomTime(symbolMetaInfo.data.ed)
                roomRestart(symbolMetaInfo.data.ed)
            }
        }
    }, [isSymbolSync, isScheduleStart, roomTime])

    const [quality, setQuality] = useState(0)

    const checkSymbolSync = useMemo(() => {
        if(symbolUserMosaics.length>0 && !isSymbolMetaInfo){
            getMetaInfoDemo(stateCtx.config.channelName)
            setIsSymbolMetaInfo(true)
        }
        if(isSymbolMetaInfo && Object.keys(symbolMetaInfo).length>0 && !isSymbolSerialsInfo){
            console.log(symbolMetaInfo)
            console.log(symbolMetaInfo.id)
            console.log(symbolMetaInfo.data.title)
            console.log(symbolMetaInfo.data.st)
            console.log(symbolMetaInfo.data.ed)
            console.log(symbolMetaInfo.data.amount)
            console.log(symbolMetaInfo.data.mosaic)
            console.log(symbolMetaInfo.data.owner)
            console.log(symbolMetaInfo.data.quality)
            setQuality(symbolMetaInfo.data.quality)
            if(symbolMetaInfo.data.st > Date.now()){
                setRoomTime(symbolMetaInfo.data.st)
                roomRestart(symbolMetaInfo.data.st)
            }else{
                setRoomTime(symbolMetaInfo.data.ed)
                roomRestart(symbolMetaInfo.data.ed)
            }
            axios.get("https://us-central1-tokenlive-b98bd.cloudfunctions.net/generateRTCToken",{
                params: {
                    uid:stateCtx.config.userAddress,
                    channel:stateCtx.config.channelName,
                    expireTime:18000
                }
            }).then((res)=>{
                setRtcToken(res.data.rtcToken)
            })
            //予約モザイクかつ自分が持っているモザイクを抽出する
            let list = symbolUserMosaics.filter(x => symbolMetaInfo.data.mosaic.includes(x))
            getMosaicsSerials(stateCtx.config.userAddress,list)
            setIsSymbolSerialsInfo(true)
        }
        if(isSymbolSerialsInfo){
            setIsSymbolSync(true)
        }
    },[symbolUserMosaics,symbolMetaInfo,isSymbolMetaInfo,symbolMosaicsSerials,isSymbolSerialsInfo])

    const updateMetaInfo = () =>{
        setIsSymbolMetaInfo(false)
    }

    const updateGiftCount = (uid,giftTotal) =>{ //host task
        console.log("updateGiftCount")
        let tmp = giftCountList
        tmp[uid] = giftTotal
        mutationCtx.updateConfig({giftCountList:tmp})
        setGiftCountList(tmp)
        sendAllUpdateGiftCountList(tmp)
    }

    const updateGiftCountList = (list) =>{ //audience task
        console.log("updateGiftCountList")
        mutationCtx.updateConfig({giftCountList:list})
        setGiftCountList(list)
    }

    // const updateChatList = (list) =>{ //audience task
    //     console.log("updateChatList")
    //     mutationCtx.updateConfig({chatText:list})
    //     setTexts(list)
    // }

    const updateGuestName = (uid,name) =>{ //host task
        console.log("updateGuestName")
        let tmp = guestName
        tmp[uid] = name
        mutationCtx.updateConfig({guestName:tmp})
        setGuestName(tmp)
        sendAllUpdateGuestList(tmp)
    }

    const updateGuestList = (list) =>{ //audience task
        console.log("updateGuestList")
        mutationCtx.updateConfig({guestName:list})
        setGuestName(list)
    }

    ////////////////////////////////RTC////////////////////////////////////
    const onUserPublished = (remoteUser, mediaType) => {
        // remoteUser:
        // mediaType: "audio" | "video" | "all"
        console.log(`onUserPublished ${remoteUser.uid}, mediaType= ${mediaType}`)
        console.debug(`onUserPublished ${remoteUser.uid}, mediaType= ${mediaType}`)
        localClient.subscribe(remoteUser, mediaType)
            .then(mRemoteTrack => {
                addRemoteUser(remoteUser)
            })
            .catch(err => {
                mutationCtx.toastError(
                    `stream ${remoteUser.uid} subscribe failed: ${err}`
                )
            })
    }

    const onUserUnPublished = async (remoteUser, mediaType) => {
        // remoteUser:
        // mediaType: "audio" | "video" | "all"
        console.log(`onUserUnPublished ${remoteUser.uid}, mediaType= ${mediaType}`)
        console.debug(`onUserUnPublished ${remoteUser.uid}, mediaType= ${mediaType}`)
        console.log(remoteUser)
        setTimeout(() => {
            if (!remoteUser._video_added_ && !remoteUser._audio_added_){
                removeRemoteUser(remoteUser)
                // setMainBoxLayout(initialMainBoxLayout)
                // setSubBoxLayout(initialSubBoxLayout)
                }
        }, 500)
    }

    const localClient = useMemo(() => {
        const client = new RTCClient()
        client.createClient({codec: stateCtx.codec, mode: stateCtx.mode})

        // client.on('connection-state-change', mutationCtx.connectionStateChanged)
        client.on('user-published', onUserPublished)
        client.on('user-unpublished', onUserUnPublished)
        return client
    }, [stateCtx.codec, stateCtx.mode])

    const [volumeLevel, setVolumeLevel] = useState(0)
    useEffect(() => {
        if(localClient.mLocalAudioTrack && role){
            const timer = setInterval(() => {
                try{
                    const vol = localClient.mLocalAudioTrack.getVolumeLevel()
                    setVolumeLevel(vol)
                }catch(e){
                    console.log(e)
                }
            }, 500)
            return () => {
                clearInterval(timer)
            }
        }
    }, [localClient.mLocalAudioTrack])

    const [muteVideo, setMuteVideo] = useState(true)
    const [muteAudio, setMuteAudio] = useState(true)

    const [isShareScreen, setShareScreen] = useState(false)
    const [VideoTrack, setVideoTrack] = useState(null)
    const [AudioTrack, setAudioTrack] = useState(null)
    const [remoteUsers, setRemoteUsers] = useState({})

    const addRemoteUser = (remoteUser) => {
        remoteUsers[remoteUser.uid] = remoteUser
        setRemoteUsers(remoteUsers)
    }

    const removeRemoteUser = (remoteUser) => {
        delete remoteUsers[remoteUser.uid]
        setRemoteUsers(remoteUsers)
    }

    const [cameraList, setCameraList] = useState([])
    const [microphoneList, setMicrophoneList] = useState([])

    const config = useMemo(() => {
        return {
            token: stateCtx.config.token,
            channel: stateCtx.config.channelName,
            microphoneId: stateCtx.config.microphoneId,
            cameraId: stateCtx.config.cameraId,
            uid: stateCtx.config.uid,
            host: stateCtx.config.host
        }
    }, [stateCtx, muteVideo, muteAudio])

    const history = routerCtx.history

    const params = new URLSearchParams(window.location.search)

    useEffect(() => {
        const roleParams = params.get('role')
        if (!config.channel && roleParams !== 'audience') {
            history.push('/')
        }
    }, [config.channel, history, params])

    useEffect(() => {
        if(isSymbolSync && isScheduleStart && rtcToken !== ""){
            if (
                config.channel &&
                localClient._created &&
                localClient._joined === false &&
                localClient._leave === false
            ) {
                localClient.setEncryptionConfig()
                localClient.setClientRole(config.host ? 'host' : 'audience') //1は標準、2はデフォルトでプレミアム
                if(!stateCtx.config.demo){
                    localClient.join(config.channel,rtcToken,stateCtx.config.userAddress)
                    .then((uid) => {
                        config.uid = uid
                        if (config.host) {
                            localClient.startLive(config.microphoneId, config.cameraId,quality)
                                .then(() => {
                                    setVideoTrack(localClient.mLocalVideoTrack)
                                    setAudioTrack(localClient.mLocalAudioTrack)
                                })
                        }
                        mutationCtx.stopLoading()
                        setLoggedInRTC(true)
                        setIsEnableMenu(true)
                        roomRestart(symbolMetaInfo.data.ed)
                    })
                    .catch((err) => {
                        mutationCtx.toastError(`セッションが重複したためブラウザをリロードしします。`)
                        window.location.reload();
                    })
                }else{
                    localClient.startLiveDemo(config.microphoneId, config.cameraId,quality)
                    .then(() => {
                        setVideoTrack(localClient.mLocalVideoTrack)
                        setAudioTrack(localClient.mLocalAudioTrack)
                    })
                    mutationCtx.stopLoading()
                    setLoggedInRTC(true)
                    setIsEnableMenu(true)
                    localClient._joined = true
                }
            }
        }
    }, [localClient, mutationCtx, config, routerCtx,isSymbolSync,isScheduleStart,rtcToken])

    const toggleResolution = () => {
        const newValue = 1 - quality
        if(newValue === 0){
            VideoTrack.setEncoderConfiguration("360p_11")
        }else{
            VideoTrack.setEncoderConfiguration("1080p_2")
        }
        setQuality(newValue)
    }

    const toggleVideo = () => {
        const newValue = !muteVideo
        if(!stateCtx.config.demo){
            if (newValue) {
                localClient._client.unpublish(VideoTrack)
                // if(stateCtx.config.userAddress!==symbolMetaInfo.data.owner && muteAudio) roleChangeAudience()
            } else {
                localClient._client.publish(VideoTrack)
            }
        }
        setMuteVideo(newValue)
    }

    const toggleAudio = () => {
        const newValue = !muteAudio
        if(!stateCtx.config.demo){
            if (newValue) {
                localClient._client.unpublish(AudioTrack)
                // if(stateCtx.config.userAddress!==symbolMetaInfo.data.owner && muteVideo) roleChangeAudience()
            } else {
                localClient._client.publish(AudioTrack)
            }
        }
        setMuteAudio(newValue)
    }

    const toggleShareScreen = () => {
        const newValue = !isShareScreen
        if(!stateCtx.config.demo){
            setIsEnableMenu(false)
            if (newValue) {
                setShareScreen(newValue)
                localClient.stopLiveOnlyShareScrren()
                setProcessorPiped(false)
                localClient.startShareScrren(quality)
                    .then(() => {
                        setVideoTrack(localClient.mLocalVideoTrack)
                        setMuteVideo(false)
                    })
                    .catch((err) => {
                        mutationCtx.toastError(`画面の共有に失敗しました code= ${err.code}`)
                        localClient.startLiveOnlyShareScrren(config.cameraId,quality)
                        .then(() => {
                            setShareScreen(!newValue)
                            setVideoTrack(localClient.mLocalVideoTrack)
                            setMuteVideo(true)
                            setVirtualBackgroundEnabled(false)
                        })
                    }).finally(()=>{
                        setIsEnableMenu(true)
                    })
            } else {
                localClient.stopShareScrren()
                setProcessorPiped(false)
                localClient.startLiveOnlyShareScrren(config.cameraId,quality)
                    .then(() => {
                        setShareScreen(newValue)
                        setVideoTrack(localClient.mLocalVideoTrack)
                        setMuteVideo(true)
                    }).finally(async()=>{
                        setIsEnableMenu(true)
                        if(virtualBackgroundEnabled) await virtualBgStart()
                    })
            }
        }else{
            if (newValue) {
                setShareScreen(newValue)
                localClient.stopLiveOnlyShareScrrenDemo()
                setProcessorPiped(false)
                localClient.startShareScrrenDemo(quality)
                    .then(() => {
                        setVideoTrack(localClient.mLocalVideoTrack)
                        setMuteVideo(false)
                    })
                    .catch((err) => {
                        mutationCtx.toastError(`画面の共有に失敗しました code= ${err.code}`)
                        localClient.startLiveOnlyShareScrren(config.cameraId,quality)
                        .then(() => {
                            setShareScreen(!newValue)
                            setVideoTrack(localClient.mLocalVideoTrack)
                            setMuteVideo(true)
                            setVirtualBackgroundEnabled(false)
                        })
                    }).finally(()=>{
                        setIsEnableMenu(true)
                    })
            } else {
                localClient.stopShareScrrenDemo()
                setProcessorPiped(false)
                localClient.startLiveOnlyShareScrren(config.cameraId,quality)
                    .then(() => {
                        setShareScreen(newValue)
                        setVideoTrack(localClient.mLocalVideoTrack)
                        setMuteVideo(true)
                    }).finally(async()=>{
                        setIsEnableMenu(true)
                        if(virtualBackgroundEnabled) await virtualBgStart()
                    })
            }
        }

    }

    const doLeave = () => {
        if(isLoggedInRTM) rtmLogout()
        if(!stateCtx.config.demo){
            if(isLoggedInRTC){
                localClient.stopLive()
                localClient.leave().then(() => {
                    localClient.destroy()
                })
            }
        }else{
            if (localClient.audioEffectTrack) {
                localClient.audioEffectTrack.stop()
                localClient.audioEffectTrack.close()
                localClient.audioEffectTrack = null
            }
            if (localClient.audioMixingTrack) {
                localClient.audioMixingTrack.stop()
                localClient.audioMixingTrack.close()
                localClient.audioMixingTrack = null
            }
            if (localClient.mLocalAudioTrack) {
                localClient.mLocalAudioTrack.stop()
                localClient.mLocalAudioTrack.close()
                localClient.mLocalAudioTrack = null
            }
            if (localClient.mLocalVideoTrack) {
                localClient.mLocalVideoTrack.stop()
                localClient.mLocalVideoTrack.close()
                localClient.mLocalVideoTrack = null
            }
        }
        routerCtx.history.push('/index')
    }

    ////////////////////////////////RTM////////////////////////////////////
    const useRtmClient = useMemo(() => createClient(process.env.REACT_APP_AGORA_APP_ID), [])
    const demoChannel = `${Date.now()}`
    const useRtmChannel = useMemo(() => createChannel((!stateCtx.config.demo)?stateCtx.config.channelName:demoChannel), [])

    const client = useRtmClient()
    const rtmChannel = useRtmChannel(client)
    const [role, setRole] = useState(stateCtx.config.host)

    const [texts, setTexts] = useState((typeof stateCtx.config.chatText[stateCtx.config.channelName] !== "undefined")?stateCtx.config.chatText[stateCtx.config.channelName]:[])
    const [textInput, setTextInput] = useState('')
    const [giftTextInput, setGiftTextInput] = useState('')
    const [bgUrl, setBgUrl] = useState("")
    const [virtualBackgroundEnabled, setVirtualBackgroundEnabled] = useState(false)
    const [processorPiped, setProcessorPiped] = useState(false)
    const [iconUrl, setIconUrl] = useState(localStorage.getItem('iconUrl')??stateCtx.config.iconUrl)
    const [userName, setUserName] = useState((localStorage.getItem('userName')??stateCtx.config.guestName[stateCtx.config.userAddress])??stateCtx.config.userName)
    const [guestName, setGuestName] = useState(stateCtx.config.guestName)
    const [giftTotal, setGiftTotal] = useState(stateCtx.config.giftTotal)
    const [giftCountList, setGiftCountList] = useState(stateCtx.config.giftCountList)
    const [blockList, setBlockList] = useState([])
    const [blockAccount, setBlockAccount] = useState('')
    const [price, setPrice] = useState(10)
    const [feeMultiplier, SetFeeMultiplier] = useState(100)
    const [transactionSize, setTransactionSize] = useState(100)
    const [selectTransactionFee, setSelectTransactionFee] = useState(2)
    const [isCharge, setIsCharge] = useState(true)
    const [giftColorSelect, setGiftColorSelect] = useState(10)
    const [infoText, setInfoText] = useState("")
    const [isEnableReaction, setIsEnableReaction] = useState(true)
    const footRef = useRef(null);

    const giftColor =
    {
        10:{
            fontColor:"white",
            boxColor:"#1664C0",
            boxBorder:"2px solid #1664C0",
            className:""
        },
        20:{
            fontColor:"black",
            boxColor:"#00B8D3",
            boxBorder:"2px solid #00B8D3",
            className:""
        },
        30:{
            fontColor:"black",
            boxColor:"#01BFA5",
            boxBorder:"2px solid #01BFA5",
            className:""
        },
        40:{
            fontColor:"black",
            boxColor:"#FFB300",
            boxBorder:"2px solid #FFB300",
            className:""
        },
        50:{
            fontColor:"white",
            boxColor:"#E65000",
            boxBorder:"2px solid #E65000",
            className:""
        },
        100:{
            fontColor:"white",
            boxColor:"#C2175B",
            boxBorder:"2px solid #C2175B",
            className:""
        },
        200:{
            fontColor:"white",
            boxColor:"#D00101",
            boxBorder:"2px solid #D00101",
            className:""
        },
        300:{
            fontColor:"black",
            boxColor:"",
            boxBorder:"2px solid #757575",
            className:"silver"
        },
        400:{
            fontColor:"black",
            boxColor:"",
            boxBorder:"2px solid #b67b03",
            className:"gold"
        },
        500:{
            fontColor:"white",
            boxColor:"",
            boxBorder:"2px solid #212121",
            className:"black"
        },
        1000:{
            fontColor:"white",
            boxColor:"",
            boxBorder:"2px solid #800080",
            className:"symbol"
        },
    }

    const textFont =
    {
        nameFontColor:"gray",
        nameFontSize:"12px",
        nameFontWeight:"nomal",
        msgFontSize:"13px",
        msgFontColor:"black",
        msgFontWeight:"nomal",
        boxColor:"#efefef",
        boxBorder:"none",
        className:"",
        ribbon:false,
    }

    const msgObj = (text,changeObj) => {
        const msgObj = {
            text,
            type:"chat",
            textFont,
            userName,
            iconUrl,
            price,
            chatTime:"00:00",
            serial:symbolMosaicsSerials
        }

        for (let key in changeObj) {
            if(typeof changeObj[key] === "object"){
                for (let childKey in changeObj[key]) {
                    msgObj[key][childKey] = changeObj[key][childKey]
                }
            }else{
                msgObj[key] = changeObj[key]
            }
        }
        return msgObj
    }
    //初回時にRTMログイン処理
    useEffect(() => {
        console.log("===================LOGIN=====================")
        console.log(stateCtx.config.userAddress)
        AgoraRTM.setArea({ areaCodes: ["GLOBAL"], excludedArea: "CHINA" })
        AgoraRTC.setArea({
            areaCode:"GLOBAL",
            excludedArea:"CHINA"
        })
        rtmLogin(`${stateCtx.config.userAddress}`)
    }, [])

    useLayoutEffect(() => {
        if (!footRef.current) return;
        footRef.current.scrollIntoView();
    }, [texts]);

    const rtmLogin = async (uid) => {
        let rtmToken
        await axios.get("https://us-central1-tokenlive-b98bd.cloudfunctions.net/generateRTMToken",{
            params: {
                uid:stateCtx.config.userAddress,
                channel:(!stateCtx.config.demo)?stateCtx.config.channelName:demoChannel,
                expireTime:36000
            }
        }).then((res)=>{
            rtmToken = res.data.rtmToken
        })
        await client.login({ uid:uid , token:rtmToken})
        await rtmChannel.join()
        client.on('ConnectionStateChanged', async (state, reason) => {
            console.log('ConnectionStateChanged', state, reason)
        })
        //リクエストと応答に使う ★重要★ onしている時に、書き込みはできるけど、変数などの読み込みはできないっぽい
        client.on('MessageFromPeer', async (message, peerId) => {

            console.log(message, peerId)

            //HOST処理 FROM GUEST
            if(textToObject(message['text']).type==="cancelRoleChangeHost"){
                setIsGuestInvite(false)
                mutationCtx.toastError('招待が承認されませんでした')
            }
            if(textToObject(message['text']).type==="requestRoleChangeHost"){
                setIsGuestInvite(false)
                setGuestUser('') //これを入れていないと送った後にもう一度空想信できてしまう
                await client.sendMessageToPeer(
                    { text:objectToText(msgObj("roleChangeHost",{type:"roleChangeHost"})), messageType: 'TEXT' }, // An RtmMessage object.
                    peerId, // The uid of the remote user.
                )
            }
            if(textToObject(message['text']).type==="requestRoleChangeAudience"){
                await client.sendMessageToPeer(
                    { text:objectToText(msgObj("roleChangeAudience",{type:"roleChangeAudience"})), messageType: 'TEXT' }, // An RtmMessage object.
                    peerId, // The uid of the remote user.
                )
            }

            //HOST/Guest処理 FROM Audience
            if(textToObject(message['text']).type==="depositGift"){
                mutationCtx.toastSuccess('着金しました')
                playDeposit()
            }

            if(textToObject(message['text']).type==="updateGuestName"){
                updateGuestName(peerId,textToObject(message['text']).userName)
            }

            if(textToObject(message['text']).type==="updateGiftCount"){
                updateGiftCount(peerId, parseInt(textToObject(message['text']).text))
            }

            //GUEST処理 FROM HOST
            if(textToObject(message['text']).type==="offerCancelRoleChangeHost"){
                offerhandleClose()
            }
            if(textToObject(message['text']).type==="offerRoleChangeHost"){
                offerhandleOpen()
            }
            if(textToObject(message['text']).type==="offerRoleChangeAudience"){
                requestRoleChangeAudience(peerId)
            }
            if(textToObject(message['text']).type==="roleChangeHost"){
                roleChangeHost()
            }
            if(textToObject(message['text']).type==="roleChangeAudience"){
                roleChangeAudience()
            }
            if(textToObject(message['text']).type==="sendUpdateGuestList"){
                updateGuestList(textToObject(message['text']).text)
            }
            if(textToObject(message['text']).type==="sendUpdateGiftCountList"){
                updateGiftCountList(textToObject(message['text']).text)
            }
            // if(textToObject(message['text']).type==="sendUpdateChatList"){
            //     updateChatList(textToObject(message['text']).text)
            // }

        })

        rtmChannel.on('ChannelMessage', (msg, uid) => {
            if(textToObject(msg['text']).type==="chat"){
                setTexts((previous) => {
                    let text = [...previous, { msg, uid }]
                    mutationCtx.updateConfig({chatText:{[stateCtx.config.channelName]:text}})
                    return text
                })
            }
            if(textToObject(msg['text']).type==="updateMetaInfo"){
                updateMetaInfo()
            }
            if(textToObject(msg['text']).type==="sendAllUpdateGuestList"){
                updateGuestList(textToObject(msg['text']).text)
            }
            if(textToObject(msg['text']).type==="sendAllUpdateGiftCountList"){
                updateGiftCountList(textToObject(msg['text']).text)
            }
        })

        rtmChannel.on('MemberJoined', async (memberId) => {
            console.log('New Member: ', memberId)
            setAccountList(await rtmChannel.getMembers())
            if(stateCtx.config.host){
                setTexts((previous) => {
                    let dateTime = new Date().toLocaleTimeString()
                    let text = [...previous, { msg: { text:objectToText(msgObj(`${memberId}が参加しました`,{chatTime:dateTime,userName:"bot",iconUrl:botIconUrl,textFont:{nameFontColor:"blue",msgFontSize:"10px"}}))}, uid:"bot"}]
                    // mutationCtx.updateConfig({chatText:{[stateCtx.config.channelName]:text}})
                    return text
                })
            }
            if(role){ //誰かが参加した時にゲスト情報を更新する
                console.log("to member sendUpdateGuestList from host")
                sendUpdateGuestList((Object.keys(guestName).length>=Object.keys(stateCtx.config.guestName).length)?guestName:stateCtx.config.guestName,memberId)
                sendUpdateGiftCountList((Object.keys(giftCountList).length>=Object.keys(stateCtx.config.giftCountList).length)?giftCountList:stateCtx.config.giftCountList,memberId)
                // sendUpdateChatList((typeof stateCtx.config.chatText[stateCtx.config.channelName] !== "undefined")?stateCtx.config.chatText[stateCtx.config.channelName]:texts,memberId)
            }
        })
        rtmChannel.on('MemberLeft', async (memberId) => {
            console.log('Member Left: ', memberId)
            setAccountList(await rtmChannel.getMembers())
            if(stateCtx.config.host){
                setTexts((previous) => {
                    let dateTime = new Date().toLocaleTimeString()
                    let text = [...previous, { msg: { text:objectToText(msgObj(`${memberId}が退出しました`,{chatTime:dateTime,userName:"bot",iconUrl:botIconUrl,textFont:{nameFontColor:"blue",msgFontSize:"10px"}})) }, uid:"bot"}]
                    // mutationCtx.updateConfig({chatText:{[stateCtx.config.channelName]:text}})
                    return text
                })
            }
        })
        setLoggedInRTM(true)
        let newAccountList = await rtmChannel.getMembers()
        setAccountList(newAccountList)
        setFirstAccountAmount(newAccountList.length)

        if(role){ //ホストが後から入った場合
            console.log("to member updateGuestName from host")
            updateGuestName(stateCtx.config.userAddress,userName)
        }

        // setTexts((previous) => {
        //     let dateTime = new Date().toLocaleTimeString()
        //     let text = [...previous, { msg: { text:objectToText(msgObj(`ルームに入りました。コメントする時にはマナーに気をつけてご利用ください。また個人情報などプライバシーに関することは記載しないようにして下さい。`,{chatTime:dateTime,userName:"bot",iconUrl:botIconUrl,textFont:{nameFontColor:"blue",msgFontSize:"10px"}}))}, uid}]
        //     return text
        // })
        // setTexts((previous) => {
        //     let dateTime = new Date().toLocaleTimeString()
        //     let text = [...previous, { msg: { text:objectToText(msgObj(`画面左下の人のアイコンをクリックして、表示名や、表示アイコンを変更しましょう`,{chatTime:dateTime,userName:"bot",iconUrl:botIconUrl,textFont:{nameFontColor:"blue",msgFontSize:"10px"}}))}, uid}]
        //     return text
        // })
    }

    const rtmLogout = async () => {
        await rtmChannel.leave()
        await client.logout()
        rtmChannel.removeAllListeners()
        client.removeAllListeners()
        setLoggedInRTM(false)
    }

    const depositGift = async (uid) => {
        await client.sendMessageToPeer(
            { text:objectToText(msgObj("depositGift",{type:"depositGift"})), messageType: 'TEXT' }, // An RtmMessage object.
            uid, // The uid of the remote user.
        )
    }

    const cancelRoleChangeHost = async (uid) => {
        await client.sendMessageToPeer(
            { text:objectToText(msgObj("cancelRoleChangeHost",{type:"cancelRoleChangeHost"})), messageType: 'TEXT' }, // An RtmMessage object.
            uid, // The uid of the remote user.
        )
    }

    const requestRoleChangeAudience = async (uid) => {
        localClient.stopLive()
        await client.sendMessageToPeer(
            { text:objectToText(msgObj("requestRoleChangeAudience",{type:"requestRoleChangeAudience"})), messageType: 'TEXT' }, // An RtmMessage object.
            uid, // The uid of the remote user.
        )
    }

    const requestRoleChangeHost = async (uid) => {
        await client.sendMessageToPeer(
            { text:objectToText(msgObj("requestRoleChangeHost",{type:"requestRoleChangeHost"})), messageType: 'TEXT' }, // An RtmMessage object.
            uid, // The uid of the remote user.
        )
    }

    const roleChangeAudience = async () => {
        setTimeout(() => {
            setRole(false)
            localClient.setClientRole('audience')
            setMuteAudio(true)
            setMuteVideo(true)
            setShareScreen(false)
            setBgmState("SETTING")
            // setMainBoxLayout(initialMainBoxLayout)
            // setSubBoxLayout(initialSubBoxLayout)
            mutationCtx.toastInfo('配信を終了しました')
        }, 500)
    }

    const roleChangeHost = async () => {
        setRole(true)
        localClient.setClientRole('host')
        setTimeout(() => {
            // TODO 変数は読み込めない。対応を考える、暫定で0固定
            // localClient.startLive(config.microphoneId, config.cameraId,quality
            localClient.startLive(config.microphoneId, config.cameraId,stateCtx.config.quality)
            .then(() => {
                setVideoTrack(localClient.mLocalVideoTrack)
                setAudioTrack(localClient.mLocalAudioTrack)
                // setMainBoxLayout(initialSubBoxLayout)
                // setSubBoxLayout(initialMainBoxLayout)
                mutationCtx.toastInfo('配信に参加しました')
            })
        }, 500)
    }

    const offerCancelRoleChangeHost = async (uid) => {
        await client.sendMessageToPeer(
            { text:objectToText(msgObj("offerCancelRoleChangeHost",{type:"offerCancelRoleChangeHost"})), messageType: 'TEXT' }, // An RtmMessage object.
            uid, // The uid of the remote user.
        )
        setIsGuestInvite(false)
    }

    const offerRoleChangeHost = async (uid) => {
        await client.sendMessageToPeer(
            { text:objectToText(msgObj("offerRoleChangeHost",{type:"offerRoleChangeHost"})), messageType: 'TEXT' }, // An RtmMessage object.
            uid, // The uid of the remote user.
        )
    }

    const offerRoleChangeAudience  = async (uid) => {
        await client.sendMessageToPeer(
            { text:objectToText(msgObj("offerRoleChangeAudience",{type:"offerRoleChangeAudience"})), messageType: 'TEXT' }, // An RtmMessage object.
            uid, // The uid of the remote user.
        )
    }

    const sendUpdateMetaInfo = async () => {
        let message = client.createMessage({ text:objectToText(msgObj(`updateMetaInfo`,{type:"updateMetaInfo"})), messageType: 'TEXT' })
        await rtmChannel.sendMessage(message)
        updateMetaInfo()
    }

    const sendUpdateGiftCount = async (giftTotal) => {
        console.log("sendUpdateGiftCount")
        await client.sendMessageToPeer(
            { text:objectToText(msgObj(giftTotal,{type:"updateGiftCount"})), messageType: 'TEXT' }, // An RtmMessage object.
            symbolMetaInfo.data.owner, // The uid of the remote user.
        )
    }

    const sendUpdateGiftCountList = async (giftCountList,uid) => {
        console.log("sendUpdateGiftCountList")
        await client.sendMessageToPeer(
            { text:objectToText(msgObj(giftCountList,{type:"sendUpdateGiftCountList"})), messageType: 'TEXT' }, // An RtmMessage object.
            uid, // The uid of the remote user.
          )
    }

    // const sendUpdateChatList = async (chatList,uid) => {
    //     console.log("sendUpdateChatList")
    //     await client.sendMessageToPeer(
    //         { text:objectToText(msgObj(chatList,{type:"sendUpdateChatList"})), messageType: 'TEXT' }, // An RtmMessage object.
    //         uid, // The uid of the remote user.
    //       )
    // }


    const sendUpdateGuestName = async (name) => {
        console.log("sendUpdateGuestName")
        await client.sendMessageToPeer(
            { text:objectToText(msgObj(name,{type:"updateGuestName"})), messageType: 'TEXT' }, // An RtmMessage object.
            symbolMetaInfo.data.owner, // The uid of the remote user.
        )
    }

    const sendUpdateGuestList = async (guestList,uid) => {
        console.log("sendUpdateGuestList")
        await client.sendMessageToPeer(
            { text:objectToText(msgObj(guestList,{type:"sendUpdateGuestList"})), messageType: 'TEXT' }, // An RtmMessage object.
            uid, // The uid of the remote user.
          )
    }

    const sendAllUpdateGiftCountList = async (giftCountList) => {
        console.log("sendAllUpdateGiftCountList")
        let message = client.createMessage({ text:objectToText(msgObj(giftCountList,{type:"sendAllUpdateGiftCountList"})), messageType: 'TEXT' })
        await rtmChannel.sendMessage(message)
    }


    const sendAllUpdateGuestList = async (guestList) => {
        console.log("sendAllUpdateGuestList")
        let message = client.createMessage({ text:objectToText(msgObj(guestList,{type:"sendAllUpdateGuestList"})), messageType: 'TEXT' })
        await rtmChannel.sendMessage(message)
    }

    const sendChat = async (text,type,selectPrice) => {
        let textFont = {}
        if(role) textFont["nameFontWeight"]="bold"
        if(config.host) textFont["nameFontColor"]="red"
        if(!config.host && role) textFont["nameFontColor"]="green"
        if(type === "reaction") textFont["msgFontSize"]="30px"
        if(type === "gift"){
            textFont["msgFontWeight"]="bold"
            textFont["nameFontColor"]=giftColor[selectPrice].fontColor
            textFont["msgFontColor"]=giftColor[selectPrice].fontColor
            textFont["boxColor"]=giftColor[selectPrice].boxColor
            textFont["className"]=giftColor[selectPrice].className
            textFont["ribbon"]=true
        }
        let dateTime = new Date().toLocaleTimeString()
        const obj = objectToText(msgObj(text,{chatTime:dateTime,textFont:textFont}))
        let message = client.createMessage({ text:obj, messageType: 'TEXT' })
        await rtmChannel.sendMessage(message)
        setTexts((previous) => {
            let text = [...previous, { msg: { text:obj}, uid:stateCtx.config.userAddress}]
            mutationCtx.updateConfig({chatText:{[stateCtx.config.channelName]:text}})
            return text
        })
        setTextInput('')
    }

    const getGiftKing = (giftCountList) => {
        let arr = Object.keys(giftCountList).map(function (key) {return [key, giftCountList[key]];});
        arr.sort(function(a, b) {
            return b[1] - a[1];
        });
        return (arr.length>0)?arr[0][0]:""
    }

    //チャット入力制御
    const predicate = ({ ctrlKey, key }) => ctrlKey && key === "Enter";
    const enterKeyDownHandler = () => {
        if(textInput.length>0 && isLoggedInRTM){
            sendChat(textInput,"chat",price)
        }
    }
    useKey(predicate, enterKeyDownHandler, { event: "keydown" });

    //Popover関連
    const [copyAnchorEl, setCopyAnchorEl] = useState(null);
    const copyPopoverOpen = Boolean(copyAnchorEl);
    const copyPopoverid = copyPopoverOpen ? 'simple-popper' : undefined;

    const copyClick = (event) => {
        setCopyAnchorEl(copyAnchorEl ? null : event.currentTarget);
        copyTextToClipboard(giftAddress);
    }
    const copyClose = () => {
        setCopyAnchorEl(null);
    }
    const [blockListAnchorEl, setBlockListAnchorEl] = useState(null);
    const blockListPopoverOpen = Boolean(blockListAnchorEl);
    const blockListPopoverid = blockListPopoverOpen ? 'simple-popper' : undefined;
    const blockListClick = (event) => {
        setBlockListAnchorEl(blockListAnchorEl ? null : event.currentTarget);
    }
    const blockListClose = () => {
        setBlockListAnchorEl(null);
    }

    const [accountListAnchorEl, setAccountListAnchorEl] = useState(null);
    const accountListPopoverOpen = Boolean(accountListAnchorEl);
    const accountListPopoverid = accountListPopoverOpen ? 'simple-popper' : undefined;
    const accountListClick = (event) => {
        console.log(event.currentTarget)
        setAccountListAnchorEl(accountListAnchorEl ? null : event.currentTarget);
    }
    const accountListClose = () => {
        setAccountListAnchorEl(null);
    }

    const [profileAnchorEl, setProfileAnchorEl] = useState(null);
    const profilePopoverOpen = Boolean(profileAnchorEl);
    const profilePopoverid = profilePopoverOpen ? 'simple-popper' : undefined;
    const profileClick = (event) => {
        setProfileAnchorEl(profileAnchorEl ? null : event.currentTarget);
    }
    const profileClose = () => {
        if(stateCtx.config.host){
            updateGuestName(symbolMetaInfo.data.owner,localStorage.getItem('userName')??userName)
        }else if(role){
            sendUpdateGuestName(userName)
        }
        setProfileAnchorEl(null);
    }

    const [giftAnchorEl, setGiftAnchorEl] = useState(null);
    const giftPopoverOpen = Boolean(giftAnchorEl);
    const giftPopoverid = giftPopoverOpen ? 'simple-popper' : undefined;

    const [giftAddress, setGiftAddress] = useState('');
    const giftClick = (event) => {
        getUserBalance(stateCtx.config.userAddress)
        setGiftAddress(event.currentTarget.id)
        SetFeeMultiplier(symbolTransactionFee.medianFeeMultiplier)
        getTransactionSize(event.currentTarget.id)
        setGiftAnchorEl(giftAnchorEl ? null : event.currentTarget);
    }

    const giftClose = () => {
        // setGiftTextInput("")
        // setPrice(0)
        setGiftAnchorEl(null);
    }
    const valueToPrice = (value) => {
        if(value===1) setPrice(10)
        if(value===2) setPrice(20)
        if(value===3) setPrice(30)
        if(value===4) setPrice(40)
        if(value===5) setPrice(50)
        if(value===6) setPrice(100)
        if(value===7) setPrice(200)
        if(value===8) setPrice(300)
        if(value===9) setPrice(400)
        if(value===10) setPrice(500)
        if(value===11) setPrice(1000)

        if(price+Math.round(feeMultiplier*transactionSize*Math.pow(10, -6) * 1000000) / 1000000>symbolUserBalance){
            setIsCharge(false)
        }else{
            setIsCharge(true)
        }
        setGiftColorSelect(price)
    }

    const getTransactionSize = (giftAddress) =>{
        const tx = TransferTransaction.create(
            Deadline.create(1615853185),
            Address.createFromRawAddress(giftAddress),
            [new Mosaic(new MosaicId(stateCtx.config.xymAddress), UInt64.fromUint(price*Math.pow(10, 6)))],
            PlainMessage.create(giftTextInput),
            stateCtx.config.networkType
          )
        setTransactionSize(tx.size + 1)
    }
    const changeTransactionFee = (event) => {
        if(event.target.value===1){
            SetFeeMultiplier(symbolTransactionFee.minFeeMultiplier)
        }
        if(event.target.value===2){
            SetFeeMultiplier(symbolTransactionFee.medianFeeMultiplier)
        }
        if(event.target.value===3){
            SetFeeMultiplier(symbolTransactionFee.highestFeeMultiplier)
        }
        setSelectTransactionFee(event.target.value)
    }


    const sendGiftSSS = () => {
        submitSSS(giftAddress)
        alert("送金はまだ確定していません。SSSで内容を確認の上サインを行って下さい")
        giftClose()
    }

    const [isGiftWalletCancel, setIsGiftWalletCancel] = useState(false);

    const sendGiftWallet = () => {
        giftClose()
        handleOpenWalletModal()

        //ここで処理書いてまう
        setInfoText(`ウォレットから送金して下さい`)
        setIsGiftEnable(false)
        const repositoryFactory = new RepositoryFactoryHttp(stateCtx.config.nodeUrl, {
            websocketUrl: `${stateCtx.config.nodeUrl.replace('http', 'ws')}/ws`,
            websocketInjected: WebSocket
            });
        const listener = repositoryFactory.createListener()
        setIsGiftWalletCancel(false)
        listener.open().then(() => {
            if(isGiftWalletCancel === true) listener.close() //キャンセル時の不要なゴミトランザクションを検知しない処理
            listener.newBlock();
            listener.unconfirmedAdded(Address.createFromRawAddress(stateCtx.config.userAddress))
            .subscribe(tx => {
                console.log(tx)
                if(giftAddress === tx.recipientAddress.address){
                    playWithdrawal()
                    mutationCtx.toastInfo('送金しました。相手に届くまで30秒以上お待ちください。')
                    setInfoText("着金するのを待っています")
                    copyClose()
                    handleCloseWalletModal()
                }
            });
            listener.confirmed(Address.createFromRawAddress(stateCtx.config.userAddress))
            .subscribe(tx => {
                console.log(tx)
                if(giftAddress === tx.recipientAddress.address){
                    console.log(tx.message.payload)
                    const message = tx.message.payload
                    console.log(tx.recipientAddress.address)
                    console.log(powerFloat(parseFloat(tx.mosaics[0].amount.toString()),-6))
                    const recipientPrice = powerFloat(parseFloat(tx.mosaics[0].amount.toString()),-6)

                    //中途半端な価格にしない調整

                    let selectPrice = 10
                    if(recipientPrice < 20) selectPrice  = 10
                    if(recipientPrice >= 20 && recipientPrice < 30) selectPrice  = 20
                    if(recipientPrice >= 30 && recipientPrice < 40) selectPrice  = 30
                    if(recipientPrice >= 40 && recipientPrice < 50) selectPrice  = 40
                    if(recipientPrice >= 50 && recipientPrice < 100) selectPrice  = 50
                    if(recipientPrice >= 100 && recipientPrice < 200) selectPrice  = 100
                    if(recipientPrice >= 200 && recipientPrice < 300) selectPrice  = 200
                    if(recipientPrice >= 300 && recipientPrice < 400) selectPrice  = 300
                    if(recipientPrice >= 400 && recipientPrice < 500) selectPrice  = 400
                    if(recipientPrice >= 500 && recipientPrice < 1000) selectPrice  = 500
                    if(recipientPrice >= 1000) selectPrice  = 1000

                    if(giftTextInput === "") sendChat(`${guestName[tx.recipientAddress.address]}さんへ${recipientPrice}xymを送りました`,"gift",selectPrice)
                    if(giftTextInput !== "") sendChat(`${guestName[tx.recipientAddress.address]}さんへ${recipientPrice}xymを送りました　💌メッセージ💌　${giftTextInput}`,"gift",selectPrice)

                    setIsGiftEnable(true)
                    mutationCtx.toastSuccess('相手のウォレットに着金しました。')
                    depositGift(tx.recipientAddress.address)

                    const newGiftTotal = parseInt(recipientPrice) + giftTotal
                    setGiftTotal(newGiftTotal)
                    mutationCtx.updateConfig({giftTotal:newGiftTotal})
                    if(stateCtx.config.host){
                        updateGiftCount(symbolMetaInfo.data.owner,newGiftTotal)
                    }else{
                        sendUpdateGiftCount(newGiftTotal)
                    }
                    playDeposit()
                    listener.close()
                }
            });
        });
    }

    const [walletModal, setWalletModal] = useState(false);
    const handleOpenWalletModal = () => setWalletModal(true);
    const handleCloseWalletModal = () => setWalletModal(false);

    const boxLayout = [
        {
            id:0,
            className:"",
            toggleIcon:false,
            margin:"10px",
            top:"0px"
        },
        {
            id:1,
            className:"stream-container",
            toggleIcon:true,
            margin:"0px",
            top:"10px"
        },
        {
            id:2,
            className:"stream-container",
            toggleIcon:true,
            margin:"0px",
            top:"175px"
        },
        {
            id:3,
            className:"stream-container",
            toggleIcon:true,
            margin:"0px",
            top:"340px"
        },
    ]

    const [boxIdList, setBoxIdList] = useState([0,1,2,3]);

    const toggleStreemPlayer = (index) => {
        let list = [...boxIdList]
        list[boxIdList.indexOf(0)] = list[index]
        list[index] = 0
        setBoxIdList(list)
    }

    const [boxHideList, setBoxHideList] = useState([false,false,false,false]);

    const hideStreemPlayer = (index) => {
        let list = [...boxHideList]
        list[index] = true
        setBoxHideList(list)
    }

    const showStreemPlayer = (index) => {
        let list = [...boxHideList]
        list[index] = false
        setBoxHideList(list)
    }

    //モーダル関連
    const [bgmOpen, SetBgmOpen] = useState(false);
    const bgmhandleOpen = () => SetBgmOpen(true);
    const bgmhandleClose = () => SetBgmOpen(false);
    const [bgmFileName, setBgmFileName] = useState("");
    const [bgmFile, setBgmFile] = useState("");
    const [bgmState, setBgmState] = useState("SETTING"); //SETTING,STOP,PLAYING,PAUSE

    const [virtualBgOpen, SetVirtualBgOpen] = useState(false);
    const virtualBghandleOpen = () => SetVirtualBgOpen(true);
    const virtualBghandleClose = () => SetVirtualBgOpen(false);

    const virtualBgStop = async () => {
        await processor.disable()
        setVirtualBackgroundEnabled(false)
    }

    const virtualBgStart = async () => {
        const imgElement = document.createElement('img');
        imgElement.onload = async() => {
            if(!processorPiped){
                let denoiser = new VirtualBackgroundExtension();
                AgoraRTC.registerExtensions([denoiser]);
                let processor = denoiser.createProcessor()
                try{
                    await processor.init("wasms");
                }catch(error){
                    console.error(error);
                    setProcessor(null)
                }
                localClient.mLocalVideoTrack.pipe(processor).pipe(localClient.mLocalVideoTrack.processorDestination);
                setProcessorPiped(true)
                setProcessor(processor)
                try {
                    processor.setOptions({type: 'img', source: imgElement});
                    await processor.enable();
                  }catch(e){
                      console.log("こちらの画像はCROSSポリシーによって指定できませんでした。別の画像を指定して下さい")
                  }finally {
                  }
            }else{
                try {
                    processor.setOptions({type: 'img', source: imgElement});
                    await processor.enable();
                  }catch(e){
                      console.log("こちらの画像はCROSSポリシーによって指定できませんでした。別の画像を指定して下さい")
                  }finally {
                  }
            }
        }
        imgElement.src = bgUrl
        imgElement.style="display:none;width:10px;height:10px;"
        imgElement.crossOrigin = "anonymous"
        setVirtualBackgroundEnabled(true)
    }

    const [offerOpen, SetOfferOpen] = useState(false);
    const offerhandleOpen = () => SetOfferOpen(true);
    const offerhandleClose = () => SetOfferOpen(false);

    const [selectOpen, SetSelectOpen] = useState(false);
    const selecthandleOpen = () => SetSelectOpen(true);
    const selecthandleClose = () => SetSelectOpen(false);
    const [guestUser, setGuestUser] = useState('');

    const selectChange = (event) => {
        setGuestUser(event.target.value)
    }

    const [deviceOpen, SetDeviceOpen] = useState(false);

    const [preTracks, setPreTracks] = useState(
        {
            videoTrack: null,
            audioTrack: null
        }
    )
    const devicehandleOpen = async () => {
        setIsEnableMenu(false)
        await Promise.all([
            localClient.getDevices().then((datas) => {
                mutationCtx.setDevicesList(datas)
                AgoraRTC.createMicrophoneAudioTrack({microphoneId: stateCtx.config.microphoneId})
                .then((micTrack)=>{
                    setPreTracks({audioTrack: micTrack})
                    if(stateCtx.config.cameraId!==""){
                        AgoraRTC.createCameraVideoTrack({cameraId: stateCtx.config.cameraId})
                        .then((CameraTrack)=>{
                            setPreTracks(
                                {
                                    videoTrack: CameraTrack,
                                    audioTrack: micTrack
                                }
                            )
                            CameraTrack.play("local-player")
                        })
                    }else{
                        setPreTracks(
                            {
                                audioTrack: micTrack
                            }
                        )
                    }
                })
            })
        ]).then((tracks) => {
            console.log("OK")
        }).finally(()=>{
            setIsEnableMenu(true)
        })
        SetDeviceOpen(true);
    }
    const devicehandleClose = () => {
        setIsEnableMenu(false)
        if(preTracks.videoTrack){
            preTracks.videoTrack.stop()
            preTracks.videoTrack.close()
            preTracks.videoTrack = null;
        }
        if(preTracks.audioTrack){
            preTracks.audioTrack.stop()
            preTracks.audioTrack.close()
            preTracks.audioTrack = null;
        }
        setIsEnableMenu(true)
        SetDeviceOpen(false);
    }

      return (
        <>
        {(isSymbolSync && isLoggedInRTM)?
        <>
        <Modal
            open={deviceOpen}
            aria-labelledby="modal-device-title"
            aria-describedby="modal-device-description"
        >
            <Box
                    style={{ position:'absolute',top:'3%',left:'48%'}}
                    sx={{ display: 'flex',flex: '1',margin:20, alignItems:"center" , flexDirection:'column',height:400 , width:350 , border: 1, p: 3, bgcolor: 'background.paper'}}
            >
                    <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">マイクの選択</InputLabel>
                    <Select
                        value={stateCtx.config.microphoneId}
                        label="microphone"
                        onChange={(evt) => {
                            mutationCtx.updateConfig({
                                microphoneId: evt.target.value
                            })
                            preTracks.audioTrack.setDevice(evt.target.value)
                        }}
                    >
                        {microphoneList.map((microphone,i)=>
                            <MenuItem key={`microphone-${i}`} value={microphone.value}>{microphone.label}</MenuItem>
                        )}
                    </Select>
                    </FormControl>
                    <br/>
                    <Box display={"flex"} width={"350px"} alignItems={"center"} justifyContent={"space-around"}>
                        <Box sx={{ width: '75%' }}>
                            <LinearProgress className={'progress-bar'} variant="determinate" value={(preTracks.audioTrack)?preTracks.audioTrack.getVolumeLevel() * 100:0} />
                        </Box>
                        <Box sx={{ width: '25%' }} display={"flex"} justifyContent={"center"}>
                            <Typography variant="caption" component="div" gutterBottom>
                                {(!preTracks.audioTrack)?"":(preTracks.audioTrack.getVolumeLevel()>0)?"マイクOK":"-"}
                            </Typography>
                        </Box>
                    </Box>
                    <br/>
                    <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">カメラの選択</InputLabel>
                    <Select
                        value={stateCtx.config.cameraId}
                        label="camera"
                        onChange={(evt) => {
                            mutationCtx.updateConfig({
                                cameraId: evt.target.value
                            })
                            preTracks.videoTrack.setDevice(evt.target.value)
                        }}
                    >
                        {cameraList.map((camera,i)=>
                            <MenuItem key={`camera-${i}`} value={camera.value}>{camera.label}</MenuItem>
                        )}
                    </Select>
                    </FormControl>
                    <br/>
                    <Box
                        className={'local-player'}
                        id={`local-player`}
                    >
                    </Box>
                    <Box display={"flex"}>
                        {(isEnableMenu)?
                            <Box display={"flex"} justifyContent={"space-around"}>
                                {(!isShareScreen)?<>
                                    <Button onClick={()=>{
                                    localClient.mLocalAudioTrack.setDevice(stateCtx.config.microphoneId)
                                    if(stateCtx.config.cameraId!==""){
                                        if(VideoTrack){
                                            localClient.mLocalVideoTrack.setDevice(stateCtx.config.cameraId)
                                        }else{
                                            AgoraRTC.createCameraVideoTrack({cameraId: stateCtx.config.cameraId ,encoderConfig:(quality===0)?"360p_11":(quality===1)?"720p_2":"1080p_2"})
                                            .then((track) => {
                                                localClient.mLocalVideoTrack = track
                                                setVideoTrack(localClient.mLocalVideoTrack)
                                            })
                                        }

                                    }
                                    devicehandleClose()
                                }}>
                                    変更する
                                </Button>
                                </>:<></>}
                                <Button onClick={()=>{
                                    devicehandleClose()
                                }}>
                                    閉じる
                                </Button>
                            </Box>
                        :
                            <Button onClick={()=>{
                                devicehandleClose()
                            }}>
                                閉じる
                            </Button>
                        }
                    </Box>
            </Box>
        </Modal>
        <Modal
            open={walletModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={sendGiftWalletModalStyle}>
                <Typography variant="body2" component="div" gutterBottom>ウォレットアプリを開き以下条件で送金して下さい</Typography>
                <Box mt={5}>
                    <FormControl className={clsx(classes.textFieldReadOnly, classes.grid)}>
                        <TextField
                            InputProps={{
                                readonly: true,
                                style: { fontSize: 13 },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton >
                                            <AttachFileIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                }}
                            label="宛先アドレス"
                            placeholder='宛先アドレス'
                            value={giftAddress}
                            onClick={copyClick}
                        />
                    </FormControl>
                </Box>
                <Box mt={2}>
                    <FormControl className={clsx(classes.textFieldReadOnly, classes.grid)}>
                        <TextField
                            inputProps={{ readonly: true }}
                            label="送金数量"
                            placeholder='送金数量'
                            value={price}
                        />
                    </FormControl>
                </Box>
                <Box mt={2} mb={5}>
                    <FormControl className={clsx(classes.textFieldReadOnly, classes.grid)}>
                        <TextField
                            inputProps={{ readonly: true }}
                            label="トランザクション手数料"
                            placeholder='トランザクション手数料'
                            value={"任意"}
                        />
                    </FormControl>
                </Box>
                <Typography variant="body2" component="div" gutterBottom>*COMSAウォレットやArcanaなどから送る事ができます。</Typography>
                <Typography variant="body2" component="div" gutterBottom color={"red"} fontWeight={"bold"}>*２分以内に送金が完了しない場合はやり直して下さい。</Typography>

                <Box mt={7} display="flex" justifyContent="center">
                        <Button color='secondary' variant="contained" onClick={()=>{
                            setIsGiftWalletCancel(true)
                            copyClose()
                            handleCloseWalletModal()
                            setInfoText("")
                        }}>
                            送金を中止する
                        </Button>
                </Box>

            </Box>
        </Modal>
        <Modal
            open={bgmOpen}
            aria-labelledby="modal-modal-bgmOpen"
            aria-describedby="modal-modal-description"
        >
            <Box 
                style={{ position:'absolute',top:'50%',left:'50%'}}
                sx={{ display: 'flex',flex: '1',margin:20, alignItems:"center" , flexDirection:'column',height: 200 , width:400 , border: 1, p: 3, bgcolor: 'background.paper'}}
            >
                <Typography variant="body2" component="div" gutterBottom>
                    BGMを選択して下さい
                </Typography>
                <br/>
                <label htmlFor="contained-button-file">
                <Input
                    accept="audio/*"
                    id="contained-button-file"
                    multiple type="file"
                    style={{ display:'none'}}
                    onChange={async(evt) => {
                        setBgmFile(evt.target.files[0])
                        setBgmFileName(evt.target.files[0].name)
                        localClient.audioMixingTrack = await AgoraRTC.createBufferSourceAudioTrack({ source: evt.target.files[0]})
                        setBgmState("STOP")
                        bgmhandleClose()
                    }}
                />
                <Button  variant="contained" component="span">
                    ファイルを選ぶ
                </Button>
                </label>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>
                    {`${bgmFileName}`}
                </Typography>
                <br/>
                <Box display={"flex"}>
                    <Button
                    
                    onClick={()=>{
                        bgmhandleClose()
                    }}>
                        閉じる
                    </Button>
                </Box>
            </Box>
        </Modal>

        <Modal
            open={virtualBgOpen}
            aria-labelledby="modal-modal-virtualBgOpen"
            aria-describedby="modal-modal-description"
        >
            <Box 
                style={{ position:'absolute',top:'50%',left:'50%'}}
                sx={{ display: 'flex',flex: '1',margin:20, alignItems:"center" , flexDirection:'column',height: 200 , width:400 , border: 1, p: 3, bgcolor: 'background.paper'}}
            >
                <Typography variant="body" component="div" gutterBottom>
                    背景にする画像URLを指定して下さい
                </Typography>
                <Input
                    placeholder="(例)https://exsample.com/photo.jpg"
                    style={{width: '100%'}}
                    type='text'
                    fullWidth
                    value={bgUrl}
                    onChange={(evt) => {
                        const PATTERN = /[0-9]{0,201}/
                        const value = PATTERN.test(evt.target.value)
                        if (value && evt.target.value.length < 201) {
                            setBgUrl(evt.target.value)
                        } else {
                            setBgUrl(bgUrl)
                        }
                    }}
                />
                <br/>
                <Typography variant="caption" component="div" gutterBottom>
                    *URLによっては画像が背景に反映されない場合があります。Twitter上の画像であれば確実に反映されます。
                </Typography>
                <Typography variant="caption" component="div" gutterBottom>
                    *スペックの低いパソコンではバーチャル背景が使えない場合があります。
                </Typography>
                <br/>
                <Box display={"flex"}>
                <Button onClick={async()=>{
                    await virtualBgStart()
                    virtualBghandleClose()
                }}>
                    変更する
                </Button>
                <Button onClick={()=>{
                    virtualBghandleClose()
                }}>
                    閉じる
                </Button>
                </Box>

            </Box>
        </Modal>

        <Modal
            open={offerOpen}
            aria-labelledby="modal-modal-offerOpen"
            aria-describedby="modal-modal-description"
        >
            <Box 
                style={{ position:'absolute',top:'50%',left:'50%'}}
                sx={{ display: 'flex',flex: '1',margin:20, alignItems:"center" , flexDirection:'column',height: 150 , width:300 , border: 1, p: 3, bgcolor: 'background.paper'}}
            >
                <Typography variant="body2" component="div" gutterBottom>
                    ホストからオファーが届きました
                </Typography>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>
                    配信に参加しますか？
                </Typography>
                <br/>
                <Box display={"flex"}>
                    <Button
                    onClick={()=>{
                        requestRoleChangeHost(symbolMetaInfo.data.owner)
                        sendUpdateGuestName(userName)
                        offerhandleClose()
                    }}>
                        参加します
                    </Button>
                    <Button onClick={()=>{
                        cancelRoleChangeHost(symbolMetaInfo.data.owner)
                        offerhandleClose()
                    }}>
                        参加しません
                    </Button>
                </Box>
            </Box>
        </Modal>
        <Modal
            open={selectOpen}
            aria-labelledby="modal-modal-selectOpen"
            aria-describedby="modal-modal-description"
        >
            <Box 
                style={{ position:'absolute',top:'50%',left:'50%'}}
                sx={{ display: 'flex',flex: '1',margin:20, alignItems:"center" , flexDirection:'column',height:250 , width:350 , border: 1, p: 3, bgcolor: 'background.paper'}}
            >
                <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">配信に参加するアドレスを選択して下さい</InputLabel>
                <Select
                    value={guestUser}
                    label="guestUser"
                    onChange={selectChange}
                >
                    {accountList.filter(address => address !== symbolMetaInfo.data.owner && !Object.keys(remoteUsers).includes(address)).map((uid,i)=>
                        <MenuItem key={`menuitemkey-${i}`} value={uid}>{uid}</MenuItem>
                    )}
                </Select>
                </FormControl>
                <br/>
                <Box display={"flex"}>
                    <Button
                    
                    onClick={()=>{
                        if(guestUser!==''){
                            offerRoleChangeHost(guestUser)
                            setIsGuestInvite(true)
                            selecthandleClose()
                            mutationCtx.toastInfo(`招待を送りました`)
                        }else{
                            mutationCtx.toastError(`配信に参加するアドレスを選択して下さい`)
                        }
                    }}>
                        招待する
                    </Button>
                    <Button onClick={()=>{
                        setGuestUser('')
                        selecthandleClose()
                    }}>
                        閉じる
                    </Button>
                </Box>
            </Box>
        </Modal>
        <Popover
            id={blockListPopoverid}
            open={blockListPopoverOpen}
            onClose={blockListClose}
            anchorEl={blockListAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
        >
            <Box sx={{ display: 'flex',flex: '1',margin:20, alignItems:"center" , flexDirection:'column',height: 50 , width:250 , border: 1, p: 3, bgcolor: 'background.paper'}}>
                    <Button onClick={()=>{
                        console.log(blockAccount)
                        setBlockList([...blockList,blockAccount])
                        blockListClose()
                    }}>
                        このユーザーを非表示にする
                    </Button>
                    <Button onClick={()=>{
                        blockListClose()
                    }}>
                        閉じる
                    </Button>

            </Box>
        </Popover>
        <Popover
            id={accountListPopoverid}
            open={accountListPopoverOpen}
            onClose={accountListClose}
            anchorEl={accountListAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
        >
            <Box sx={{ display: 'flex',flex: '1',margin:20, alignItems:"center" , flexDirection:'column',height: 150 , width:500 , border: 1, p: 3, bgcolor: 'background.paper'}}>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: "490px"},
                }}
                noValidate
                autoComplete="off"
            >
                <TextField
                    label="参加者一覧"
                    multiline
                    rows={5}
                    defaultValue={
                        accountList.join('\n')
                    }
                />
            </Box>
                <Button onClick={()=>{
                    accountListClose()
                }}>
                    閉じる
                </Button>
            </Box>
        </Popover>
        <Popover
            id={profilePopoverid}
            open={profilePopoverOpen}
            anchorEl={profileAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
        >
            <Box sx={{ display: 'flex',flex: '1',margin:20, alignItems:"center" , flexDirection:'column',height: 150 , width:300 , border: 1, p: 3, bgcolor: 'background.paper'}}>
                <Typography variant="caption" component="div" gutterBottom>
                    表示名を入力して下さい（20文字まで）
                </Typography>
                <Input
                    placeholder='表示名を入力して下さい（20文字まで）'
                    style={{width: '100%'}}
                    type='text'
                    fullWidth
                    value={userName}
                    onChange={(evt) => {
                        const PATTERN = /[0-9]{0,21}/
                        const value = PATTERN.test(evt.target.value)
                        if (value && evt.target.value.length < 21) {
                            setUserName(evt.target.value)
                            mutationCtx.updateConfig({
                                userName: evt.target.value
                            })
                            localStorage.setItem('userName', evt.target.value);
                        } else {
                            setUserName(userName)
                        }
                    }}
                />
                <br/>
                <Typography variant="caption" component="div" gutterBottom>
                    プロフィールの画像URLを指定して下さい
                </Typography>
                <Input
                    placeholder="(例)https://exsample.com/photo.jpg"
                    style={{width: '100%'}}
                    type='text'
                    fullWidth
                    value={iconUrl}
                    onChange={(evt) => {
                        const PATTERN = /[0-9]{0,201}/
                        const value = PATTERN.test(evt.target.value)
                        if (value && evt.target.value.length < 201) {
                            setIconUrl(evt.target.value)
                            mutationCtx.updateConfig({
                                iconUrl: evt.target.value
                            })
                            localStorage.setItem('iconUrl', evt.target.value);
                        } else {
                            setIconUrl(iconUrl)
                        }
                    }}
                />
                <br/>
                <Button onClick={()=>{
                    profileClose()
                }}>
                    変更する
                </Button>
            </Box>
        </Popover>
        <Popover
            id={giftPopoverid}
            open={giftPopoverOpen}
            onClose={giftClose}
            anchorEl={giftAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
        >
            <Box sx={{ display: 'flex',flex: '1',margin:20, alignItems:"center" , flexDirection:'column',height: 450 , width:320 , border: 1, p: 3, bgcolor: 'background.paper'}}>
                <Box
                    color={giftColor[giftColorSelect].fontColor}
                    bgcolor={giftColor[giftColorSelect].boxColor}
                    className={`${giftColor[giftColorSelect].className} ribbon-wrapper`}
                    borderRadius={5}
                    sx={{ display: 'flex', justifyContent:"center", flexDirection:'column', alignItems:"center", padding:"10px", margin:"10px" ,width:320,height: 150}}
                >
                    <Box className="ribbon-content">
                        <span className="ribbon">Gift</span>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent:"center", alignItems:"center"}} marginBottom={0}>
                        <Typography display="flex" alignItems="center" variant="h2" component="div" gutterBottom>
                            {price}
                        </Typography>
                        <Typography display="flex" alignItems="center" variant="h4" component="div" gutterBottom>
                            xym
                        </Typography>
                    </Box>
                </Box>
                <Typography variant="caption" component="div" gutterBottom>
                    {`※別途トランザクション手数料がかかります`}
                </Typography>
                <Slider
                    aria-label="send Host xym"
                    defaultValue={1}
                    getAriaValueText={valueToPrice}
                    step={1}
                    marks
                    min={1}
                    max={11}
                />
                {(stateCtx.config.loginType==="SSS")?
                <FormControl fullWidth>
                <InputLabel>トランザクション手数料　{Math.round(feeMultiplier*transactionSize*Math.pow(10, -6) * 1000000) / 1000000}xym</InputLabel>
                <Select
                value={selectTransactionFee}
                label="TransactionFee"
                onChange={changeTransactionFee}
                >
                    <MenuItem value={1}>遅い</MenuItem>
                    <MenuItem value={2}>普通</MenuItem>
                    <MenuItem value={3}>早い</MenuItem>
                </Select>
                </FormControl>
                :<></>
                }
                <Box
                    marginTop={3}
                    marginBottom={3}
                    width={280}
                >
                    <Input
                        autoFocus={true}
                        multiline={true}
                        placeholder="メッセージを入力（200文字まで）"
                        style={{width: '100%'}}
                        type='text'
                        fullWidth
                        value={giftTextInput}
                        onChange={(evt) => {
                            getTransactionSize(giftAddress)
                            const PATTERN = /[0-9]{0,201}/
                            const value = PATTERN.test(evt.target.value)
                            if (value && evt.target.value.length < 201) {
                                setGiftTextInput(evt.target.value)
                            } else {
                                setGiftTextInput(giftTextInput)
                            }
                        }}
                    />
                </Box>
                {(stateCtx.config.loginType==="SSS")?
                <FormControlLabel control={<Checkbox
                    checked={isGiftMessage}
                    onChange={()=>{
                        setIsGiftMessage(!isGiftMessage)
                    }}
                />} label={<Typography variant="caption" component="div" gutterBottom>ブロックチェーンにメッセージを記録する</Typography>} />
                :<></>
                }
                {(isCharge)?
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={(stateCtx.config.loginType==="SSS")?sendGiftSSS:sendGiftWallet}
                    >
                        {(stateCtx.config.loginType==="SSS")?"XYMを送る":"ウォレットからXYMを送る"}
                    </Button>
                :
                    <Button
                        disabled
                    >
                        残高が足りません
                    </Button>
                }
                <Box
                    sx={{ display: 'flex', justifyContent:"center", alignItems:"center", flex: '1' ,marginTop:'10px'}}
                >
                    <Typography variant="body2" component="div" gutterBottom>
                        現在の残高　
                    </Typography>
                    <Typography color={(isCharge)?"black":"red"} variant="h6" component="div" gutterBottom>
                        {symbolUserBalance}
                    </Typography>
                    <Typography variant="body2" component="div" gutterBottom>
                        xym
                    </Typography>
                </Box>
            </Box>
        </Popover>
        <Popover
            id={copyPopoverid}
            open={copyPopoverOpen}
            onClose={copyClose}
            anchorEl={copyAnchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
        >
            <Typography variant="caption" component="div" gutterBottom>コピーしました</Typography>
        </Popover>

        <Box className="meeting" style={{backgroundColor:backgroundColor}}>
            <Box className="current-view">
                <Box className="nav">
                    {(stateCtx.config.host)?
                    <>
                    <Tooltip title="トップ画面を別タブで開く">
                        <Box
                            className="top"
                            onClick={()=>window.open('https://tokenlive.me/#/index', '_blank')}
                        ></Box>
                    </Tooltip>
                    <Tooltip title="画面のリロード">
                        <Box
                            className="reload"
                            onClick={()=>{
                                 sendUpdateMetaInfo()
                            }}
                        ></Box>
                    </Tooltip>
                    </>
                    :<></>}
                    <Tooltip title="退出">
                        <Box
                            className="quit"
                            onClick={doLeave}
                        ></Box>
                    </Tooltip>
                </Box>
                <Box className="messages-container">

                    <Box display={"flex"} justifyContent={"space-evenly"} alignItems={"center"} height={"40px"} width={"350px"}>
                        {/* 　 */}
                        <Tooltip title="現在の参加者数">
                            <AvatarGroup
                                onClick={accountListClick}
                                max={2}
                                total={accountList.length+1}
                                sx={{ width: 35, height: 35 }}
                            >
                                {
                                accountList.map((address,i)=>(
                                    <Avatar key={`addressList${i}`} alt="address" src="" sx={{ width: 40, height: 40 , backgroundColor:blue[500],cursor: 'pointer'}}/>
                                ))
                                }
                            </AvatarGroup>
                        </Tooltip>
                        {/* {(accountList.length>symbolMetaInfo.data.amount)?<ArrowForwardIosIcon style={{color:"red"}}/>
                        :(accountList.length<symbolMetaInfo.data.amount)?<ArrowBackIosIcon />
                        :<SyncAltIcon />}
                        　
                        <Tooltip title="予約人数">
                            <AvatarGroup
                                // onClick={accountListClick}
                                max={2}
                                total={symbolMetaInfo.data.amount+1}
                                sx={{ width: 35, height: 35 }}
                            >
                                {
                                [...Array(symbolMetaInfo.data.amount)].map((_, i) => i).map((address,i)=>(
                                    <Avatar key={`addressList2${i}`} alt="address" src="" sx={{ width: 35, height: 35 , backgroundColor:pink[500],cursor: 'pointer'}}/>
                                ))
                                }
                            </AvatarGroup>
                        </Tooltip> */}

                    </Box>
                    <Box style={{ display: 'flex', flex: 1, flexDirection: 'column', height: '90vh', width:'100%' ,margin: 3 }}>
                        <Box style={{ display: 'flex', margin: 'auto' }}>
                        </Box>
                        <Box style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 3, padding:3, backgroundColor: '#efefef',overflowY: 'scroll'}}>
                            {texts.map((text, i) => {
                                return<Box
                                    bgcolor={textToObject(text.msg['text']).textFont.boxColor}
                                    border={textToObject(text.msg['text']).textFont.boxBorder}
                                    className={`${textToObject(text.msg['text']).textFont.className} ribbon-wrapper`}
                                    key={`messageBox${i}`}
                                    display="flex"
                                    align-items="center"
                                    padding={"3px"}
                                    borderRadius={3}
                                    marginBottom={"2px"}
                                >
                                    {(textToObject(text.msg['text']).textFont.ribbon)?
                                    <Box className="ribbon-content-chat">
                                        <span className="ribbon-chat">Gift</span>
                                    </Box>
                                    :
                                    <></>
                                    }
                                    {(stateCtx.config.userAddress===symbolMetaInfo.data.owner && isEnableMenu)?
                                    <Tooltip key={`Tooltip1-${i}`} title={`クリックするとゲストとして配信に招待できます`}>
                                        <Avatar
                                            sx={{cursor: 'pointer'}}
                                            key={`Avatar1-${i}`}
                                            src={textToObject(text.msg['text']).iconUrl}
                                            onClick={()=>{
                                                if(Object.keys(remoteUsers).length<3){
                                                    setGuestUser(text.uid)
                                                    selecthandleOpen()
                                                }else{
                                                    mutationCtx.toastError('一度に参加できるゲストは３人までです。')
                                                }
                                            }}
                                        />
                                    </Tooltip>
                                    :
                                    <Avatar
                                        key={`Avatar2-${i}`}
                                        src={textToObject(text.msg['text']).iconUrl}
                                    />
                                    }
                                    {(!blockList.includes(text.uid))?
                                    <Box key={`Box2-${i}`}marginLeft={1} word-break="break-all" style={{ width: '300px'}}>
                                        <Box
                                            style={{ display: 'flex', flexDirection: 'row', alignItems:'center'}}
                                        >
                                            <Box
                                                key={`Box3-1-${i}`}
                                                fontSize={textToObject(text.msg['text']).textFont.nameFontSize}
                                                color={textToObject(text.msg['text']).textFont.nameFontColor}
                                                fontWeight={textToObject(text.msg['text']).textFont.nameFontWeight}
                                            >
                                                {textToObject(text.msg['text']).userName}
                                            </Box>
                                            {(getGiftKing(giftCountList)===text.uid)?<>
                                                <Tooltip title={"投げ銭王の称号"}>
                                                    <Box
                                                        key={`Box3-4-${i}`}
                                                        className="diamond-avatar"
                                                    />
                                                </Tooltip>
                                            </>
                                            :<></>}
                                            {/* <Box
                                                key={`Box3-3-${i}`}
                                                marginLeft={1}
                                                fontSize={"10px"}
                                                color={"gray"}
                                            >
                                                {textToObject(text.msg['text']).chatTime}
                                            </Box> */}
                                            {
                                            <Tooltip key={`Tooltip3-${i}`} title={
                                                Object.keys(textToObject(text.msg['text']).serial)
                                                .map((key,j)=>(
                                                    `${(textToObject(text.msg['text']).serial[key]["version"].indexOf('nft')>0)?`【unique】🎫`:(textToObject(text.msg['text']).serial[key]["version"].indexOf('ncft')>0)?`【bundle】🎟`:`【mosaic】🆔`}
                                                    ${(textToObject(text.msg['text']).serial[key]["title"])?textToObject(text.msg['text']).serial[key]["title"]:key}
                                                     ${(textToObject(text.msg['text']).serial[key]["serial"].length>0)?textToObject(text.msg['text']).serial[key]["serial"].map((num)=>(
                                                        ` No${num}`
                                                    )):` .`}　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　　`
                                                ))
                                            }><Box style={{ display: 'flex', flexDirection: 'row', alignItems:'center'}}>
                                                {//　ここは1チケット目、3シリアルまでの表示、それ以降は次のTooltipで全表示させる(bundleのタイトルがない場合はそれ以外ということで表示はしない)
                                                    Object.values(textToObject(text.msg['text']).serial).filter(list => list["serial"].length > 0 && list["title"] !== "").map((list,j)=>(
                                                        <>
                                                            {(j === 0)?
                                                            <Box
                                                                key={`Box3-5-${i}-${j}`}
                                                                marginLeft={1}
                                                            >
                                                            {(list["version"].indexOf('nft')>0)?`🎫`:(list["version"].indexOf('ncft')>0)?`🎟`:`🆔`}
                                                            </Box>
                                                            :<></>
                                                            }
                                                            {list["serial"].map((num,k)=>(
                                                                (j === 0 && k === 0)?
                                                                <>
                                                                <Box
                                                                    key={`Box3-6-${i}-${j}-${k}`}
                                                                    marginLeft={1}
                                                                    fontSize={"10px"}
                                                                    color={"gray"}
                                                                >
                                                                {`No`}
                                                                </Box>
                                                                <Box
                                                                    key={`Box3-6-${i}-${j}-${k}-2`}
                                                                    marginLeft={0.1}
                                                                    fontSize={"15px"}
                                                                    color={"gray"}
                                                                >
                                                                {`${num}`}
                                                                </Box>

                                                                </>
                                                                :<></>
                                                            ))}
                                                        </>
                                                    ))
                                                }
                                                {//ここは１チケット目が2枚以上あるか、２チケット以上ある場合の処理
                                                    (Object.values(textToObject(text.msg['text']).serial).filter(list => list["serial"].length > 0).length > 0 && (Object.values(textToObject(text.msg['text']).serial)[0]["serial"].length > 1 || Object.values(textToObject(text.msg['text']).serial).length > 1))?
                                                        <Box
                                                        key={`Box3-7-${i}`}
                                                        marginLeft={0.5}
                                                        fontSize={"15px"}
                                                        color={"gray"}
                                                        >
                                                        ...
                                                        </Box>
                                                    :<></>
                                                }
                                            </Box></Tooltip>
                                            }
                                        </Box>
                                        <Box
                                            key={`Box3-2-${i}`}
                                            marginBottom={1}
                                            fontSize={"10px"}
                                            color={"gray"}
                                        >
                                            {text.uid}
                                        </Box>
                                        <Box
                                            key={`Box4-${i}`}
                                            fontSize={textToObject(text.msg['text']).textFont.msgFontSize}
                                            color={textToObject(text.msg['text']).textFont.msgFontColor}
                                            fontWeight={textToObject(text.msg['text']).textFont.msgFontWeight}
                                        >
                                            {textToObject(text.msg['text']).text}
                                        </Box>
                                        {i === texts.length - 1 && <div ref={footRef}></div>}
                                    </Box>
                                    :
                                    <Box key={`Box2-${i}`}marginLeft={1} word-break="break-all" style={{ width: '300px'}}>
                                        <Box
                                            display="flex"
                                            align-items="center"
                                        >
                                            <Box
                                                key={`Box3-1-${i}`}
                                                fontSize={textToObject(text.msg['text']).textFont.nameFontSize}
                                                color={textToObject(text.msg['text']).textFont.nameFontColor}
                                                fontWeight={textToObject(text.msg['text']).textFont.nameFontWeight}
                                            >
                                                ブロックされたユーザー
                                            </Box>
                                            <Box
                                                key={`Box3-3-${i}`}
                                                marginLeft={1}
                                                fontSize={"10px"}
                                                color={"gray"}
                                            >
                                                {textToObject(text.msg['text']).chatTime}
                                            </Box>
                                        </Box>
                                        {i === texts.length - 1 && <div ref={footRef}></div>}
                                    </Box>
                                    }

                                    {(!blockList.includes(text.uid))?
                                        <Tooltip key={`Tooltip2-${i}`} title={"このユーザーを非表示にする"}>
                                            <Avatar
                                                key={`Avatar3-${i}`}
                                                sx={{ width: 12, height: 12, cursor: 'pointer'}}
                                                onClick={blockListClick}
                                            ><CancelIcon onClick={()=>setBlockAccount(text.uid)}/>
                                            </Avatar>
                                        </Tooltip>
                                    :
                                    <Tooltip key={`Tooltip2-${i}`} title={"非表示を解除する"}>
                                        <Avatar
                                            key={`Avatar3-${i}`}
                                            sx={{ width: 12, height: 12, cursor: 'pointer'}}
                                            onClick={()=>{
                                                let list = blockList.filter(address => address !== text.uid)
                                                console.log(list)
                                                setBlockList(list)
                                            }}
                                        ><CheckCircleOutlineIcon/>
                                        </Avatar>
                                    </Tooltip>
                                    }

                                </Box>
                                }
                            )}
                        </Box>
                        <Box
                            margin={"3px"}
                            padding={"3px"}
                            borderRadius={3}
                            style={{ display: 'flex' ,justifyContent: "center", flexDirection: 'column'}}
                            >
                            {/* <Box style={{ display: 'flex'}}>
                                <Box style={{display: 'flex', justifyContent: "space-around", flexDirection: 'row', flex: '1', marginRight: '3%', marginLeft: '3%', height: 10}}>
                                    <Button disabled={!isEnableReaction} style={{ fontSize: '20px' }}onClick={async()=>{
                                    }}>🎟 </Button>
                                    <Button disabled={!isEnableReaction} style={{ fontSize: '20px' }}onClick={async()=>{
                                    }}>🎟</Button>
                                    <Button disabled={!isEnableReaction} style={{ fontSize: '20px' }}onClick={async()=>{
                                    }}>🎟</Button>
                                </Box>
                            </Box> */}
                            <Box style={{ display: 'flex'}}>
                                <Box style={{display: 'flex', justifyContent: "start", flexDirection: 'row', flex: '1', marginRight: '3%', marginLeft: '3%', height: 50}}>
                                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems:'center'}}>
                                        <Tooltip title={"アイコンと名前を変更"}>
                                            <Avatar
                                                sx={{cursor: 'pointer'}}
                                                src={iconUrl}
                                                onClick={profileClick}
                                            />
                                        </Tooltip>
                                        <a style={{fontSize: 12, opacity: 0.5,marginLeft: 10}}>{userName}</a>
                                        {(getGiftKing(giftCountList)===stateCtx.config.userAddress)?
                                        <Tooltip title={"投げ銭王の称号"}>
                                            <Box className="diamond-avatar"/>
                                        </Tooltip>
                                        :<></>}
                                    </Box>
                                </Box>
                            </Box>
                            {/* <Box style={{ display: 'flex'}}>
                                <Button id={"button"}
                                onClick={()=>{
                                    console.log(symbolMosaicsSerials)
                                }}>
                                    click
                                </Button>
                            </Box> */}
                            <Box style={{ display: 'flex'}}>
                                <Box style={{display: 'flex', justifyContent: "space-between", flexDirection: 'row', flex: '1', marginRight: '3%', marginLeft: '3%', height: 30}}>
                                <Button disabled={!isEnableReaction} style={{ fontSize: '20px' }}onClick={async()=>{
                                    sendChat("👍","reaction",price)
                                    setIsEnableReaction(false)
                                    setTimeout(() => setIsEnableReaction(true), 1000)
                                }}>👍</Button>
                                <Button disabled={!isEnableReaction} style={{ fontSize: '20px' }}onClick={async()=>{
                                    sendChat("🙋‍♂️","reaction",price)
                                    setIsEnableReaction(false)
                                    setTimeout(() => setIsEnableReaction(true), 1000)
                                }}>🙋‍♂️</Button>
                                <Button disabled={!isEnableReaction} style={{ fontSize: '20px' }}onClick={async()=>{
                                    sendChat("😄","reaction",price)
                                    setIsEnableReaction(false)
                                    setTimeout(() => setIsEnableReaction(true), 1000)
                                }}>😄</Button>
                                <Button disabled={!isEnableReaction} style={{ fontSize: '20px' }}onClick={async()=>{
                                    sendChat("😱","reaction",price)
                                    setIsEnableReaction(false)
                                    setTimeout(() => setIsEnableReaction(true), 1000)
                                }}>😱</Button>
                                <Button disabled={!isEnableReaction} style={{ fontSize: '20px' }}onClick={async()=>{
                                    sendChat("💖","reaction",price)
                                    setIsEnableReaction(false)
                                    setTimeout(() => setIsEnableReaction(true), 1000)
                                }}>💖</Button>
                                </Box>
                            </Box>
                            <Box style={{ display: 'flex'}}>
                                <Box style={{display: 'flex', flexDirection: 'row', flex: '1', marginRight: '3%', marginLeft: '3%', height: 40}}>
                                    <Input
                                        autoFocus={true}
                                        multiline={true}
                                        placeholder="メッセージを入力（200文字まで）"
                                        style={{width: '100%', marginRight: 20}}
                                        type='text'
                                        fullWidth
                                        value={textInput}
                                        onChange={(evt) => {
                                            const PATTERN = /[0-9]{0,201}/
                                            const value = PATTERN.test(evt.target.value)
                                            if (value && evt.target.value.length < 201) {
                                                setTextInput(evt.target.value)
                                            } else {
                                                setTextInput(textInput)
                                            }
                                        }}
                                    />
                                    <Tooltip title={"Ctrl+Enterでも送信できます"}>
                                        <Fab
                                            disabled={(textInput.length>0 && isLoggedInRTM)?false:true}
                                            color="primary"
                                            aria-label="add"
                                            size='small'
                                            onClick={() => sendChat(textInput,"chat",price)}
                                        >
                                            <SendIcon />
                                        </Fab>
                                    </Tooltip>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box
                    style={{ display: 'flex',flex: '1',flexDirection:'column'}}
                >
                    {(role)?
                        <Box
                            className={boxLayout[boxIdList[0]].className}
                            display={"flex"}
                            flex={1}
                            margin={boxLayout[boxIdList[0]].margin}
                            top={boxLayout[boxIdList[0]].top}
                        >
                            <StreamPlayer
                                host={config.host}
                                uid={config.uid}
                                isLocal={true}
                                shareScreen={isShareScreen}
                                videoTrack={VideoTrack}
                                audioTrack={AudioTrack}
                                muteAudio={muteAudio}
                                muteVideo={muteVideo}
                                showInfo={stateCtx.profile}
                                rtcClient={localClient._client}
                                isPlayAudioTrack={false}
                                toggleIcon={boxLayout[boxIdList[0]].toggleIcon}
                                toggleStreemPlayer={toggleStreemPlayer}
                                hideStreemPlayer={hideStreemPlayer}
                                showStreemPlayer={showStreemPlayer}
                                boxHide={boxHideList[0]}
                                ownerAddress={symbolMetaInfo.data.owner}
                                backgroundColor={backgroundColor}
                                boxId={0}
                                offerRoleChangeAudience={offerRoleChangeAudience}
                                giftClick={giftClick}
                                isGiftEnable={isGiftEnable}
                                userName={userName}
                                guestName={guestName}
                            />
                        </Box>
                    :<></>
                    }
                    {(role)?Object.values(remoteUsers).map((remoteUser,index) => {
                        return <Box
                            className={boxLayout[boxIdList[index+1]].className}
                            display={"flex"}
                            flex={1}
                            margin={boxLayout[boxIdList[index+1]].margin}
                            top={boxLayout[boxIdList[index+1]].top}
                        >
                            <StreamPlayer
                                key={`key-${remoteUser.uid}`}
                                host={config.host}
                                uid={remoteUser.uid}
                                isLocal={false}
                                shareScreen={isShareScreen}
                                videoTrack={remoteUser.videoTrack}
                                audioTrack={remoteUser.audioTrack}
                                muteAudio={false}
                                muteVideo={false}
                                showInfo={false}
                                rtcClient={localClient._client}
                                isPlayAudioTrack={true}
                                toggleIcon={boxLayout[boxIdList[index+1]].toggleIcon}
                                toggleStreemPlayer={toggleStreemPlayer}
                                hideStreemPlayer={hideStreemPlayer}
                                showStreemPlayer={showStreemPlayer}
                                boxHide={boxHideList[index+1]}
                                ownerAddress={symbolMetaInfo.data.owner}
                                backgroundColor={backgroundColor}
                                boxId={index+1}
                                offerRoleChangeAudience={offerRoleChangeAudience}
                                giftClick={giftClick}
                                isGiftEnable={isGiftEnable}
                                userName={userName}
                                guestName={guestName}
                                />
                        </Box>
                    }):<></>}
                    {(!role)?Object.values(remoteUsers).map((remoteUser,index) => {
                        return <Box
                            className={boxLayout[boxIdList[index]].className}
                            display={"flex"}
                            flex={1}
                            margin={boxLayout[boxIdList[index]].margin}
                            top={boxLayout[boxIdList[index]].top}
                        >
                            <StreamPlayer
                                key={`key-${remoteUser.uid}`}
                                host={config.host}
                                uid={remoteUser.uid}
                                isLocal={false}
                                shareScreen={isShareScreen}
                                videoTrack={remoteUser.videoTrack}
                                audioTrack={remoteUser.audioTrack}
                                muteAudio={false}
                                muteVideo={false}
                                showInfo={false}
                                rtcClient={localClient._client}
                                isPlayAudioTrack={true}
                                toggleIcon={boxLayout[boxIdList[index]].toggleIcon}
                                toggleStreemPlayer={toggleStreemPlayer}
                                hideStreemPlayer={hideStreemPlayer}
                                showStreemPlayer={showStreemPlayer}
                                boxHide={boxHideList[index]}
                                ownerAddress={symbolMetaInfo.data.owner}
                                backgroundColor={backgroundColor}
                                boxId={index}
                                offerRoleChangeAudience={offerRoleChangeAudience}
                                giftClick={giftClick}
                                isGiftEnable={isGiftEnable}
                                userName={userName}
                                guestName={guestName}
                                />
                        </Box>
                    }):<></>}
                    {(role)?
                    <Box
                        style={{ display: 'flex', position:'relative', height:'60px'}}
                    >
                        <StreamMenu
                            uid={stateCtx.config.userAddress}
                            muteAudio={muteAudio}
                            muteVideo={muteVideo}
                            videoTrack={VideoTrack}
                            audioTrack={AudioTrack}
                            volumeLevel={volumeLevel}
                            shareScreen={isShareScreen}
                            toggleVideo={toggleVideo}
                            toggleAudio={toggleAudio}
                            toggleShareScreen={toggleShareScreen}
                            isEnableMenu={isEnableMenu}
                            requestRoleChangeAudience={requestRoleChangeAudience}
                            ownerAddress={symbolMetaInfo.data.owner}
                            remoteUsers={remoteUsers}
                            selecthandleOpen={selecthandleOpen}
                            devicehandleOpen={devicehandleOpen}
                            devicehandleClose={devicehandleClose}
                            deviceOpen={deviceOpen}
                            bgmhandleOpen={bgmhandleOpen}
                            bgmFile={bgmFile}
                            rtcClient={localClient._client}
                            audioMixingTrack={localClient.audioMixingTrack}
                            bgmState={bgmState}
                            setBgmState={setBgmState}
                            isGuestInvite={isGuestInvite}
                            offerCancelRoleChangeHost={offerCancelRoleChangeHost}
                            guestUser={guestUser}
                            toggleResolution={toggleResolution}
                            quality={quality}
                            demo={stateCtx.config.demo}
                            virtualBghandleOpen={virtualBghandleOpen}
                            virtualBackgroundEnabled={virtualBackgroundEnabled}
                            virtualBgStop={virtualBgStop}
                            setCameraList={setCameraList}
                            setMicrophoneList={setMicrophoneList}
                        />
                    </Box>
                    :<></>
                    }
                    <Box style={{ display: 'flex', height:'40px',margin:'10px'}}>
                        {/* フッター */}
                        <Box>
                            <Box style={{ display: 'flex' ,alignItems: 'center'}}>
                                <Typography variant="caption" component="div" style={{ color: 'white' }} gutterBottom>
                                        {`${Convert.decodeHex(symbolMetaInfo.data.title)}　` ?? ""}
                                </Typography>
                                {(symbolMetaInfo.data.st > Date.now())?
                                <>
                                    <Typography variant="caption" component="div" style={{ color: 'white' }} gutterBottom>
                                        {`ライブ配信は`}
                                    </Typography>
                                    <Typography variant="body1" component="div" style={{ color: 'white' }} gutterBottom>
                                        {(roomDays>0)?`${roomDays}日後に`:`あと${roomHours}時間${roomMinutes}分${roomSeconds}秒で`}
                                    </Typography>
                                    <Typography variant="caption" component="div" style={{ color: 'white' }} gutterBottom>
                                        {`始まる予定です`}
                                    </Typography>
                                </>
                                :<>
                                    <Chip label="LIVE" size="small" color="error" className="live-text"/>
                                    <Typography variant="caption" component="div" style={{ color: 'white' }} gutterBottom>
                                    {'　'}{(quality===0)?`中画質マルチ配信モード(640 × 360)`:(quality===1)?`高画質シングル配信モード(1280 × 720)`:`高画質マルチ配信モード (1920 × 1080)`}{'で配信中'}
                                    </Typography>
                                    <Box className="stream-time">{(stateCtx.config.demo)?'':(roomDays>0)?'':(roomHours>0)?'':(roomMinutes>10)?'':(roomMinutes>0)?`残り${roomMinutes}分${roomSeconds}秒`:`残り${roomSeconds}秒`}</Box>
                                </>
                                }
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
        </>:<>
        <Box
            flex="1"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
        >
            <Bars height={50} width={50} color={"#44A2FC"}></Bars>
        </Box>
        </>}
        </>
    )
}

export default React.memo(MeetingPage)