import React, { useEffect, useMemo ,useState} from 'react'
import clsx from 'clsx'
import {useGlobalState, useGlobalMutation} from '../../utils/container'
import useRouter from '../../utils/use-router'
import {makeStyles} from '@material-ui/core/styles'
import { Typography ,Box, Popover} from '@mui/material'
import {Link} from 'react-router-dom'
import { Button, Modal, TextField,FormControl, InputAdornment, IconButton} from '@material-ui/core'
import {useSymbol} from '../../hooks/useSymbol'
import {useFunction} from '../../hooks/useFunction'
import { Address, RepositoryFactoryHttp} from 'symbol-sdk'
import axios from 'axios'
import { Bars } from 'react-loader-spinner'
import Countdown from 'react-countdown'
import { isMobile } from "react-device-detect"
import AttachFileIcon from '@material-ui/icons/AttachFile';

const useStyles = makeStyles((theme) => ({
    menuTitle: {
        color: '#333333',
        textAlign: 'center',
        fontSize: 'h6.fontSize',
        position: 'relative',
        top: '7px'
    },
    grid: {
        margin: '0 !important'
    },
    textFieldReadOnly: {
        maxWidth: '430px',
        minWidth: '430px',
        alignSelf: 'center'
    },
    textFieldReadOnlyMobile: {
        maxWidth: '230px',
        minWidth: '230px',
        alignSelf: 'center'
    },
    textFieldReadOnlyAddressMobile: {
        maxWidth: '280px',
        minWidth: '280px',
        alignSelf: 'center'
    },
    menu: {
        margin: '0.25rem 0',
        position: 'relative',
        height: '39px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    line: {
        marginTop: '0.2rem',
        marginBottom: '0.5rem',
        borderBottom: '1px solid #EAEAEA'
    },
    confirmButton: {
        lineHeight: '21px',
        color: 'rgba(255,255,255,1)',
        fontSize: '17px',
        textTransform: 'none',
        height: '44px',
        width: '260px',
        '&:hover': {
            backgroundColor: '#82C2FF'
        },
        margin: theme.spacing(1),
        marginTop: '33px',
        backgroundColor: '#44a2fc',
        borderRadius: '30px'
    },
}))

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    height: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const modalMobileStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 250,
    height: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 5,
  };

export default function LoginAccountCard() {
    const classes = useStyles()
    const routerCtx = useRouter()
    const stateCtx = useGlobalState()
    const mutationCtx = useGlobalMutation()
    const [account,setAccount] = useState(localStorage.getItem('loginAddress')??"")
    const [pin,setPin] = useState(0)
    const [isChecking,setIsChecking] = useState(false)

    const {
        checkNode,checkedNode,nodeLoading,
    } = useSymbol()

    const {
        copyTextToClipboard
    } = useFunction()

    useEffect(() => {
        checkNode()
    }, [])

    const checkMosaicsAccount = useMemo(() => {
        mutationCtx.updateConfig({
            nodeUrl:checkedNode
            })
    },[nodeLoading])

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    //Popover関連
    const [copyAnchorEl, setCopyAnchorEl] = useState(null);
    const copyPopoverOpen = Boolean(copyAnchorEl);
    const copyPopoverid = copyPopoverOpen ? 'simple-popper' : undefined;

    const copyClick = (event) => {
        setCopyAnchorEl(copyAnchorEl ? null : event.currentTarget);
        copyTextToClipboard(stateCtx.config.adminAddress);
    }
    const copyClose = () => {
        setCopyAnchorEl(null);
    }

    const [copy2AnchorEl, setCopy2AnchorEl] = useState(null);
    const copy2PopoverOpen = Boolean(copy2AnchorEl);
    const copy2Popoverid = copy2PopoverOpen ? 'simple-popper' : undefined;

    const copy2Click = (event) => {
        setCopy2AnchorEl(copy2AnchorEl ? null : event.currentTarget);
        copyTextToClipboard(pin);
    }
    const copy2Close = () => {
        setCopy2AnchorEl(null);
    }

    return (
        <>
        {(!nodeLoading)?
        <>
        <Popover
            id={copyPopoverid}
            open={copyPopoverOpen}
            onClose={copyClose}
            anchorEl={copyAnchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
        >
            <Typography variant="caption" component="div" gutterBottom>アドレスをコピーしました</Typography>
        </Popover>

        <Popover
            id={copy2Popoverid}
            open={copy2PopoverOpen}
            onClose={copy2Close}
            anchorEl={copy2AnchorEl}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
        >
            <Typography variant="caption" component="div" gutterBottom>PINコードをコピーしました</Typography>
        </Popover>

        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {!isMobile?
            <Box sx={modalStyle}>
                <Typography variant="body2" component="div" gutterBottom>本人確認のため、ウォレットアプリを開き以下条件で</Typography>
                <Typography variant="body2" component="div" gutterBottom>メッセージにPINコードを入力して送信してください</Typography>
                <Box mt={5}>
                    <FormControl className={clsx(classes.textFieldReadOnly, classes.grid)}>
                        <TextField
                            InputProps={{
                                readonly: true,
                                style: { fontSize: 13 },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton >
                                            <AttachFileIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                }}
                            label="宛先アドレス"
                            placeholder='宛先アドレス'
                            value={stateCtx.config.adminAddress}
                            onClick={copyClick}
                        />
                    </FormControl>
                </Box>
                <Box mt={2}>
                    <FormControl className={clsx(classes.textFieldReadOnly, classes.grid)}>
                        <TextField
                            inputProps={{ readonly: true }}
                            label="送金数量"
                            placeholder='送金数量'
                            value={"0.0000001"}
                        />
                    </FormControl>
                </Box>
                <Typography variant="caption" component="div" gutterBottom>*0xymが可能であれば0xymを指定</Typography>
                <Box mt={2}>
                    <FormControl className={clsx(classes.textFieldReadOnly, classes.grid)}>
                        <TextField
                            InputProps={{
                                readonly: true,
                                style: { fontSize: 13 },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton >
                                            <AttachFileIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                }}
                            label="メッセージ"
                            placeholder='メッセージ'
                            value={pin}
                            onClick={copy2Click}
                        />
                    </FormControl>
                </Box>
                <Box mt={2} mb={5}>
                    <FormControl className={clsx(classes.textFieldReadOnly, classes.grid)}>
                        <TextField
                            inputProps={{ readonly: true }}
                            label="トランザクション手数料"
                            placeholder='トランザクション手数料'
                            value={"遅い"}
                        />
                    </FormControl>
                </Box>
                <Box mt={2} mb={5} display="flex" justifyContent="center">
                    <Countdown date={Date.now() + 60000*5} />
                </Box>
                <Typography variant="body2" component="div" gutterBottom>*PINコードはCOMSAウォレットやArcanaなどのウォレットから送る事ができます。</Typography>
                <Typography variant="body2" component="div" gutterBottom color={"red"} fontWeight={"bold"}>*５分以内にPINコードを送れなかった場合は認証が失敗します。</Typography>
            </Box>
            :
            <Box sx={modalMobileStyle}>
                <Typography variant="caption" component="div" gutterBottom>本人確認のため、ウォレットアプリを開き以下条件でメッセージにPINコードを入力して送信してください</Typography>
                <Box mt={3}>
                    <FormControl className={clsx(classes.textFieldReadOnlyAddressMobile, classes.grid)}>
                        <TextField
                            InputProps={{
                                readonly: true,
                                style: { fontSize: 10 },
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton >
                                            <AttachFileIcon/>
                                        </IconButton>
                                    </InputAdornment>
                                ),
                                }}
                            label="宛先アドレス"
                            placeholder='宛先アドレス'
                            value={stateCtx.config.adminAddress}
                            onClick={copyClick}
                        />
                    </FormControl>
                </Box>
                <Box mt={2}>
                    <FormControl className={clsx(classes.textFieldReadOnlyMobile, classes.grid)}>
                        <TextField
                            inputProps={{ readonly: true }}
                            label="送金数量"
                            placeholder='送金数量'
                            value={"0.0000001"}
                        />
                    </FormControl>
                </Box>
                <Typography variant="caption" component="div" gutterBottom>*0xymが可能であれば0xymを指定</Typography>
                <Box mt={2}>
                    <FormControl className={clsx(classes.textFieldReadOnlyMobile, classes.grid)}>
                    <TextField
                        InputProps={{
                            readonly: true,
                            style: { fontSize: 13 },
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton >
                                        <AttachFileIcon/>
                                    </IconButton>
                                </InputAdornment>
                            ),
                            }}
                        label="メッセージ"
                        placeholder='メッセージ'
                        value={pin}
                        onClick={copy2Click}
                    />
                    </FormControl>
                </Box>
                <Box mt={2} mb={3}>
                    <FormControl className={clsx(classes.textFieldReadOnlyMobile, classes.grid)}>
                        <TextField
                            inputProps={{ readonly: true }}
                            label="トランザクション手数料"
                            placeholder='トランザクション手数料'
                            value={"遅い"}
                        />
                    </FormControl>
                </Box>
                <Box mt={2} mb={3} display="flex" justifyContent="center">
                    <Countdown date={Date.now() + 60000*5} />
                </Box>
                <Typography variant="caption" component="div" gutterBottom>*PINコードはCOMSAウォレットやArcanaなどのウォレットから送る事ができます。</Typography>
                <Typography variant="caption" component="div" gutterBottom color={"red"} fontWeight={"bold"}>*５分以内にPINコードを送れなかった場合は認証が失敗します。</Typography>
            </Box>
            }
        </Modal>
        <Box flex="1" display="flex" flexDirection="column">
            <Link to="/" className="back-btn"/>
            <Box
                display="flex"
                flex="1"
                flexDirection="column"
                padding="0 1rem"
                justifyContent="flex-start"
            >
                <FormControl className={classes.menu}>
                    <span className={classes.menuTitle}>ログイン</span>
                </FormControl>
                <Box className={classes.line}></Box>
                <Box marginTop={1} />
                <FormControl className={clsx(classes.textField, classes.grid)}>
                    <TextField
                        label="ログインするSymbolアドレス"
                        placeholder='ログインするSymbolアドレス'
                        type="search"
                        InputProps={(!isMobile)?{style: { fontSize: 13 },}:{style: { fontSize: 10 },}}
                        value={account}
                        onChange={(evt) => {
                            const PATTERN = /[0-9]{0,40}/
                            const value = PATTERN.test(evt.target.value)
                            if (value && evt.target.value.length < 40) {
                                setAccount(evt.target.value)
                            } else {
                                setAccount(account)
                            }
                        }}
                    />
                </FormControl>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Button
                        onClick={()=>{
                            localStorage.setItem('loginAddress', account);
                            setIsChecking(true)
                            axios.get(`${stateCtx.config.nodeUrl}/accounts/${account}`)
                            .then((res)=>{
                                console.log(res.status)
                                axios.get(`https://us-central1-tokenlive-b98bd.cloudfunctions.net/mobileRequestPin`)
                                .then((res)=>{
                                    console.log(res.data.pin)
                                    setPin(res.data.pin)
                                    handleOpen()
                                    const noSound = new Audio("no_sound_5min.mp3");
                                    noSound.play();
                                    axios.get(`https://us-central1-tokenlive-b98bd.cloudfunctions.net/mobileLogin?userAddress=${account}&node=${stateCtx.config.nodeUrl}&pin=${res.data.pin}&adminAddress=${stateCtx.config.adminAddress}`)
                                    .then(async (res)=>{
                                        console.log(res)
                                        const repositoryFactory = new RepositoryFactoryHttp(stateCtx.config.nodeUrl)
                                        const address = Address.createFromRawAddress(account);
                                        const accountHttp = repositoryFactory.createAccountRepository();
                                        await accountHttp.getAccountInfo(address).toPromise().then(
                                            (accountInfo) => {
                                                mutationCtx.updateConfig({
                                                    userAddress:account,
                                                    userPublicKey:accountInfo.publicKey,
                                                    loginType:"ACCOUNT",
                                                })
                                            }
                                        )
                                        routerCtx.history.push("/index");
                                    }).catch((e)=>{
                                        console.log(e.response)
                                        console.log(e.response.request)
                                        console.log(e.response.request.status)
                                        if(e.response.request.status==400){
                                            mutationCtx.toastError('５分以内にPINコードが送られませんでした')
                                        }else if(e.response.request.status==401){
                                            mutationCtx.toastError('PINコードが違います')
                                        }else if(e.response.request.status==402){
                                            mutationCtx.toastError('管理者アドレス以外にPINメッセージを送っています。送付先のアドレスを確認して下さい。')
                                        }else if(e.response.request.status==403){
                                            mutationCtx.toastError('ログインアドレスと送信アドレスが異なります。指定したログインアドレスからPINメッセージを送ってください')
                                        }
                                        else{
                                            mutationCtx.toastError('サーバー接続に失敗しました')
                                        }
                                    }).finally(()=>{
                                        noSound.pause();
                                        setIsChecking(false)
                                        copyClose()
                                        handleClose()
                                    })
                                }).catch((e)=>{
                                    console.log(e)
                                    setIsChecking(false)
                                    mutationCtx.toastError('サーバー接続に失敗しました')
                                })
                            }).catch((e)=>{
                                console.log(e)
                                setIsChecking(false)
                                mutationCtx.toastError('Symbolアカウントアドレスが正しくありません')
                            })
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.confirmButton}
                        disabled={isChecking}
                    >
                        認証開始
                    </Button>
                </Box>
            </Box>
        </Box>
        </>:<>
        <Box
            flex="1"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
        >
            <Bars height={50} width={50} color={"#44A2FC"}></Bars>
        </Box>
        </>}
        </>
    )
}
