import React, { useEffect, useMemo } from 'react'
import clsx from 'clsx'
import {useGlobalState, useGlobalMutation} from '../../utils/container'
import {makeStyles} from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Box from '@material-ui/core/Box'
import {Link} from 'react-router-dom'
import { Button, TextField} from '@material-ui/core'
import { useState } from 'react'
import { Typography } from '@mui/material'
import {useSymbol} from '../../hooks/useSymbol'
import {useNft} from '../../hooks/useNft'
import { Bars } from 'react-loader-spinner'
import { Address, TransactionGroup , RepositoryFactoryHttp, MosaicId, MetadataType, Convert ,ChainHttp, Order,TransactionType} from 'symbol-sdk-comsa'
import axios from 'axios'

const useStyles = makeStyles((theme) => ({
    grid: {
        margin: '0 !important'
    },
    textField: {
        maxWidth: '300px',
        minWidth: '300px',
        alignSelf: 'center'
    },
    menu: {
        margin: '0.25rem 0',
        position: 'relative',
        height: '39px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    line: {
        marginTop: '0.2rem',
        marginBottom: '0.5rem',
        borderBottom: '1px solid #EAEAEA'
    },
    hr: {
        borderBottom: '1px solid #EAEAEA'
    },
    confirmButton: {
        lineHeight: '21px',
        color: 'rgba(255,255,255,1)',
        fontSize: '17px',
        textTransform: 'none',
        height: '44px',
        width: '260px',
        '&:hover': {
            backgroundColor: '#82C2FF'
        },
        margin: theme.spacing(1),
        marginTop: '33px',
        backgroundColor: '#44a2fc',
        borderRadius: '30px'
    },
}))

export default function NcftCard() {
    const classes = useStyles()
    const stateCtx = useGlobalState()
    const mutationCtx = useGlobalMutation()
    const [address,setAddress] = useState("")
    const [mosaicLine,setMosaicLine] = useState("")
    const [mosaicList,setMosaicList] = useState([])
    const [isMosaicCheck, setIsMosaicCheck] = useState(false);
    const [serial,setSerial] = useState([])
    const [nft,setNft] = useState(stateCtx.config.nft)

    const {
        checkNode,checkedNode,nodeLoading,
        getMosaicInfo, //モザイクの存在のチェック用
        getMosaicsSerials,symbolMosaicsSerials,
        getSymbolMosaicsBundlenames,symbolMosaicsBundlenames
    } = useSymbol()

    const {getComsaNft,getDriveNft,checkDriveNft} = useNft()

    useEffect(() => {
        checkNode()
    }, [])

    const checkMosaicsAccount = useMemo(() => {
        mutationCtx.updateConfig({
            nodeUrl:checkedNode
            })
    },[nodeLoading])

    const confirmButtonClick = async () =>{
        console.log(stateCtx.config.nodeUrl)
        setIsMosaicCheck(false)
        const cehckdMosaicList = mosaicList.filter(element => element != "") //何もない行を除外
        setMosaicList(cehckdMosaicList)
        if(cehckdMosaicList.length == 0){
            mutationCtx.toastError(
                `mosaicが一つも入力されていません`
            )
            return
        }else if(cehckdMosaicList.length > 10){
            mutationCtx.toastError(
                `指定できるmosaicは10件までです`
            )
            return
        }

        const cehckdMosaicListSet = new Set(cehckdMosaicList)
        if(cehckdMosaicListSet.size !== cehckdMosaicList.length){
            mutationCtx.toastError(
                `重複したmosaicが入力されています`
            )
            return
        }

        for(let i = 0; i < cehckdMosaicList.length; i++){
            if(cehckdMosaicList[i]===stateCtx.config.xymAddress){
                mutationCtx.toastError(
                    `${cehckdMosaicList[i]}はXYMです。指定することはできません`
                )
                return
            }
            try{
                console.log(cehckdMosaicList[i])
                await getMosaicInfo(cehckdMosaicList[i])
            }catch(e){
                if(e.message === 'expired mosaic'){
                    mutationCtx.toastError(
                        `${cehckdMosaicList[i]}は期限が切れたモザイクです。`
                    )
                }else if(e.message === 'non zero divisibility'){
                    mutationCtx.toastError(
                        `${cehckdMosaicList[i]}の最小単位が1以下（divisibilityが１以上）のため利用できません`
                    )
                }else if(e.message === 'FT mosaic'){
                    mutationCtx.toastError(
                        `${cehckdMosaicList[i]}はNFTもしくは、転送不可なFT（NCFT）ではないFTに該当するため指定する事ができません。`
                    )
                }else if(e.message === 'BlackList mosaic'){
                    mutationCtx.toastError(
                        `${cehckdMosaicList[i]}はNFT-DRIVEでブラックリストに登録されているため指定する事ができません。。`
                    )
                }else{
                    console.log(e)
                    mutationCtx.toastError(
                        `${cehckdMosaicList[i]}は存在しないモザイクです。`
                    )
                }
                return
            }
        }
        await getSymbolMosaicsBundlenames(cehckdMosaicList);
        mutationCtx.toastSuccess(
            `モザイクのチェックOK`
        )
        setIsMosaicCheck(true)
    }

    return (
        <>
        {(!nodeLoading)?
        <>
        <Box flex="1" display="flex" flexDirection="column">
            <Link to="/index" className="back-btn"/>
            <Box
                display="flex"
                flex="1"
                flexDirection="column"
                padding="0 1rem"
                justifyContent="flex-start"
            >
                <FormControl className={classes.menu}>
                    <Typography variant="body2" component="div" gutterBottom>シリアル番号チェック</Typography>
                </FormControl>
                <Box className={classes.line}></Box>
                <Box marginTop={1} />
                <FormControl className={clsx(classes.textField, classes.grid)}>
                    <TextField
                        label="COMSAウォレットのアドレス"
                        placeholder='COMSAウォレットのアドレス'
                        value={address}
                        onChange={(evt) => {
                            const PATTERN = /[0-9]{0,40}/
                            const value = PATTERN.test(evt.target.value)
                            if (value && evt.target.value.length < 40) {
                                setAddress(evt.target.value)
                            } else {
                                setAddress(address)
                            }
                        }}
                    />
                </FormControl>
                <FormControl className={clsx(classes.textField, classes.grid)}>
                    <TextField
                        label="モザイクIDの入力"
                        placeholder='モザイクIDの入力'
                        id="outlined-multiline-static"
                        multiline
                        rows={3}
                        value={mosaicLine}
                        onChange={(evt) => {
                            setMosaicLine(evt.target.value)
                            setMosaicList(evt.target.value.split('\n'))
                        }}
                    />
                </FormControl>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Button
                        onClick={confirmButtonClick}
                        variant="contained"
                        color="primary"
                        className={classes.confirmButton}
                        disabled={isMosaicCheck}
                    >
                        シリアルNoチェック
                    </Button>
                </Box>

                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Button
                        onClick={async ()=>{
                            console.log(await checkDriveNft("0810F882DEF004B3"))
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.confirmButton}
                    >
                        ver1.0のNFT
                    </Button>
                </Box>

                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Button
                        onClick={async ()=>{
                            setNft(await getDriveNft("16F881DE78ED5810","nftdrive"))
                        }}
                        variant="contained"
                        color="primary"
                        className={classes.confirmButton}
                    >
                        NFTの表示
                    </Button>
                </Box>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} mt={3}>
                    <img src={`${nft}`} style={{width:"auto",height:"auto",maxWidth:"100px",maxHeight:"100px"}} alt="代替文字" />
                    <Typography variant="body2" component="div" gutterBottom>{serial.join()}</Typography>
                </Box>
            </Box>
        </Box>
        </>:<>
        <Box
            flex="1"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
        >
            <Bars height={50} width={50} color={"#44A2FC"}></Bars>
        </Box>
        </>}
        </>
    )
}
