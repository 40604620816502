import {useEffect, useMemo} from 'react'
import {useGlobalState, useGlobalMutation} from '../utils/container'
import RTCClient from '../rtc-client'
import AgoraRTC from 'agora-rtc-sdk-ng'

export default function useDevices() {
    const stateCtx = useGlobalState()
    const mutationCtx = useGlobalMutation()

    const client = useMemo(() => {
        const _rtcClient = new RTCClient()
        return _rtcClient
    }, [])

    AgoraRTC.onMicrophoneChanged = async (changedDevice) => {
        console.log("microphone changed!", changedDevice.state, changedDevice.device);
        // When plugging in a device, switch to a device that is newly plugged in.
        client.getDevices().then((datas) => {
            mutationCtx.setDevicesList(datas)
        })
        // Switch to an existing device when the current device is unplugged.
        // }else 
        //  else if (changedDevice.device.label === this.mLocalAudioTrack.getTrackLabel()) {
        //   const oldMicrophones = await AgoraRTC.getMicrophones();
        //   oldMicrophones[0] && this.mLocalAudioTrack.setDevice(oldMicrophones[0].deviceId);
        // }
    }
    
    AgoraRTC.onCameraChanged = async (changedDevice) => {
        console.log("camera changed!", changedDevice.state, changedDevice.device);
        // When plugging in a device, switch to a device that is newly plugged in.
        client.getDevices().then((datas) => {
            mutationCtx.setDevicesList(datas)
        })
        //  else if (changedDevice.device.label === this.mLocalVideoTrack.getTrackLabel()) {
        //   const oldCameras = await AgoraRTC.getCameras();
        //   oldCameras[0] && this.mLocalVideoTrack.setDevice(oldCameras[0].deviceId);
        // }
    }

    const [cameraList, microphoneList] = useMemo(() => {
        console.log("=========== cameraList ===========")
        console.log(
            stateCtx.devicesList
                .filter((item) => item.kind === 'videoinput')
                .map((item, idx) => ({
                    value: item.deviceId,
                    label: item.label ? item.label : `Video Input ${idx}`
                }))
        )
        console.log("=========== micList ===========")
        console.log(
            stateCtx.devicesList
                .filter((item) => item.kind === 'audioinput')
                .map((item, idx) => ({
                    value: item.deviceId,
                    label: item.label ? item.label : `Audio Input ${idx}`
                }))
        )
        return [
            stateCtx.devicesList
                .filter((item) => item.kind === 'videoinput')
                .map((item, idx) => ({
                    value: item.deviceId,
                    label: item.label ? item.label : `Video Input ${idx}`
                })),
            stateCtx.devicesList
                .filter((item) => item.kind === 'audioinput')
                .map((item, idx) => ({
                    value: item.deviceId,
                    label: item.label ? item.label : `Audio Input ${idx}`
                }))
        ]
    }, [stateCtx.devicesList])

    useEffect(() => {
        if (cameraList.length > 0 || microphoneList.length > 0) return
        console.log("===========first getDevices==========")
        client.getDevices().then((datas) => {
            mutationCtx.setDevicesList(datas)
        })
        return () => {
            client.destroy()
        }
    }, [microphoneList, mutationCtx, cameraList, client])

    //getDevice後にグローバル変数に格納する処理を分けている
    useEffect(() => {
        if (
            cameraList[0] &&
            (stateCtx.config.cameraId === "")
        ) {
            if(cameraList[0].value === ""){
                mutationCtx.updateConfig({
                    cameraId: "none"
                })
            }else{
                mutationCtx.updateConfig({
                    cameraId: cameraList[0].value
                })    
            }

        }
        if (
            microphoneList[0] &&
            (stateCtx.config.microphoneId === "")
        ) {
            if(microphoneList[0].value === ""){
                mutationCtx.updateConfig({
                    microphoneId: "none"
                })
            }else{
                mutationCtx.updateConfig({
                    microphoneId: microphoneList[0].value
                })    
            }
        }
    }, [
        mutationCtx,
        stateCtx.devicesList,
        stateCtx.config,
        cameraList,
        microphoneList
    ])

    return [cameraList, microphoneList]
}