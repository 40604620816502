import React, {useState} from 'react'
import clsx from 'clsx'
import Tooltip from '@material-ui/core/Tooltip'
import {makeStyles} from '@material-ui/core/styles'
import { Avatar, Slider, Stack } from '@mui/material'
import { grey , green} from '@material-ui/core/colors'
import {useGlobalState, useGlobalMutation} from '../../utils/container'
import useDevices from '../../utils/use-devices'
import { Box } from '@material-ui/core'

const useStyles = makeStyles({
    menu: {
        height: '50px',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'flex-start',
    },
    customBtn: {
        width: '35px',
        height: '35px',
        marginLeft: '14px',
        borderRadius: '26px',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        backgroundSize: '35px',
        cursor: 'pointer'
    },
    customBgmBtn: {
        width: '50px',
        height: '50px',
        marginLeft: '50px',
        marginRight: '50px',
        borderRadius: '26px',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        backgroundSize: '50px',
        cursor: 'pointer'
    },

    leftAlign: {
        display: 'flex',
        flex: '1',
        justifyContent: 'space-evenly'
    },
    rightAlign: {
        display: 'flex',
        flex: '1',
        justifyContent: 'center'
    },
    menuContainer: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        zIndex: '2'
    }
})

export default function StreamMenuMobile(props) {
    const stateCtx = useGlobalState()
    const mutationCtx = useGlobalMutation()
    const [camera,mic] = useDevices()

    const [sliderVolume, setSliderVolume] = useState(10);
    const {
        uid,
        muteVideo,
        muteAudio,
        videoTrack,
        audioTrack,
        volumeLevel,
        shareScreen,
        isEnableMenu,
        ownerAddress,
        remoteUsers,
        requestRoleChangeAudience,
        selecthandleOpen,
        devicehandleOpen,
        devicehandleClose,
        deviceOpen,
        bgmhandleOpen,
        audioMixingTrack,
        bgmFile,
        rtcClient,
        bgmState,
        setBgmState,
        isGuestInvite,
        offerCancelRoleChangeHost,
        guestUser,
        toggleResolution,
        quality,
        demo,
        virtualBghandleOpen,
        virtualBackgroundEnabled,
        virtualBgStop,
        setCameraList,
        setMicrophoneList,
    } = props

    const classes = useStyles()

    function toggleVideo() {
        props.toggleVideo()
    }

    function toggleAudio() {
        props.toggleAudio()
    }

    function toggleShareScreen() {
        props.toggleShareScreen()
    }

    setCameraList(camera)
    setMicrophoneList(mic)

    return (
        <div className={classes.menuContainer}>
            {(isEnableMenu)?
            <div className={classes.menu}>
                {(demo)?
                <Tooltip placement="top" title={(shareScreen)?'画面共有時は画質を変更できません':'画質の切り替え（デモのみ、配信画質確認用）'}>
                    <i
                        onClick={()=>(shareScreen)?null:toggleResolution()}
                        className={clsx(
                            classes.customBtn,
                            (shareScreen && quality===0)?'resolution-low-disable':(shareScreen && quality===1)?'resolution-high-disable':(quality===0)?'resolution-low':'resolution-high'
                        )}
                    />
                </Tooltip>
                :<></>}
                <Tooltip placement="top" title={'カメラ/マイクの切り替え'}>
                    <i
                        onClick={()=>(!deviceOpen)?devicehandleOpen():devicehandleClose()}
                        className={clsx(
                            classes.customBtn,
                            (!deviceOpen)?'rotate-camera':'off-rotate-camera'
                        )}
                    />
                </Tooltip>
                <Tooltip placement="top" title={!videoTrack ? "Track無し" : muteVideo ? 'ビデオを開始する' : 'ビデオを停止する'}>
                    <i
                        onClick={!videoTrack ? null : toggleVideo}
                        className={clsx(
                            classes.customBtn,
                            !videoTrack ? "disable-video" : muteVideo ? 'mute-video' : 'unmute-video'
                        )}
                    />
                </Tooltip>
                <Box style={{display: 'flex', justifyContent: "center", flexDirection: 'row'}}>
                    <Tooltip placement="top" title={!audioTrack ? "Track無し" : muteAudio ? 'マイクを開始する' : 'マイクを停止する'}>
                        <i
                            onClick={!audioTrack ? null : toggleAudio}
                            className={clsx(
                                classes.customBtn,
                                !audioTrack ? "disable-audio" : muteAudio ? 'mute-audio' :'unmute-audio'
                            )}
                        />
                    </Tooltip>
                    <Avatar className={(!muteAudio && volumeLevel>0.1)?"live-text":""} sx={(!muteAudio && volumeLevel>0.1)?{ width: 10, height: 10 , backgroundColor:green[500]}:{ width: 10, height: 10 , backgroundColor:grey[500]}}>　</Avatar>
                </Box>
                {(!stateCtx.config.demo)?<>
                    {(uid===ownerAddress)?
                    <Tooltip placement="top" title={(quality===1)?"高画質シングル配信モードではゲストを招待することはできません":(isGuestInvite)?"招待を中止する":(Object.keys(remoteUsers).length<3)?'ゲストを配信に招待する':'上限に達したため招待できません'}>
                        <i
                            onClick={()=>(quality===1)?null:(isGuestInvite)?offerCancelRoleChangeHost(guestUser):(Object.keys(remoteUsers).length<3)?selecthandleOpen():null}
                            className={clsx(
                                classes.customBtn,
                                (quality===1)?'disable-guest':(isGuestInvite)?'leave-guest':(Object.keys(remoteUsers).length<3)?'join-guest':'disable-guest'
                            )}
                        />
                    </Tooltip>
                    :
                    <Tooltip placement="top" title={'配信を停止する'}>
                        <i
                            onClick={()=>{
                                requestRoleChangeAudience(ownerAddress)
                            }}
                            className={clsx(
                                classes.customBtn,
                                'disconnect'
                            )}
                        />
                    </Tooltip>
                    }
                </>:<>
                    <Tooltip placement="top" title={'デモ時はゲスト招待機能は使えません'}>
                        <i
                            className={clsx(
                                classes.customBtn,
                                'disable-guest'
                            )}
                        />
                    </Tooltip>
                </>}
            </div>
            :
            <div className={classes.menu}>
                <Tooltip placement="top" title={"ライブ開始まで操作できません"}>
                    <i
                        className={clsx(
                            classes.customBtn,
                            'disable-rotate-camera'
                        )}
                    />
                </Tooltip>
                <Tooltip placement="top" title={"ライブ開始まで操作できません"}>
                    <i
                        className={clsx(
                            classes.customBtn,
                            'disable-video'
                        )}
                    />
                </Tooltip>
                <Tooltip placement="top" title={"ライブ開始まで操作できません"}>
                    <i
                        className={clsx(
                            classes.customBtn,
                            'disable-audio'
                        )}
                    />
                </Tooltip>
                <Tooltip placement="top" title={"ライブ開始まで操作できません"}>
                    <i
                        className={clsx(
                            classes.customBtn,
                            'disable-guest'
                        )}
                    />
                </Tooltip>

            </div>
            }
        </div>
    )
}