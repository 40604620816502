import React, { useEffect } from 'react'
import clsx from 'clsx'
import {useGlobalState, useGlobalMutation} from '../../utils/container'
import useRouter from '../../utils/use-router'
import {makeStyles} from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'
import {Link} from 'react-router-dom'
import { Button, Divider, IconButton, InputAdornment, Modal, Popover, TextField, Tooltip} from '@material-ui/core'
import { useState } from 'react'
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { DateTimePicker } from '@mui/lab'
import { Typography } from '@mui/material'
import {useSymbol} from '../../hooks/useSymbol'
import {useFunction} from '../../hooks/useFunction'
import { AccountCircle } from '@material-ui/icons'
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import TocIcon from '@material-ui/icons/Toc';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Address, Convert, RepositoryFactoryHttp, TransactionMapping, UInt64 } from 'symbol-sdk'
import axios from 'axios'
import {Buffer} from 'buffer'
import useSound from 'use-sound';
import { Bars } from 'react-loader-spinner'
import { isMobile } from "react-device-detect"

const useStyles = makeStyles((theme) => ({
    menuTitle: {
        color: '#333333',
        textAlign: 'center',
        fontSize: 'h6.fontSize',
        position: 'relative',
        top: '7px'
    },
    marginTop: {
        marginTop: '10px'
    },
    marginTopDayTime: {
        marginTop: '15px'
    },
    marginTopAccount: {
        marginTop: '0px'
    },
    marginLeft: {
        marginLeft: '10px'
    },
    marginLeftDayTime: {
        marginLeft: '15px'
    },
    marginLeftAccount: {
        marginLeft: '10px'
    },
    grid: {
        margin: '0 !important'
    },
    input: {
        maxWidth: '300px',
        minWidth: '300px',
        alignSelf: 'center',
    },
    dayTime: {
        maxWidth: '200px',
        minWidth: '200px',
    },
    select: {
        maxWidth: '70px',
        minWidth: '70px',
    },
    selectDayTime: {
        maxWidth: '70px',
        minWidth: '70px',
    },
    selectAccount: {
        maxWidth: '140px',
        minWidth: '140px',
    },
    textField: {
        maxWidth: '300px',
        minWidth: '300px',
        alignSelf: 'center'
    },
    menu: {
        margin: '0.25rem 0',
        position: 'relative',
        height: '39px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    accountCheckButton: {
        lineHeight: '21px',
        color: 'rgba(255,255,255,1)',
        fontSize: '12px',
        textTransform: 'none',
        height: '40px',
        width: '150px',
        '&:hover': {
            backgroundColor: '#8200FF'
        },
        // margin: theme.spacing(1),
        marginTop: '3px',
        backgroundColor: '#4400fc',
        borderRadius: '30px'
    },
    line: {
        marginTop: '0.2rem',
        marginBottom: '0.5rem',
        borderBottom: '1px solid #EAEAEA'
    },
    hr: {
        borderBottom: '1px solid #EAEAEA'
    },
    confirmButton: {
        lineHeight: '21px',
        color: 'rgba(255,255,255,1)',
        fontSize: '17px',
        textTransform: 'none',
        height: '44px',
        width: '260px',
        '&:hover': {
            backgroundColor: '#82C2FF'
        },
        margin: theme.spacing(1),
        marginTop: '33px',
        backgroundColor: '#44a2fc',
        borderRadius: '30px'
    },
    mobaileAccountCheckButton: {
        lineHeight: '21px',
        color: 'rgba(255,255,255,1)',
        fontSize: '11px',
        textTransform: 'none',
        height: '40px',
        width: '150px',
        '&:hover': {
            backgroundColor: '#8200FF'
        },
        // margin: theme.spacing(1),
        marginTop: '3px',
        marginLeft: '3px',
        backgroundColor: '#4400fc',
        borderRadius: '30px'
    },
    mobileConfirmButton: {
        lineHeight: '21px',
        color: 'rgba(255,255,255,1)',
        fontSize: '13px',
        textTransform: 'none',
        height: '40px',
        width: '260px',
        '&:hover': {
            backgroundColor: '#82C2FF'
        },
        // margin: theme.spacing(1),
        marginTop: '3px',
        backgroundColor: '#44a2fc',
        borderRadius: '30px'
    },
}))

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 1000,
    height: 600,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const modalMobileStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 250,
    height: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

export default function ReserveCard() {
    const classes = useStyles()
    const routerCtx = useRouter()
    const stateCtx = useGlobalState()
    const mutationCtx = useGlobalMutation()
    const [isSymbolSync, setIsSymbolSync] = useState(false)
    const [playWithdrawal] = useSound("ding.ogg");
    const [playDeposit] = useSound("ding2.ogg");
    const [infoText,setInfoText] = useState("")
    const [isMosaicCheking, setIsMosaicCheking] = useState(false)
 
    const [title,setTitle] = useState("")
    const [amount,setAmount] = useState(0)
    const [dayTime,setDayTime] = useState(Date.now()+1000*60*10)
    const [period,setPeriod] = useState(1)
    const [mosaicLine,setMosaicLine] = useState("")
    const [mosaicList,setMosaicList] = useState([])
    const unixHour = 3600000
    const [isMosaicCheck, setIsMosaicCheck] = useState(false);
    const [quality, setQuality] = useState(0);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [isSymbolMetaInfo,setIsSymbolMetaInfo] = useState(false)

    const [titleOld,setTitleOld] = useState("")
    const [amountOld,setAmountOld] = useState(0)
    const [dayTimeOld,setDayTimeOld] = useState(Date.now()+1000*60*10)
    const [periodOld,setPeriodOld] = useState(1)
    const [mosaicListOld,setMosaicListOld] = useState([])
    const [qualityOld, setQualityOld] = useState(0);

    const {
        getMosaicInfo, //モザイクの存在のチェック用
        getMosaicsInfo,symbolMosaicsAmount,
        getMosaicsAccount,symbolMosaicsAccount,
        getMetaInfo,symbolMetaInfo,
        getUserBalance,symbolUserBalance,
        getSymbolMosaicsBundlenames,symbolMosaicsBundlenames,
    } = useSymbol()

    const {
        unixTimeToString,
        objectToText,
        getRateSymbol,
        rateSymbol
    } = useFunction()

    useEffect(() => {
        setTimeout(() => {
            if(stateCtx.config.loginType==="SSS"){
                window.requestSSS()
                if (!window.SSS || stateCtx.config.userAddress === "" ) {
                    console.log('not installed')
                    routerCtx.history.push("/");
                } else {
                    console.log('installed')
                    getRateSymbol()
                    getUserBalance(stateCtx.config.userAddress)
                }
            }else if(stateCtx.config.loginType==="ACCOUNT"){
                if (stateCtx.config.userAddress === "" ) {
                    console.log('not loggedin')
                    routerCtx.history.push("/");
                } else {
                    console.log('loggedin')
                    getRateSymbol()
                    getUserBalance(stateCtx.config.userAddress)
                }
            }else{
                console.log('not loggedin')
                routerCtx.history.push("/");
            }
        }, 2000) // SSSのプログラムがwindowに挿入されるよりも後に実行するために遅らせる
    }, [])

    const checkMosaicList = async (mosaicList) =>{
        setIsMosaicCheck(false)
        setIsMosaicCheking(true)
        const cehckdMosaicList = mosaicList.filter(element => element !== "") //何もない行を除外
        setMosaicList(cehckdMosaicList)
        if(cehckdMosaicList.length === 0){
            mutationCtx.toastError(
                `mosaicが一つも入力されていません`
            )
            setIsMosaicCheking(false)
            return
        }else if(cehckdMosaicList.length > 10){
            mutationCtx.toastError(
                `指定できるmosaicは10件までです`
            )
            setIsMosaicCheking(false)
            return
        }

        const cehckdMosaicListSet = new Set(cehckdMosaicList)
        if(cehckdMosaicListSet.size !== cehckdMosaicList.length){
            mutationCtx.toastError(
                `重複したmosaicが入力されています`
            )
            setIsMosaicCheking(false)
            return
        }

        for(let i = 0; i < cehckdMosaicList.length; i++){
            if(cehckdMosaicList[i]===stateCtx.config.xymAddress){
                mutationCtx.toastError(
                    `${cehckdMosaicList[i]}はXYMです。指定することはできません`
                )
                setIsMosaicCheking(false)
                return
            }
            try{
                await getMosaicInfo(cehckdMosaicList[i])
            }catch(e){
                if(e.message === 'expired mosaic'){
                    mutationCtx.toastError(
                        `${cehckdMosaicList[i]}は期限が切れたモザイクです。`
                    )
                }else if(e.message === 'non zero divisibility'){
                    mutationCtx.toastError(
                        `${cehckdMosaicList[i]}の最小単位が1以下（divisibilityが１以上）のため利用できません`
                    )
                }else if(e.message === 'BlackList mosaic'){
                    mutationCtx.toastError(
                        `${cehckdMosaicList[i]}はNFT-DRIVEで禁止リストに該当するため指定する事ができません。`
                    )
                }else if(e.message === 'FT mosaic'){
                    mutationCtx.toastError(
                        `${cehckdMosaicList[i]}はNFTもしくは、転送不可なFT（NCFT）、またはNFT-Drive発行ではないFTに該当するため指定する事ができません。`
                    )
                }else{
                    console.log(e)
                    mutationCtx.toastError(
                        `${cehckdMosaicList[i]}は存在しないモザイクです。`
                    )
                }
                setIsMosaicCheking(false)
                return
            }
        }

        await getMosaicsAccount(cehckdMosaicList)
        await getMosaicsInfo(cehckdMosaicList)

        mutationCtx.toastSuccess(
            `モザイクのチェックOK`
        )
        setIsMosaicCheck(true)
        setIsMosaicCheking(false)
    }

    useEffect(() => {
        if(isSymbolSync && symbolMosaicsAccount.length>0) setAmount(symbolMosaicsAccount.length + 1)
    },[symbolMosaicsAccount])

    useEffect(() => {
        if(!isSymbolMetaInfo){
            console.log(stateCtx.config.channelName)
            getMetaInfo(stateCtx.config.channelName)
            setIsSymbolMetaInfo(true)
        }
        if(isSymbolMetaInfo && Object.keys(symbolMetaInfo).length===0){ //新規予約処理
            setIsSymbolSync(true)
        }
        if(isSymbolMetaInfo && Object.keys(symbolMetaInfo).length>0){ //予約変更処理
            console.log(symbolMetaInfo)
            console.log(symbolMetaInfo.id)
            console.log(symbolMetaInfo.data.title) //
            console.log(symbolMetaInfo.data.st) //
            console.log(symbolMetaInfo.data.ed) //
            console.log(symbolMetaInfo.data.amount) //
            console.log(symbolMetaInfo.data.mosaic) //
            console.log(symbolMetaInfo.data.owner)
            console.log(symbolMetaInfo.data.quality)
            setTitle(Convert.decodeHex(symbolMetaInfo.data.title))
            setTitleOld(Convert.decodeHex(symbolMetaInfo.data.title))
            setAmount(symbolMetaInfo.data.amount)
            setAmountOld(symbolMetaInfo.data.amount)
            setDayTime(symbolMetaInfo.data.st)
            setDayTimeOld(symbolMetaInfo.data.st)
            setPeriod((symbolMetaInfo.data.ed - symbolMetaInfo.data.st)/3600000)
            setPeriodOld((symbolMetaInfo.data.ed - symbolMetaInfo.data.st)/3600000)
            setQuality(symbolMetaInfo.data.quality)
            setQualityOld(symbolMetaInfo.data.quality)
            setMosaicList(symbolMetaInfo.data.mosaic)
            setMosaicListOld(symbolMetaInfo.data.mosaic)
            setMosaicLine(symbolMetaInfo.data.mosaic.join('\n'))
            checkMosaicList(symbolMetaInfo.data.mosaic).then(()=>{
                setIsSymbolSync(true)
            })
        }
    },[symbolMetaInfo])

    const makeObjectToText = () => {
        const reserveObject = {
            title:Convert.utf8ToHex(title),
            st:dayTime,
            ed:dayTime+period*unixHour,
            amount:amount,
            mosaic:mosaicList,
            owner:stateCtx.config.userAddress,
            quality:quality,
            fee:(amount <= 11 && period <= 1 && quality <= 1)?0:(stateCtx.config.channelName!=="")?diffChangeFee():Math.round(stateCtx.config.sytemFee*period*amount*(quality===2?2:1)/rateSymbol * 1000000) / 1000000
        }
        console.log(reserveObject)
        console.log(objectToText(reserveObject))
        return objectToText(reserveObject)
    }

    const confirmButtonClick = async () => {
        if(!isMosaicCheck){
            mutationCtx.toastError(
                `モザイクのチェックが未完了です。鍵アイコンをクリックしてモザイクのチェックを完了して下さい`
            )
            return
        }

        if(amount<2 || !amount){
            mutationCtx.toastError(
                `予約人数（配信者含む）が2未満です`
            )
            return
        }

        if(amount>symbolMosaicsAmount+1){
            mutationCtx.toastError(
                `予約人数（配信者含む）がモザイクの総発行数+配信者を超えて設定することはできません`
            )
            return
        }

        if(stateCtx.config.loginType==="ACCOUNT" && amount>11){
            mutationCtx.toastError(
                `11人を超える予約は有料になります。SSSを連携して予約して下さい。`
            )
            return
        }

        if(title === ""){
            mutationCtx.toastError(
                `イベントタイトルが入力されていません`
            )
            return
        }
        if(dayTime === undefined){
            mutationCtx.toastError(
                `予約開始時間を指定して下さい`
            )
            return
        }

        if(!isMosaicCheck){
            checkMosaicList(mosaicList)
        }

        if(!isMosaicCheck){
            mutationCtx.toastError(
                `モザイクに不備がありました`
            )
            return
        }
        //ここで無料範囲外であれば残高のチェックを行う
        if(!(amount <= 11 && period <= 1 && quality <= 1)){
            if(stateCtx.config.channelName!==""){
                if(diffChangeFee()>symbolUserBalance){
                    mutationCtx.toastError(
                        `残高が不足しているため予約変更が出来ません`
                    )
                    return
                }
            }else{
                if(Math.round(stateCtx.config.sytemFee*period*amount*(quality===2?2:1)/rateSymbol * 1000000) / 1000000>symbolUserBalance){
                    mutationCtx.toastError(
                        `残高が不足しているため予約が出来ません`
                    )
                    return
                }
            }
        }
        //ここでbundleの名前を取得する
        getSymbolMosaicsBundlenames(mosaicList)

        console.log(infoText)
        handleOpen()
    }

    const paymentButtonClick = async () => {
        const NODE = stateCtx.config.nodeUrl
        const repo = new RepositoryFactoryHttp(NODE);
        const generationHash = await repo.getGenerationHash().toPromise();
        let text = "申請情報を送信中"
        setInfoText(text)
        if(stateCtx.config.loginType === "SSS"){
            axios.get("https://us-central1-tokenlive-b98bd.cloudfunctions.net/getSignedPayload",{
                params: {
                  data: makeObjectToText(),
                  mosaic: {
                      mosaic: stateCtx.config.adminMosaic
                  },
                  pubkey: {
                    pubkey: window.SSS.activePublicKey
                  },
                  metakey: {
                    metakey: (stateCtx.config.channelName!=="")?parseInt(stateCtx.config.channelName, 16):dayTime+period*unixHour
                  },
                  node: {
                    node: NODE
                  },
                }
            }).then((getSignedPayload) => {
                alert("サーバーより署名要求があります。SSSで内容を確認の上サインを行って下さい")
                text = "SSSを開き内容を確認後、署名を行なって下さい。"
                setInfoText(text)
                const tx = TransactionMapping.createFromPayload(getSignedPayload.data.signedPayload);
                const res = tx.signer.verifySignature(
                    tx.getSigningBytes([...Buffer.from(getSignedPayload.data.signedPayload,'hex')],[...Buffer.from(generationHash,'hex')]),
                    tx.signature
                )
                if(res){//検証がOKであれば
                    console.log(text)
                    const timerId = setTimeout(() => {
                        if(text==="SSSを開き内容を確認後、署名を行なって下さい。"){
                            console.log("ここに入ったよ")
                            mutationCtx.toastError('1分経過してもSSSにて署名がされなかったので送金が無効になりました。')
                            setInfoText("")
                            handleClose()
                        }
                    }, 61000)
                    window.SSS.setTransactionByPayload(getSignedPayload.data.signedPayload)
                    window.SSS.requestSignCosignatureTransaction().then((ownerSignedTx) => {
                        clearTimeout(timerId)
                        text = "署名を行いました。承認されるまで30秒以上かかります。しばらくこのままお待ち下さい"
                        setInfoText(text)
                        playWithdrawal()
                        const ownerSignedTxSignature = ownerSignedTx.signature;
                        const ownerSignedTxSignerPublicKey = ownerSignedTx.signerPublicKey;
                        axios.get("https://us-central1-tokenlive-b98bd.cloudfunctions.net/getCosignatureSignedTransaction",{
                            params: {
                                textdata: makeObjectToText(),
                                data:{
                                    signedPayload: getSignedPayload.data.signedPayload,
                                    ownerSignedTxSignature: ownerSignedTxSignature,
                                    ownerSignedTxSignerPublicKey:ownerSignedTxSignerPublicKey,
                                },
                                textmetakey: {
                                    textmetakey:(stateCtx.config.channelName!=="")?UInt64.fromUint(parseInt(stateCtx.config.channelName, 16)).toHex():UInt64.fromUint(dayTime+period*unixHour).toHex()
                                },
                                node: {
                                    node: NODE
                                },
                            }
                        }).then((getCosignatureSignedTransaction)=>{
                            const repositoryFactory = new RepositoryFactoryHttp(stateCtx.config.nodeUrl, {
                                websocketUrl: `${stateCtx.config.nodeUrl.replace('http', 'ws')}/ws`,
                                websocketInjected: WebSocket
                                });
                            const listener = repositoryFactory.createListener();
                            listener.open().then(() => {
                                listener.newBlock();
                                listener.confirmed(Address.createFromRawAddress(stateCtx.config.userAddress))
                                .subscribe(tx => {
                                    console.log(tx)
                                    text = "予約が完了しました。"
                                    setInfoText(text)
                                    playDeposit()
                                    listener.close()
                                });
                            });
                        })
                    })
                }
            }).catch((err)=>{
                console.log(err);
            })
        }else if(stateCtx.config.loginType === "ACCOUNT"){
            text = "予約申請を行いました。承認されるまで30秒以上かかります。しばらくこのままお待ち下さい"
            setInfoText(text)
            axios.get(`https://us-central1-tokenlive-b98bd.cloudfunctions.net/mobileGetSignedPayload?data=${makeObjectToText()}&mosaic=${stateCtx.config.adminMosaic}&pubkey=${stateCtx.config.userPublicKey}&metakey=${(stateCtx.config.channelName!=="")?parseInt(stateCtx.config.channelName, 16):dayTime+period*unixHour}&node=${stateCtx.config.nodeUrl}&wsNode=${stateCtx.config.nodeUrl.replace('http', 'ws')}/ws&userAddress=${stateCtx.config.userAddress}`)
            .then((res)=>{
                console.log(res)
                text = "予約が完了しました。"
                setInfoText(text)
            }).catch((e)=>{
                console.log(e.response)
                console.log(e.response.request)
                console.log(e.response.request.status)
            }).finally(()=>{
            })
        }
    }

    const diffChangeFee = () => {
        let changeFee = 0
        const old = amountOld * (qualityOld===2?2:1) * periodOld
        const now = amount * (quality===2?2:1) * period
        const diff = now - old
        if(diff>0){
            return Math.round(stateCtx.config.sytemFee*diff/rateSymbol * 1000000) / 1000000
        }else{
            return changeFee
        }
    }

    //Popover関連
    const [accountListAnchorEl, setAccountListAnchorEl] = useState(null);
    const accountListPopoverOpen = Boolean(accountListAnchorEl);
    const accountListPopoverid = accountListPopoverOpen ? 'simple-popper' : undefined;
    const accountListClick = (event) => {
        setAccountListAnchorEl(accountListAnchorEl ? null : event.currentTarget);
    }
    const accountListClose = () => {
        setAccountListAnchorEl(null);
    }

    return (
        <>
        {(isSymbolSync && !isMosaicCheking)?
        <>
        <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {!isMobile?
            <Box sx={modalStyle}>
                {(infoText==="")?
                    <>
                    <Typography variant="h6" component="div" gutterBottom>
                        {(stateCtx.config.channelName!=="")?`予約変更情報`:`予約情報`}
                    </Typography>
                    <Box display={"flex"} alignItems={"center"}>
                        <Box width={150}><Typography variant="caption" component="div" gutterBottom>予約ID</Typography></Box>
                        <Box width={300}><Typography variant="caption" component="div" gutterBottom>{stateCtx.config.channelName}</Typography></Box>
                        {(stateCtx.config.channelName!=="")? <>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={20}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={300}><Typography variant="caption" component="div" gutterBottom>変更前</Typography></Box>
                        </>:<></>}
                    </Box>
                    <Divider/>
                    <Box display={"flex"} alignItems={"center"}>
                        <Box width={150}><Typography variant="caption" component="div" gutterBottom>イベントタイトル</Typography></Box>
                        <Box width={300}><Typography variant="caption" component="div" gutterBottom>{title}</Typography></Box>
                        {(stateCtx.config.channelName!=="" && title !== titleOld)? <>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={20}><ArrowBackIosIcon /></Box>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={300}><Typography variant="caption" component="div" gutterBottom>{titleOld}</Typography></Box>
                        </>:<></>}
                    </Box>
                    <Divider/>
                    <Box display={"flex"} alignItems={"center"}>
                        <Box width={150}><Typography variant="caption" component="div" gutterBottom>予約時間</Typography></Box>
                        <Box width={300}><Typography variant="caption" component="div" gutterBottom>{unixTimeToString(dayTime)} - {unixTimeToString(dayTime+period*unixHour)}</Typography></Box>
                        {(stateCtx.config.channelName!=="" && (dayTime !== dayTimeOld || period !== periodOld))? <>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={20}><ArrowBackIosIcon /></Box>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={300}><Typography variant="caption" component="div" gutterBottom>{unixTimeToString(dayTimeOld)} - {unixTimeToString(dayTimeOld+periodOld*unixHour)}</Typography></Box>
                        </>:<></>}

                    </Box>
                    <Divider/>
                    <Box display={"flex"} alignItems={"center"}>
                        <Box width={150}><Typography variant="caption" component="div" gutterBottom>配信モード</Typography></Box>
                        <Box width={300}><Typography variant="caption" component="div" gutterBottom>{(quality===0)?`中画質マルチ配信モード(640 × 360)`:(quality===1)?`高画質シングル配信モード(1280 × 720)`:`高画質マルチ配信モード (1920 × 1080)`}</Typography></Box>
                        {(stateCtx.config.channelName!=="" && quality !== qualityOld)? <>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={20}><ArrowBackIosIcon /></Box>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={300}><Typography variant="caption" component="div" gutterBottom>{(qualityOld===0)?`中画質マルチ配信モード(640 × 360)`:(quality===1)?`高画質シングル配信モード(1280 × 720)`:`高画質マルチ配信モード (1920 × 1080)`}</Typography></Box>
                        </>:<></>}
                    </Box>
                    <Divider/>
                    <Box display={"flex"} alignItems={"center"}>
                        <Box width={150}><Typography variant="caption" component="div" gutterBottom>参加条件モザイク</Typography></Box>
                        <Box width={300}><Typography variant="caption" component="div" gutterBottom>{Object.keys(symbolMosaicsBundlenames).map((key,j)=>((symbolMosaicsBundlenames[key]["title"] !== "")?`${symbolMosaicsBundlenames[key]["title"]}, `:`${key}, `))}</Typography></Box>
                        {(stateCtx.config.channelName!=="" && JSON.stringify(mosaicList) !== JSON.stringify(mosaicListOld))? <>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={20}><ArrowBackIosIcon /></Box>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={300}><Typography variant="caption" component="div" gutterBottom>{mosaicListOld.join(' , ')}</Typography></Box>
                        </>:<></>}
                    </Box>
                    <Divider/>
                    <Box display={"flex"} alignItems={"center"}>
                        <Box width={150}><Typography variant="caption" component="div" gutterBottom>招待人数</Typography></Box>
                        <Box width={300}><Typography variant="caption" component="div" gutterBottom>{`${amount}アカウント（配信者含む）`}</Typography></Box>
                        {(stateCtx.config.channelName!=="" && amount !== amountOld)? <>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={20}><ArrowBackIosIcon /></Box>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={300}><Typography variant="caption" component="div" gutterBottom>{`${amountOld}アカウント（配信者含む）`}</Typography></Box>
                        </>:<></>}
                    </Box>
                    <Divider/>
                    <Box display={"flex"} alignItems={"center"} marginBottom={1}>
                        <Box width={150}><Typography variant="caption" component="div" gutterBottom>利用料(＄)</Typography></Box>
                        <Box width={300}><Typography variant="caption" component="div" gutterBottom>{`通信料金 $${stateCtx.config.sytemFee} × 通信時間 ${period}h × 配信モードオプション ${quality===2?2:1}倍 × 招待人数${amount}人 = $${Math.round(stateCtx.config.sytemFee*period*amount*(quality===2?2:1)*100)/100}`}</Typography></Box>
                        {(stateCtx.config.channelName!=="" && Math.round(stateCtx.config.sytemFee*period*amount*(quality===2?2:1)*100)/100 !== Math.round(stateCtx.config.sytemFee*periodOld*amountOld*(qualityOld===2?2:1)*100)/100)? <>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={20}><ArrowBackIosIcon /></Box>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={300}><Typography variant="caption" component="div" gutterBottom>{`通信料金 $${stateCtx.config.sytemFee} × 通信時間 ${periodOld}h × 配信モードオプション ${qualityOld===2?2:1}倍 × 招待人数${amountOld}人 = $${Math.round(stateCtx.config.sytemFee*periodOld*amountOld*(qualityOld===2?2:1)*100)/100}`}</Typography></Box>
                        </>:<></>}
                    </Box>
                    <Box display={"flex"} alignItems={"center"} marginBottom={1}>
                        <Box width={150}><Typography variant="caption" component="div" gutterBottom>{(stateCtx.config.channelName!=="")?`追加費用(XYM)`:`利用料(XYM)`}</Typography></Box>
                        {(amount <= 11 && period <= 1 && quality <= 1)
                        ?
                        <Box width={300}><Typography variant="h6" component="div" gutterBottom><del>{(stateCtx.config.channelName!=="")?`${diffChangeFee()}xym`:`${Math.round(stateCtx.config.sytemFee*period*amount*(quality===2?2:1)/rateSymbol * 1000000) / 1000000}xym`}</del>{"　=>　0xym"}</Typography></Box>
                        :
                        <Box width={300}><Typography variant="h6" component="div" gutterBottom>{(stateCtx.config.channelName!=="")?`${diffChangeFee()}xym`:`${Math.round(stateCtx.config.sytemFee*period*amount*(quality===2?2:1)/rateSymbol * 1000000) / 1000000}xym`}</Typography></Box>
                        }
                    </Box>
                    {(amount <= 11 && period <= 1 && quality <= 1)?
                    <Box><Typography variant="caption" component="div" gutterBottom color={"red"} fontWeight={"bold"}>※1時間以内かつ予約人数11人までは無料でお使いいただけるキャンペーン中です！</Typography></Box>
                    :<></>}
                    <Box><Typography variant="caption" component="div" gutterBottom>※xymのレートは<a href='https://min-api.cryptocompare.com/data/price?fsym=XYM&tsyms=USD' target="_blank" rel="noopener noreferrer">Cryptocompareのapi</a>を利用しているため、都度変動しています</Typography></Box>
                    <Box></Box>
                    <br/>
                    <Box display={"flex"} justifyContent={"space-around"} alignItems={"baseline"}>
                        <Button
                            onClick={paymentButtonClick}
                            variant="contained"
                            color="primary"
                            className={classes.confirmButton}
                        >
                            {(stateCtx.config.channelName!=="")?`予約の変更を行う`:(amount <= 11 && period <= 1 && quality <= 1)?`無料で予約する`:`支払いを行う`}
                        </Button>
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            color="primary"
                            className={classes.accountCheckButton}
                        >キャンセル</Button>
                    </Box>
                    </>
                :
                    <>
                    <Typography className={"info-text"} variant="body2" component="div">
                        {infoText}
                    </Typography>
                    {(infoText==="SSSを開き内容を確認後、署名を行なって下さい。")?
                        <>
                        <br></br>
                        <Typography variant="body2" component="div" gutterBottom>{`＜署名確認事項＞`}</Typography>
                        <Typography variant="caption" component="div">{`　・Tx 1 MOSAIC_METADATA　で指定されるモザイクが以下であること`}</Typography>
                        <Typography variant="body2" component="div">{`　　${stateCtx.config.adminMosaic}`}</Typography>
                        <br></br>
                        <Typography variant="caption" component="div">{`　・Tx 2 TRANSFER　で指定されるアドレスが以下であること`}</Typography>
                        <Typography variant="body2" component="div">{`　　${stateCtx.config.adminAddress}`}</Typography>
                        <br></br>
                        <Typography variant="caption" component="div">{`　・Tx 2 TRANSFER　で指定されるMosaics（xym）の数が以下であること`}</Typography>
                        <Typography variant="body2" component="div">{(stateCtx.config.channelName!=="")?`　　${diffChangeFee()}xym`:`　　${Math.round(stateCtx.config.sytemFee*period*amount*(quality===2?2:1)/rateSymbol * 1000000) / 1000000}xym`}</Typography>
                        <br></br>
                        <Typography variant="body2" component="div" gutterBottom>{`＜その他注意事項＞`}</Typography>
                        <Typography variant="caption" component="div" gutterBottom>{`　※SSSの署名は60秒以内に行わないと無効になります`}</Typography>
                        <Typography variant="caption" component="div" gutterBottom>{`　※署名後、既に支払われたxymを返金することはできません`}</Typography>
                        <Typography variant="caption" component="div" gutterBottom>{`　※予約は後ほど変更できますが、xymが足りない場合は追加の支払いが必要になります`}</Typography>
                        <br></br>
                        <Box display={"flex"} justifyContent={"space-around"} alignItems={"baseline"}>
                            <Button
                                onClick={()=>{
                                    setInfoText("")
                                    handleClose()
                                }}
                                variant="contained"
                                color="primary"
                                className={classes.accountCheckButton}
                            >キャンセル</Button>
                        </Box>

                        </>
                    :
                        <>
                            <Box
                                flex="1"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                flexDirection="column"
                                margin={5}
                            >
                                <Bars height={50} width={50} color={"#44A2FC"}></Bars>
                            </Box>
                        </>
                    }
                        {(infoText==="予約が完了しました。")?
                        <>
                        <br></br>
                        <Box display={"flex"} justifyContent={"space-around"} alignItems={"baseline"}>
                            <Button
                                onClick={()=>{
                                    setInfoText("")
                                    handleClose()
                                    routerCtx.history.push('/index')
                                    window.location.reload()
                                }}
                                variant="contained"
                                color="primary"
                                className={classes.confirmButton}
                            >トップページへ移動する</Button>
                        </Box>
                        </>
                    :
                        <></>
                    }
                    </>
                }
            </Box>
            :
            <Box sx={modalMobileStyle}>
                {(infoText==="")?
                    <>
                    <Typography variant="body2" component="div" gutterBottom>
                        {(stateCtx.config.channelName!=="")?`予約変更情報`:`予約情報`}
                    </Typography>
                    <Box display={"flex"} alignItems={"center"}>
                        <Box width={150}><Typography variant="caption" component="div" gutterBottom>予約ID</Typography></Box>
                        <Box width={300}><Typography variant="caption" component="div" gutterBottom>{stateCtx.config.channelName}</Typography></Box>
                        {/* {(stateCtx.config.channelName!=="")? <>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={20}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={300}><Typography variant="caption" component="div" gutterBottom>変更前</Typography></Box>
                        </>:<></>} */}
                    </Box>
                    <Divider/>
                    <Box display={"flex"} alignItems={"center"}>
                        <Box width={150}><Typography variant="caption" component="div" gutterBottom>タイトル</Typography></Box>
                        <Box width={300}><Typography variant="caption" component="div" gutterBottom>{title}</Typography></Box>
                        {/* {(stateCtx.config.channelName!=="" && title !== titleOld)? <>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={20}><ArrowBackIosIcon /></Box>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={300}><Typography variant="caption" component="div" gutterBottom>{titleOld}</Typography></Box>
                        </>:<></>} */}
                    </Box>
                    <Divider/>
                    <Box display={"flex"} alignItems={"center"}>
                        <Box width={150}><Typography variant="caption" component="div" gutterBottom>予約時間</Typography></Box>
                        <Box width={300}><Typography variant="caption" component="div" gutterBottom>{unixTimeToString(dayTime)} - </Typography><Typography variant="caption" component="div" gutterBottom>{unixTimeToString(dayTime+period*unixHour)}</Typography></Box>
                        {/* {(stateCtx.config.channelName!=="" && (dayTime !== dayTimeOld || period !== periodOld))? <>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={20}><ArrowBackIosIcon /></Box>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={300}><Typography variant="caption" component="div" gutterBottom>{unixTimeToString(dayTimeOld)} - {unixTimeToString(dayTimeOld+periodOld*unixHour)}</Typography></Box>
                        </>:<></>} */}

                    </Box>
                    <Divider/>
                    <Box display={"flex"} alignItems={"center"}>
                        <Box width={150}><Typography variant="caption" component="div" gutterBottom>配信モード</Typography></Box>
                        <Box width={300}><Typography variant="caption" component="div" gutterBottom>{(quality===0)?`中画質マルチ配信モード(640 × 360)`:(quality===1)?`高画質シングル配信モード(1280 × 720)`:`高画質マルチ配信モード (1920 × 1080)`}</Typography></Box>
                        {/* {(stateCtx.config.channelName!=="" && quality !== qualityOld)? <>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={20}><ArrowBackIosIcon /></Box>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={300}><Typography variant="caption" component="div" gutterBottom>{(qualityOld===0)?`中画質マルチ配信モード(640 × 360)`:(quality===1)?`高画質シングル配信モード(1280 × 720)`:`高画質マルチ配信モード (1920 × 1080)`}</Typography></Box>
                        </>:<></>} */}
                    </Box>
                    <Divider/>
                    <Box display={"flex"} alignItems={"center"}>
                        <Box width={150}><Typography variant="caption" component="div" gutterBottom>参加条件</Typography></Box>
                        <Box width={300}><Typography variant="caption" component="div" gutterBottom>{Object.keys(symbolMosaicsBundlenames).map((key,j)=>((symbolMosaicsBundlenames[key]["title"] !== "")?`${symbolMosaicsBundlenames[key]["title"]}, `:`${key}, `))}</Typography></Box>
                        {/* {(stateCtx.config.channelName!=="" && JSON.stringify(mosaicList) !== JSON.stringify(mosaicListOld))? <>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={20}><ArrowBackIosIcon /></Box>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={300}><Typography variant="caption" component="div" gutterBottom>{mosaicListOld.join(' , ')}</Typography></Box>
                        </>:<></>} */}
                    </Box>
                    <Divider/>
                    <Box display={"flex"} alignItems={"center"}>
                        <Box width={150}><Typography variant="caption" component="div" gutterBottom>招待人数</Typography></Box>
                        <Box width={300}><Typography variant="caption" component="div" gutterBottom>{`${amount}アカウント（配信者含む）`}</Typography></Box>
                        {/* {(stateCtx.config.channelName!=="" && amount !== amountOld)? <>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={20}><ArrowBackIosIcon /></Box>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={300}><Typography variant="caption" component="div" gutterBottom>{`${amountOld}アカウント（配信者含む）`}</Typography></Box>
                        </>:<></>} */}
                    </Box>
                    <Divider/>
                    <Box display={"flex"} alignItems={"center"} marginBottom={1}>
                        <Box width={150}><Typography variant="caption" component="div" gutterBottom>利用料(＄)</Typography></Box>
                        <Box width={300}><Typography variant="caption" component="div" gutterBottom>{`通信料金 $${stateCtx.config.sytemFee} × 通信時間 ${period}h × 配信モードオプション ${quality===2?2:1}倍 × 招待人数${amount}人 = $${Math.round(stateCtx.config.sytemFee*period*amount*(quality===2?2:1)*100)/100}`}</Typography></Box>
                        {/* {(stateCtx.config.channelName!=="" && Math.round(stateCtx.config.sytemFee*period*amount*(quality===2?2:1)*100)/100 !== Math.round(stateCtx.config.sytemFee*periodOld*amountOld*(qualityOld===2?2:1)*100)/100)? <>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={20}><ArrowBackIosIcon /></Box>
                            <Box width={50}><Typography variant="caption" component="div" gutterBottom>　</Typography></Box>
                            <Box width={300}><Typography variant="caption" component="div" gutterBottom>{`通信料金 $${stateCtx.config.sytemFee} × 通信時間 ${periodOld}h × 配信モードオプション ${qualityOld===2?2:1}倍 × 招待人数${amountOld}人 = $${Math.round(stateCtx.config.sytemFee*periodOld*amountOld*(qualityOld===2?2:1)*100)/100}`}</Typography></Box>
                        </>:<></>} */}
                    </Box>
                    <Box display={"flex"} alignItems={"center"} marginBottom={1}>
                    <Box width={150}><Typography variant="caption" component="div" gutterBottom>XYM</Typography></Box>
                        {(amount <= 11 && period <= 1 && quality <= 1)
                        ?
                        <Box width={300}><Typography variant="caption" component="div" gutterBottom><del>{(stateCtx.config.channelName!=="")?`${diffChangeFee()}xym`:`${Math.round(stateCtx.config.sytemFee*period*amount*(quality===2?2:1)/rateSymbol * 1000000) / 1000000}xym`}</del>{"　=>　0xym"}</Typography></Box>
                        :
                        <Box width={300}><Typography variant="caption" component="div" gutterBottom>{(stateCtx.config.channelName!=="")?`${diffChangeFee()}xym`:`${Math.round(stateCtx.config.sytemFee*period*amount*(quality===2?2:1)/rateSymbol * 1000000) / 1000000}xym`}</Typography></Box>
                        }
                    </Box>
                    {(amount <= 11 && period <= 1 && quality <= 1)?
                    <Box><Typography variant="caption" component="div" gutterBottom color={"red"} fontWeight={"bold"}>※1時間以内かつ予約人数11人までは無料でお使いいただけるキャンペーン中です！</Typography></Box>
                    :<></>}
                    <Box><Typography variant="caption" component="div" gutterBottom>※xymのレートは<a href='https://min-api.cryptocompare.com/data/price?fsym=XYM&tsyms=USD' target="_blank" rel="noopener noreferrer">Cryptocompareのapi</a>を利用しているため、都度変動しています</Typography></Box>
                    <Box></Box>
                    <br/>
                    <Box display={"flex"} justifyContent={"space-around"} alignItems={"baseline"}>
                        <Button
                            onClick={paymentButtonClick}
                            variant="contained"
                            color="primary"
                            className={classes.mobileConfirmButton}
                        >
                            {(stateCtx.config.channelName!=="")?`予約の変更を行う`:(amount <= 11 && period <= 1 && quality <= 1)?`無料で予約する`:`支払いを行う`}
                        </Button>
                        <Button
                            onClick={handleClose}
                            variant="contained"
                            color="primary"
                            className={classes.mobaileAccountCheckButton}
                        >キャンセル</Button>
                    </Box>
                    </>
                :
                    <>
                    <Typography className={"info-text"} variant="body2" component="div">
                        {infoText}
                    </Typography>
                    {(infoText==="SSSを開き内容を確認後、署名を行なって下さい。")?
                        <>
                        <br></br>
                        <Typography variant="body2" component="div" gutterBottom>{`＜署名確認事項＞`}</Typography>
                        <Typography variant="caption" component="div">{`　・Tx 1 MOSAIC_METADATA　で指定されるモザイクが以下であること`}</Typography>
                        <Typography variant="body2" component="div">{`　　${stateCtx.config.adminMosaic}`}</Typography>
                        <br></br>
                        <Typography variant="caption" component="div">{`　・Tx 2 TRANSFER　で指定されるアドレスが以下であること`}</Typography>
                        <Typography variant="body2" component="div">{`　　${stateCtx.config.adminAddress}`}</Typography>
                        <br></br>
                        <Typography variant="caption" component="div">{`　・Tx 2 TRANSFER　で指定されるMosaics（xym）の数が以下であること`}</Typography>
                        <Typography variant="body2" component="div">{(stateCtx.config.channelName!=="")?`　　${diffChangeFee()}xym`:`　　${Math.round(stateCtx.config.sytemFee*period*amount*(quality===2?2:1)/rateSymbol * 1000000) / 1000000}xym`}</Typography>
                        <br></br>
                        <Typography variant="body2" component="div" gutterBottom>{`＜その他注意事項＞`}</Typography>
                        <Typography variant="caption" component="div" gutterBottom>{`　※SSSの署名は60秒以内に行わないと無効になります`}</Typography>
                        <Typography variant="caption" component="div" gutterBottom>{`　※署名後、既に支払われたxymを返金することはできません`}</Typography>
                        <Typography variant="caption" component="div" gutterBottom>{`　※予約は後ほど変更できますが、xymが足りない場合は追加の支払いが必要になります`}</Typography>
                        <br></br>
                        <Box display={"flex"} justifyContent={"space-around"} alignItems={"baseline"}>
                            <Button
                                onClick={()=>{
                                    setInfoText("")
                                    handleClose()
                                }}
                                variant="contained"
                                color="primary"
                                className={classes.mobaileAccountCheckButton}
                            >キャンセル</Button>
                        </Box>

                        </>
                    :
                        <>
                            <Box
                                flex="1"
                                display="flex"
                                alignItems="center"
                                justifyContent="center"
                                flexDirection="column"
                                margin={5}
                            >
                                <Bars height={50} width={50} color={"#44A2FC"}></Bars>
                            </Box>
                        </>
                    }
                        {(infoText==="予約が完了しました。")?
                        <>
                        <br></br>
                        <Box display={"flex"} justifyContent={"space-around"} alignItems={"baseline"}>
                            <Button
                                onClick={()=>{
                                    setInfoText("")
                                    handleClose()
                                    routerCtx.history.push('/index')
                                    window.location.reload()
                                }}
                                variant="contained"
                                color="primary"
                                className={classes.mobileConfirmButton}
                            >トップページへ移動する</Button>
                        </Box>
                        </>
                    :
                        <></>
                    }
                    </>
                }
            </Box>
            }

        </Modal>
        <Popover
            id={accountListPopoverid}
            open={accountListPopoverOpen}
            onClose={accountListClose}
            anchorEl={accountListAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
        >
            <Box sx={{ display: 'flex',flex: '1',margin:20, alignItems:"center" , flexDirection:'column',height: 400 , width:500 , border: 1, p: 3, bgcolor: 'background.paper'}}>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: "490px"},
                }}
                noValidate
                autoComplete="off"
            >
                <TextField
                    label="モザイク保有者一覧（現時点）"
                    multiline
                    rows={5}
                    defaultValue={
                        symbolMosaicsAccount.join('\n')
                    }
                />
                <Box display={"flex"} alignItems={"center"}>
                    <Typography variant="caption" component="div" gutterBottom>{`※現在のモザイク保有者は　`}</Typography>
                    <Typography variant="body1" component="div" gutterBottom>{`${symbolMosaicsAccount.length}`}</Typography>
                    <Typography variant="caption" component="div" gutterBottom>{`　です。`}</Typography>
                </Box>
                <Box display={"flex"} alignItems={"center"}>
                    <Typography variant="caption" component="div" gutterBottom>{`※モザイクの総発行数の合計は　`}</Typography>
                    <Typography variant="body1" component="div" gutterBottom>{`${symbolMosaicsAmount}`}</Typography>
                    <Typography variant="caption" component="div" gutterBottom>{`　です。`}</Typography>
                </Box>

                <Typography variant="caption" component="div" gutterBottom>{`※指定した参加者数以上は入室する事ができません。`}</Typography>
                <Typography variant="caption" component="div" gutterBottom>{`※保有者が増える予定があれば、その分を考慮した人数を指定して下さい`}</Typography>
                <Typography variant="caption" component="div" gutterBottom>{`※後から人数の追加は可能です。その際追加人数分の利用料が別途必要になります`}</Typography>
            </Box>
                <Button onClick={()=>{
                    accountListClose()
                }}>
                    閉じる
                </Button>
            </Box>
        </Popover>
        <Box flex="1" display="flex" flexDirection="column">
            <Link to="/index" className="back-btn"/>
            <Box
                display="flex"
                flex="1"
                flexDirection="column"
                padding="0 1rem"
                justifyContent="flex-start"
            >
                <FormControl className={classes.menu}>
                    <Typography variant="body2" component="div" gutterBottom>{(stateCtx.config.channelName===""?`新規予約`:`変更ID　${stateCtx.config.channelName}`)}</Typography>
                </FormControl>
                <Box className={classes.line}></Box>
                <Box marginTop={1} />
                <FormControl className={clsx(classes.textField, classes.grid)}>
                    <TextField
                        label="イベントタイトル"
                        InputProps={{
                            style: { fontSize: 13 },
                            startAdornment: (
                                <TocIcon position="start">
                                <VpnKeyIcon/>
                                </TocIcon>
                            ),
                            }}
                        placeholder=' 25文字以下で入力してください'
                        value={title}
                        onChange={(evt) => {
                            const PATTERN = /[0-9]{0,26}/
                            const value = PATTERN.test(evt.target.value)
                            if (value && evt.target.value.length < 26) {
                                setTitle(evt.target.value)
                            } else {
                                setTitle(title)
                            }
                        }}
                        disabled={(dayTimeOld<Date.now())?true:false}
                    />
                </FormControl>
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"} marginTop={1}>
                <Tooltip title={(dayTimeOld<Date.now())?"ライブ開始後は開始時間を変更できません":"右のカレンダーアイコンをクリック"} placement="bottom">
                    <FormControl className={clsx(classes.input, classes.dayTime)}>
                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    InputProps={{ style: { fontSize: 13 , color:"black"} }}
                                    InputLabelProps={{ style: { fontSize: 13 } }}
                                    renderInput={(props) => <TextField {...props} disabled/>}
                                    label="ライブ開始時間"
                                    value={dayTime}
                                    onChange={(newValue) => {
                                        setDayTime(newValue.getTime())
                                    }}
                                    minDateTime={Date.now()}
                                    maxDateTime={Date.now()+3600000*24*7} //１週間後をUnixTimeで
                                    onOpen={()=>mutationCtx.toastInfo(`現在から１週間先までを指定できます`)}
                                    disabled={(dayTimeOld<Date.now())?true:false}
                                />
                            </LocalizationProvider>
                    </FormControl>
                </Tooltip>
                    <FormControl className={clsx(classes.selectDayTime, classes.marginLeftDayTime)}>
                        <InputLabel>予約時間</InputLabel>
                        <Select
                            InputProps={{ style: { fontSize: 13 } }}
                            value={period}
                            onChange={(evt) => {
                                setPeriod(evt.target.value)
                            }}
                            onOpen={() => {
                                if(stateCtx.config.loginType==="ACCOUNT") mutationCtx.toastInfo(`1時間以上予約する場合はSSS連携を行なって下さい`)
                            }}
                        >
                            <MenuItem value={0.25} >15分</MenuItem>
                            <MenuItem value={0.5} >30分</MenuItem>
                            <MenuItem value={0.75} >45分</MenuItem>
                            <MenuItem value={1} >1時間</MenuItem>
                            <MenuItem value={1.25} disabled={(stateCtx.config.loginType==="ACCOUNT")}>1時間15分</MenuItem>
                            <MenuItem value={1.5} disabled={(stateCtx.config.loginType==="ACCOUNT")}>1時間30分</MenuItem>
                            <MenuItem value={1.75} disabled={(stateCtx.config.loginType==="ACCOUNT")}>1時間45分</MenuItem>
                            <MenuItem value={2} disabled={(stateCtx.config.loginType==="ACCOUNT")}>2時間</MenuItem>
                            <MenuItem value={2.25} disabled={(stateCtx.config.loginType==="ACCOUNT")}>2時間15分</MenuItem>
                            <MenuItem value={2.5} disabled={(stateCtx.config.loginType==="ACCOUNT")}>2時間30分</MenuItem>
                            <MenuItem value={2.75} disabled={(stateCtx.config.loginType==="ACCOUNT")}>2時間45分</MenuItem>
                            <MenuItem value={3} disabled={(stateCtx.config.loginType==="ACCOUNT")}>3時間</MenuItem>
                            <MenuItem value={3.25} disabled={(stateCtx.config.loginType==="ACCOUNT")}>3時間15分</MenuItem>
                            <MenuItem value={3.5} disabled={(stateCtx.config.loginType==="ACCOUNT")}>3時間30分</MenuItem>
                            <MenuItem value={3.75} disabled={(stateCtx.config.loginType==="ACCOUNT")}>3時間45分</MenuItem>
                            <MenuItem value={4} disabled={(stateCtx.config.loginType==="ACCOUNT")}>4時間</MenuItem>
                            <MenuItem value={4.25} disabled={(stateCtx.config.loginType==="ACCOUNT")}>4時間15分</MenuItem>
                            <MenuItem value={4.5} disabled={(stateCtx.config.loginType==="ACCOUNT")}>4時間30分</MenuItem>
                            <MenuItem value={4.75} disabled={(stateCtx.config.loginType==="ACCOUNT")}>4時間45分</MenuItem>
                            <MenuItem value={5} disabled={(stateCtx.config.loginType==="ACCOUNT")}>5時間</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box marginTop={1} />
                <FormControl className={clsx(classes.textField,classes.grid)}>
                        <InputLabel>配信モード</InputLabel>
                        <Select
                            InputProps={{ style: { fontSize: 13 } }}
                            value={quality}
                            onChange={(evt) => {
                                setQuality(evt.target.value)
                            }}
                            onOpen={() => {
                                if(stateCtx.config.loginType==="ACCOUNT") mutationCtx.toastInfo(`高画質マルチ配信モードを予約する場合はSSS連携を行なって下さい`)
                            }}
                            disabled={(dayTimeOld<Date.now())?true:false}
                        >
                            <MenuItem value={0} >中画質マルチ配信モード(640 × 360)</MenuItem>
                            <MenuItem value={1} >高画質シングル配信モード(1280 × 720)</MenuItem>
                            <MenuItem value={2}  disabled={(stateCtx.config.loginType==="ACCOUNT")}>高画質マルチ配信モード (1920 × 1080)</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl className={clsx(classes.textField, classes.grid)}>
                    <Box  display={"flex"} justifyContent={"center"} alignItems={"center"} margin={2}>
                        {(quality===0)?<>
                            <Typography variant="caption" component="div" gutterBottom>
                                {'普通はこちらの配信モードでOKです'}
                            </Typography>
                        </>:<></>}
                        {(quality===1)?<>
                            <Box>
                            <Typography variant="caption" component="div" gutterBottom >
                                {'高画質です。プレゼンやゲーム配信などに'}
                            </Typography>
                            <Typography variant="caption" component="div" gutterBottom color={"red"} fontWeight={"bold"}>
                                {'*こちらのモードでゲスト招待は出来ません'}
                            </Typography>
                            </Box>
                        </>:<></>}
                        {(quality===2)?<>
                            <Box>
                            <Typography variant="caption" component="div" gutterBottom >
                                {'ゲスト招待機能で高画質、コラボ配信などに'}
                            </Typography>
                            <Typography variant="caption" component="div" gutterBottom color={"red"} fontWeight={"bold"}>
                                {'*こちらのモードは料金が倍になります'}
                            </Typography>
                            </Box>
                        </>:<></>}
                    </Box>
                    </FormControl>
                <FormControl className={clsx(classes.textField, classes.grid)}>
                    <TextField
                        disabled={(dayTimeOld<Date.now())?true:isMosaicCheck?true:false}
                        InputProps={{
                            style: { fontSize: 13 },
                            startAdornment: (
                                <InputAdornment position="start">
                                    {isMosaicCheck?
                                    <Tooltip title="モザイクを再度登録する">
                                        <IconButton onClick={()=>setIsMosaicCheck(false)}>
                                            <VpnKeyIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    :
                                    <Tooltip title="モザイクをチェックする">
                                        <IconButton onClick={()=>{
                                            mutationCtx.toastInfo(`モザイクをチェック中`)
                                            checkMosaicList(mosaicList)
                                        }}>
                                            <VpnKeyIcon/>
                                        </IconButton>
                                    </Tooltip>
                                    }
                                </InputAdornment>
                            ),
                                }}
                        label="参加可能なモザイクの指定"
                        placeholder='最大10件まで改行してモザイクを入力して下さい。その後左の鍵マークを押してモザイクをチェックして下さい'
                        id="outlined-multiline-static"
                        multiline
                        rows={3}
                        value={mosaicLine}
                        onChange={(evt) => {
                            setMosaicLine(evt.target.value)
                            setMosaicList(evt.target.value.split('\n'))
                        }}
                    />
                </FormControl>
                <Box marginTop={1} />
                <FormControl className={clsx(classes.textField, classes.grid)}>
                    <TextField
                        disabled={isMosaicCheck?false:true}
                        id="input-with-icon-textfield"
                        label="予約人数の指定（配信者含む）"
                        placeholder={`2以上${symbolMosaicsAmount+1}以下の数値を入れて下さい`}
                        InputProps={{
                        style: { fontSize: 13 },
                        startAdornment: (
                            <InputAdornment position="start">
                                <Tooltip title="参加者の詳細を確認する">
                                    <IconButton disabled={isMosaicCheck?false:true} onClick={accountListClick}>
                                        <AccountCircle/>
                                    </IconButton>
                                </Tooltip>
                            </InputAdornment>
                        ),
                        }}
                        value={amount}
                        onChange={(evt) => {
                            const PATTERN = /^[0-9\b]+$/
                            let value = true
                            if(PATTERN.test(evt.target.value) || evt.target.value === ''){
                                value = true
                            }else{
                                value = false
                            }
                            if (value && evt.target.value.length < 4) {
                                setAmount((evt.target.value)?parseInt(evt.target.value):"")
                            } else {
                                setAmount(amount)
                            }
                        }}
                        variant="standard"
                    />
                </FormControl>
                {(isMosaicCheck && amount>1)?
                <>
                    {(stateCtx.config.channelName==="")?
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Typography variant="caption" component="div">{`合計　`}</Typography>
                        {(amount <= 11 && period <= 1 && quality <= 1)
                        ?
                        <Typography variant="body1" component="div" color={"red"}><del>{`${Math.round(stateCtx.config.sytemFee*period*amount*(quality===2?2:1)/rateSymbol * 1000000) / 1000000}`}</del>{"　 => 　0"}</Typography>
                        :
                        <Typography variant="body1" component="div" color={"red"}>{`${Math.round(stateCtx.config.sytemFee*period*amount*(quality===2?2:1)/rateSymbol * 1000000) / 1000000}`}</Typography>
                        }
                        <Typography variant="caption" component="div">{`　xym`}</Typography>
                    </Box>
                    :
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Typography variant="caption" component="div">{`追加費用　`}</Typography>
                        {(amount <= 11 && period <= 1 && quality <= 1)
                        ?
                        <Typography variant="body1" component="div" color={"red"}><del>{`${diffChangeFee()}`}</del>{"　 => 　0"}</Typography>
                        :
                        <Typography variant="body1" component="div" color={"red"}>{`${diffChangeFee()}`}</Typography>
                        }
                        <Typography variant="caption" component="div">{`　xym`}</Typography>
                    </Box>
                    }
                </>
                :<></>}
                {(stateCtx.config.channelName!=="")?
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Button
                        onClick={()=>{
                            if(
                                title===titleOld && 
                                amount===amountOld && 
                                dayTime===dayTimeOld && 
                                period===periodOld && 
                                JSON.stringify(mosaicList) === JSON.stringify(mosaicListOld) && 
                                quality===qualityOld
                            ){
                                mutationCtx.toastError(
                                    `変更点がないため予約変更に進む事が出来ません`
                                )
                                return
                            }
                            confirmButtonClick()
                        }}
                        disabled={(isMosaicCheck)?false:true}
                        variant="contained"
                        color="primary"
                        className={classes.confirmButton}
                    >
                        変更内容確認へ
                    </Button>
                </Box>
                :
                <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <Button
                        onClick={confirmButtonClick}
                        variant="contained"
                        color="primary"
                        className={classes.confirmButton}
                    >
                        予約確認へ
                    </Button>
                </Box>
                }
            </Box>
        </Box>
        </>:<>
        <Box
            flex="1"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
        >
            <Bars height={50} width={50} color={"#44A2FC"}></Bars>
        </Box>
        </>}
        </>
    )
}
