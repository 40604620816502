import { BigNumber } from 'bignumber.js';
import { useState } from "react"
import axios from 'axios';

export const useFunction = () => {

    const [rateSymbol,setRateSymbol] = useState(0)

    const randRange = (min, max) => Math.floor(Math.random() * (max - min + 1) + min)

    const unixTimeToString = (unixTime)=>{
        if(typeof unixTime === 'number'){
            if(`${new Date(unixTime).toLocaleTimeString().slice(4,5)}`===":"){
                return `${new Date(unixTime).toLocaleDateString()} ${new Date(unixTime).toLocaleTimeString().slice(0,4)}`
            }else{
                return `${new Date(unixTime).toLocaleDateString()} ${new Date(unixTime).toLocaleTimeString().slice(0,5)}`

            }
        }else{
            return `${unixTime.toLocaleDateString()} ${unixTime.toLocaleTimeString().slice(0,5)}`
        }
    }

    const ts = (time)=>{
        if(time<10){
            return `0${time}`
        }else{
            return `${time}`
        }
    }

    const powerFloat = (float,power)=>{
        return BigNumber(float).times(10**power).toPrecision()
    }

    const timeFloat = (float,time)=>{
        return BigNumber(float).times(time).toPrecision()
    }

    const objectToText = (obj) => {
        return JSON.stringify(obj)
    }

    const textToObject = (text) => {
        return JSON.parse(text)
    }

    const getRateSymbol = () => {
        axios.get('https://min-api.cryptocompare.com/data/price?fsym=XYM&tsyms=USD')
        .then(response => {
            setRateSymbol(response.data.USD)
        })
        .catch(error => {
            console.error(error);
        })
        .finally(() => {
            // skip
        });
    }

    const copyTextToClipboard = async (text) => {
        await navigator.clipboard.writeText(text)
        .then(function() {
          console.log('Async: Copying to clipboard was successful!');
        }, function(err) {
          console.error('Async: Could not copy text: ', err);
        });
    }

    return {
        unixTimeToString,
        ts,
        powerFloat,
        timeFloat,
        objectToText,
        textToObject,
        getRateSymbol,
        rateSymbol,
        randRange,
        copyTextToClipboard
    }
}