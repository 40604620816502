import React , { useEffect, useState } from 'react'
import clsx from 'clsx'
import {makeStyles, withStyles} from '@material-ui/core/styles'
import {useGlobalState, useGlobalMutation} from '../../utils/container'
import useRouter from '../../utils/use-router'
import { MenuItem, Popover, Select, TextField, Typography ,FormControl,InputLabel,Box,Button} from '@material-ui/core'
import {useSymbol} from '../../hooks/useSymbol'
import {useFunction} from '../../hooks/useFunction'
import { Convert } from 'symbol-sdk'
import { Bars } from 'react-loader-spinner'
import { isMobile } from "react-device-detect"

const CustomRadio = withStyles({
    root: {
        color: '#999999',
        '&$checked': {
            color: '#44A2FC'
        },
        '&:hover': {
            backgroundColor: 'inherit'
        }
    }
})(({children, ...props}) => {
    return (
        <div className={`role-item ${props.checked ? 'active' : 'inactive'}`} onClick={(evt) => {
            props.onClick(props)
        }}>
            <div className={`icon-${props.value}`}></div>
            <div className={`radio-row ${props.value}`}>
                <div className="custom-radio">
                    <input
                        readOnly
                        type="radio"
                        value={props.value}
                        checked={props.checked}
                    />
                    <div className="checkmark"></div>
                </div>
                <Box
                    flex="1"
                    className={`role-name ${props.checked ? 'active' : 'inactive'}`}
                >
                    {props.value}
                </Box>
            </div>
        </div>
    )
})

const useStyles = makeStyles((theme) => ({
    input: {
        maxWidth: '280px',
        minWidth: '280px',
        alignSelf: 'center'
    },
    grid: {
        margin: '0 !important'
    },
    enterButtonUser: {
        lineHeight: '21px',
        color: 'rgba(255,255,255,1)',
        fontSize: '17px',
        textTransform: 'none',
        height: '44px',
        width: '260px',
        '&:hover': {
            backgroundColor: '#82C2FF'
        },
        margin: theme.spacing(1),
        backgroundColor: '#44a2fc',
        borderRadius: '30px'
    },
    enterButtonAdmin: {
        lineHeight: '21px',
        color: 'rgba(255,255,255,1)',
        fontSize: '17px',
        textTransform: 'none',
        height: '44px',
        width: '130px',
        '&:hover': {
            backgroundColor: '#82C2FF'
        },
        margin: theme.spacing(1),
        backgroundColor: '#44a2fc',
        borderRadius: '30px'
    },

    radio: {
        padding: '0',
        fontSize: '14px',
        // display: 'flex',
        alignItems: 'center',
        paddingRight: '5px',
    }
}))

export default function IndexCard() {
    const classes = useStyles()

    const routerCtx = useRouter()
    const stateCtx = useGlobalState()
    const mutationCtx = useGlobalMutation()
    const [isSymbolSync, setIsSymbolSync] = useState(false)
    const [isLoadingSerial, setIsLoadingSerial] = useState(false)
    const {
            getMosaicsFromUser,symbolUserMosaics,
            checkMosaic,
            getMetasInfo,symbolMetasInfo,
            getMosaicsAccount,symbolMosaicsAccount,
            getSymbolMosaicsBundlenames,symbolMosaicsBundlenames,
        } = useSymbol()

    const {
            unixTimeToString,
        } = useFunction()

    const [menuItemList,setMenuItemList] = useState([])
    const [channelName,setChannelName] = useState("")
    const [isDemo,setIsDemo] = useState(false)
    const [selectValue,setSelectValue] = useState(0)
    const [myRoomKeys,setMyRoomKeys] = useState([])
    const [isSymbolMetasInfo,setIsSymbolMetasInfo] = useState(false)
    const [isSymbolSerialsInfo,setIsSymbolSerialsInfo] = useState(false)

    useEffect(() => {
        if(symbolUserMosaics.length>0 && !isSymbolMetasInfo){
            console.log(symbolMetasInfo.length)
            getMetasInfo()
            setIsSymbolMetasInfo(true)
        }
        if(isSymbolMetasInfo && !isSymbolSerialsInfo){
            let newMenuItemList = []
            let newMyRoomKeys = []

            for(let i = 0; i < symbolMetasInfo.length; i++){
                if(symbolMetasInfo[i].data.owner === stateCtx.config.userAddress || checkMosaic(symbolMetasInfo[i].data.mosaic,symbolUserMosaics)){
                    newMyRoomKeys = [...newMyRoomKeys,symbolMetasInfo[i]]
                }
            }

            for(let i = 0; i < newMyRoomKeys.length; i++){
                newMenuItemList = [...newMenuItemList,
                    <MenuItem key={newMyRoomKeys[i].id} value={i}>
                        {`ID:${newMyRoomKeys[i].id}`}
                    </MenuItem>
                ]
            }
            newMenuItemList = [...newMenuItemList,
                <MenuItem key={"reserve"} value={9999}>
                    {"新規ルーム予約"}
                </MenuItem>
            ]

            newMenuItemList = [...newMenuItemList,
                <MenuItem key={"demo"} value={8888}>
                    {"デモルーム見学"}
                </MenuItem>
            ]

            setMenuItemList(newMenuItemList)
            setMyRoomKeys(newMyRoomKeys)

            console.log("symbolMetasInfo")
            console.log("newMyRoomKeys")

            console.log(symbolMetasInfo)
            console.log(newMyRoomKeys)

            mutationCtx.updateConfig({host: true}) //予約なし、モザイクなしの場合はホスト
            //channnelnameの初期値を設定しておく
            if(newMyRoomKeys.length>0){
                setChannelName(newMyRoomKeys[0].id)
                if(newMyRoomKeys[0].data.owner !== stateCtx.config.userAddress){
                    mutationCtx.updateConfig({host: false})
                }
                getMosaicsAccount(newMyRoomKeys[0].data.mosaic)
                getSymbolMosaicsBundlenames(newMyRoomKeys[0].data.mosaic)
            }
            setIsSymbolSerialsInfo(true)
        }
        if(isSymbolSerialsInfo){
            setIsSymbolSync(true)
        }
    },[symbolUserMosaics,symbolMetasInfo,isSymbolSerialsInfo])

    useEffect(() => {
        if (stateCtx.config.userAddress === "" ) {
            console.log('no userAddress')
            routerCtx.history.push("/");
        } else {
            console.log(`userAddress is ${stateCtx.config.userAddress}`)
            getMosaicsFromUser(stateCtx.config.userAddress)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // meetingページへ
    const enterButtonClick = async () => {
        if(!isDemo){
            //hostの認証
            if(stateCtx.config.host){
                if (stateCtx.config.userAddress===myRoomKeys[selectValue].data.owner ?? "") {
                    console.log("ログイン可");
                } else {
                    mutationCtx.toastError(
                        `${channelName}の予約者でないためホストとしてルームに入れません`
                    )
                    return
                }
            }
            //audienceの認証
            else{
                //mosaicから所有者を調べる案
                if(checkMosaic(myRoomKeys[selectValue].data.mosaic,symbolUserMosaics)){
                    console.log("ログイン可");
                } else {
                    mutationCtx.toastError(
                        `ルームに入るための以下モザイクのうちどれかを所有していないため入室できません\n${myRoomKeys[selectValue].data.mosaic ?? [].join()}`
                    )
                    return
                }
            }
        }
        mutationCtx.updateConfig({
            channelName: channelName,
            demo: isDemo,
            userName: (stateCtx.config.host)?"ホスト":"ゲスト",
            quality:(isDemo)?0:myRoomKeys[selectValue].data.quality
        })
        routerCtx.history.push({
            pathname: (!isMobile) ? `/meeting/${channelName}` : `/meetingmobile/${channelName}`
        })
    }

    //reserveページへ
    const reserveButtonClick = () => {
        mutationCtx.updateConfig({
            channelName: channelName,
        })
        routerCtx.history.push({
            pathname: `/reserve`
        })
    }

    const radioChange = (evt) => {
        const {value, checked} = evt
        console.log(checked)
        mutationCtx.updateConfig({
            host: value === 'host'
        })
    }

    const selectChange = async (evt) => {
        if(evt.target.value===9999){
            setIsDemo(false)
            mutationCtx.updateConfig({host: true})
            setSelectValue(evt.target.value)
            setChannelName("")
        }else if(evt.target.value===8888){
            setIsDemo(true)
            mutationCtx.updateConfig({host: true})
            setSelectValue(evt.target.value)
            setChannelName(stateCtx.config.demoScopedMetadataKey)
        }else{
            setIsDemo(false)
            setSelectValue(evt.target.value)
            setChannelName(myRoomKeys[evt.target.value].id)
            if(myRoomKeys[evt.target.value].data.owner === stateCtx.config.userAddress){
                mutationCtx.updateConfig({host: true})
            }else{
                mutationCtx.updateConfig({host: false})
            }
            setIsLoadingSerial(true)
            await getMosaicsAccount(myRoomKeys[evt.target.value].data.mosaic)
            await getSymbolMosaicsBundlenames(myRoomKeys[evt.target.value].data.mosaic)
            setIsLoadingSerial(false)
        }
    }

    //Popover関連
    const [mosaicListAnchorEl, setMosaicListAnchorEl] = useState(null);
    const mosaicListPopoverOpen = Boolean(mosaicListAnchorEl);
    const mosaicListPopoverid = mosaicListPopoverOpen ? 'simple-popper' : undefined;
    const mosaicListClick = (event) => {
        setMosaicListAnchorEl(mosaicListAnchorEl ? null : event.currentTarget);
    }
    const mosaicListClose = () => {
        setMosaicListAnchorEl(null);
    }

    return (
        <>
        {(isSymbolSync)?
        <>
        <Popover
            id={mosaicListPopoverid}
            open={mosaicListPopoverOpen}
            onClose={mosaicListClose}
            anchorEl={mosaicListAnchorEl}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
        >
            <Box sx={{ display: 'flex',flex: '1',margin:20, alignItems:"center" , flexDirection:'column',height: 150 , width:450 , border: 1, p: 3, bgcolor: 'background.paper'}}>
            <Box
                component="form"
                sx={{
                    '& .MuiTextField-root': { m: 1, width: "490px"},
                }}
                noValidate
                autoComplete="off"
            >
                <TextField
                    label="参加に必要なモザイク"
                    multiline
                    rows={5}
                    defaultValue={
                        (channelName!=="" && channelName!==stateCtx.config.demoScopedMetadataKey)?myRoomKeys[selectValue].data.mosaic.join('\n'):""
                    }
                />
            </Box>
                <Button onClick={()=>{
                    mosaicListClose()
                }}>
                    閉じる
                </Button>
            </Box>
        </Popover>
        <Box
            marginTop="0px"
            flex="1"
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            flexDirection="column"
        >
            <Box>
                <p style={{fontSize:"10px"}}>{stateCtx.config.userAddress}</p>
            </Box>

            <Box className="role-container" marginBottom={10}>
                {(stateCtx.config.host)?
                <CustomRadio
                    className={classes.radio}
                    value="host"
                    checked={stateCtx.config.host}
                    onClick={radioChange}
                ></CustomRadio>
                :
                <CustomRadio
                    className={classes.radio}
                    value="audience"
                    checked={!stateCtx.config.host}
                    onClick={radioChange}
                ></CustomRadio>
                }
            </Box>
            <Box
                flex="1"
                display="flex"
                alignItems="center"
                justifyContent="start"
                flexDirection="column"
            >
                <FormControl className={clsx(classes.input, classes.grid)}>
                    <InputLabel>ルームを選択して下さい</InputLabel>
                    <Select
                        value={selectValue}
                        onChange={selectChange}
                    >
                        {menuItemList.map((item)=>item)}
                    </Select>
                </FormControl>
                {(channelName!=="" && channelName!==stateCtx.config.demoScopedMetadataKey)?
                <>
                <FormControl className={clsx(classes.input, classes.grid)}>
                <Box mt={2} mb={1}>
                        <Box
                            display="flex"
                            flexDirection="row"
                        >
                        <Typography variant="caption" component="div" gutterBottom style={{fontSize:"10px"}}>{`📢　`}</Typography>
                        <Typography variant="caption" component="div" gutterBottom style={{fontSize:"10px"}}>{`${unixTimeToString(myRoomKeys[selectValue].data.st)} - ${unixTimeToString(myRoomKeys[selectValue].data.ed)}`}</Typography>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="row"
                        >
                        <Typography variant="caption" component="div" gutterBottom style={{fontSize:"10px"}}>{`🎤　`}</Typography>
                        <Typography variant="caption" component="div" gutterBottom style={{fontSize:"10px"}}>{`${Convert.decodeHex(myRoomKeys[selectValue].data.title)?? ""}`}</Typography>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="row"
                        >
                        <Typography variant="caption" component="div" gutterBottom style={{fontSize:"10px"}}>{`🖥　`}</Typography>
                        <Typography variant="caption" component="div" gutterBottom style={{fontSize:"10px"}}>{(myRoomKeys[selectValue].data.quality===0)?`中画質マルチ配信モード(640 × 360)`:(myRoomKeys[selectValue].data.quality===1)?`高画質シングル配信モード(1280 × 720)`:`高画質マルチ配信モード (1920 × 1080)`}</Typography>
                        </Box>
                        <Box
                            display="flex"
                            flexDirection="row"
                        >
                        <Typography variant="caption" component="div" gutterBottom style={{fontSize:"10px"}}>{`👥　`}</Typography>
                        <Typography variant="caption" component="div" style={(symbolMosaicsAccount.length > myRoomKeys[selectValue].data.amount)?{color: 'red' , fontSize:"10px"}:{fontSize:"10px"}} gutterBottom>{`予約人数　${myRoomKeys[selectValue].data.amount}人（モザイク保有者${symbolMosaicsAccount.length}人）`}</Typography>
                        </Box>
                        
                        <Typography variant="caption" component="div" gutterBottom style={{fontSize:"10px"}}>　　</Typography>
                        <Typography variant="caption" component="div" gutterBottom style={{fontSize:"10px"}}>{`🔑　以下${(myRoomKeys[selectValue].data.mosaic.length>1)?"いずれか":""}のMOSAICを所有していること`}</Typography>
                        <Typography variant="caption" component="div" gutterBottom style={{fontSize:"10px"}}>
                            {
                            (!isLoadingSerial)?
                                Object.keys(symbolMosaicsBundlenames).map((key)=>(
                                    <>
                                    {(symbolMosaicsBundlenames[key]["version"].indexOf('nft')>0)?`🎫`:(symbolMosaicsBundlenames[key]["version"].indexOf('ncft')>0)?`🎟`:`🆔`}
                                    {(symbolMosaicsBundlenames[key]["title"]!=="")?`${symbolMosaicsBundlenames[key]["title"]}`:key}
                                    {(symbolMosaicsBundlenames[key]["name"]!=="")?`　by ${symbolMosaicsBundlenames[key]["name"]}　`:"　"}
                                    </>
                                    ))
                                    :
                                    <Bars height={30} width={30} color={"#44A2FC"}></Bars>
                            }
                        </Typography>
                    </Box>
                </FormControl>
                <FormControl className={classes.grid}>
                    {(stateCtx.config.host)?
                    <Box display={"flex"} justifyContent={"center"}>
                    <Button
                        onClick={enterButtonClick}
                        variant="contained"
                        color="primary"
                        className={classes.enterButtonAdmin}
                    >
                        入室
                    </Button>
                    <Button
                        onClick={reserveButtonClick}
                        variant="contained"
                        color="primary"
                        className={classes.enterButtonAdmin}
                    >
                        予約変更
                    </Button>
                    </Box>
                    :
                    <Button
                        onClick={enterButtonClick}
                        variant="contained"
                        color="primary"
                        className={classes.enterButtonUser}
                    >
                        入室
                    </Button>
                    }
                </FormControl>
                </>
                :(selectValue===9999)?
                <FormControl className={classes.grid}>
                    <Box display={"flex"} justifyContent={"center"} marginTop={3}>
                    <Button
                        onClick={reserveButtonClick}
                        variant="contained"
                        color="primary"
                        className={classes.enterButtonAdmin}
                    >
                        新規予約
                    </Button>
                    </Box>

                </FormControl>
                :(channelName!=="")?
                <FormControl className={classes.grid}>
                    <Box display={"flex"} justifyContent={"center"} marginTop={3}>
                    <Button
                        onClick={enterButtonClick}
                        variant="contained"
                        color="primary"
                        className={classes.enterButtonAdmin}
                    >
                        入室
                    </Button>
                    </Box>

                </FormControl>
                :<></>
                }
                {!(channelName!==stateCtx.config.demoScopedMetadataKey)?<>
                <Box margin={3}>
                    <Typography variant="caption" component="div" gutterBottom>{`デモルームでは、実際のルームをシミュレーションできます。情報は誰にも公開されませんので安心してご利用下さい。`}</Typography>
                    <Typography variant="caption" component="div" gutterBottom>{`（但し投げXYMはブロックチェーンに記録されるためご利用はご注意下さい）`}</Typography>
                </Box>
                </>:<></>}
            </Box>
        </Box>
        </>
        :<>
        <Box
            flex="1"
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
        >
            <Bars height={50} width={50} color={"#44A2FC"}></Bars>
        </Box>
        </>
        }
        </>
    )
}
