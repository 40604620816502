import {TransactionGroup , RepositoryFactoryHttp , MosaicId ,TransactionType} from 'symbol-sdk-comsa'
import {useGlobalState} from '../utils/container'

export const useNft = () => {
  const stateCtx = useGlobalState()

  const getComsaNft = async (hash,version) => {
    const nodeUrl = stateCtx.config.nodeUrl
    const repositoryFactory = new RepositoryFactoryHttp(nodeUrl)
    const transactionRepository = repositoryFactory.createTransactionRepository();
    const transaction = await transactionRepository.getTransaction(hash,TransactionGroup.Confirmed).toPromise()
    let base64 = ""
    if(version==='comsa-nft-1.0'){
      const flatDataList = transaction.innerTransactions.slice(1).map(itrans => {
          return itrans.message.payload
      })
      const flattenMessage = [].concat(...flatDataList).filter(v => v).map((r) => r.split('#', 2)[1]);
      base64 = flattenMessage.join('')
    }else{
      const flatDataList = transaction.innerTransactions.slice(1).map(itrans => {
        return itrans.message.toBuffer()
      })
      const totalLength = flatDataList.reduce((acc, value) => acc + value.length, 0);
      const binary = new Uint8Array(totalLength);
      let length = 0;
      for (const array of flatDataList) {
        binary.set(array, length);
        length += array.length;
      }
      base64 = btoa(String.fromCharCode(...binary))
    }
    // 最初の１文字でファイルタイプを判断できる　/ jpeg i png R gif
    if(base64.slice(0,1) === "/"){
      return `data:image/jpeg;base64,${base64}`
    }else if(base64.slice(0,1) === "i"){
      return `data:image/png;base64,${base64}`
    }else if(base64.slice(0,1) === "R"){
      return `data:image/gif;base64,${base64}`
    }else{
      console.log("no type jpeg png gif")
    }
  }


  const getDriveNft = async (mosaicid) => {
    const repositoryFactory = new RepositoryFactoryHttp(stateCtx.config.nodeUrl)
    const mosaicRepo = repositoryFactory.createMosaicRepository()
    const txRepo = repositoryFactory.createTransactionRepository();

    const mosaicId = new MosaicId(mosaicid);
    let ownerAddress = ""
    await mosaicRepo.getMosaic(mosaicId).toPromise().then((mosaicInfo)=>{
        ownerAddress = mosaicInfo.ownerAddress
    })
    let aggTxes = [];
    await txRepo.search({
        type:[
            TransactionType.AGGREGATE_COMPLETE,
            TransactionType.AGGREGATE_BONDED
        ],
        address:ownerAddress,group:TransactionGroup.Confirmed,pageSize:100
    }).toPromise().then(async(x)=>{
        for (let idx = 0; idx < x.data.length; idx++) {
            const aggTx = await txRepo.getTransaction(x.data[idx].transactionInfo.hash,TransactionGroup.Confirmed).toPromise();
            if(aggTx.innerTransactions.find(elem => elem.type === 16724)){
                aggTxes.push(aggTx);
            }
        }
    })
    const sotedAggTxes = aggTxes.sort(function(a, b) {
        if (Number(a.innerTransactions[0].message.payload) > Number(b.innerTransactions[0].message.payload)) {
          return 1;
        } else {
          return -1;
        }
    })
    let nftData = "";
    let header = 15;
    for (let aggTx of sotedAggTxes) {
        for(let idx = 0 + header; idx < aggTx.innerTransactions.length;idx++){
            nftData += aggTx.innerTransactions[idx].message.payload;
        }
        header = 1;
    }
    return nftData
  }

  const checkDriveNft = async (mosaicid) => {
    const repositoryFactory = new RepositoryFactoryHttp(stateCtx.config.nodeUrl)
    const mosaicRepo = repositoryFactory.createMosaicRepository()
    const txRepo = repositoryFactory.createTransactionRepository();

    const mosaicId = new MosaicId(mosaicid);
    let ownerAddress = ""
    await mosaicRepo.getMosaic(mosaicId).toPromise().then((mosaicInfo)=>{
        ownerAddress = mosaicInfo.ownerAddress
    })
    let message1 = false
    let message2 = false
    await txRepo.search({
        type:[
            TransactionType.TRANSFER,
        ],
        address:ownerAddress,group:TransactionGroup.Confirmed,pageSize:10
    }).toPromise().then(async(x)=>{
        for (let idx = 0; idx < x.data.length; idx++) {
          if(x.data[idx].type === 16724){
            if(x.data[idx].message.payload === "Please note that this mosaic is an NFT.") message1 = true
            if(x.data[idx].message.payload === "Refund-2") message2 = true
          }
        }
    })
    return (message1 && message2)?true:false
  }

  return {
    getComsaNft,
    getDriveNft,
    checkDriveNft
  }
}