import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Box from '@material-ui/core/Box'
import {Link} from 'react-router-dom'
import { Typography } from '@mui/material'

const useStyles = makeStyles((theme) => ({
    menu: {
        margin: '0.25rem 0',
        position: 'relative',
        height: '39px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    line: {
        marginTop: '0.2rem',
        marginBottom: '0.5rem',
        borderBottom: '1px solid #EAEAEA'
    },
}))

export default function PrivacyCard() {
    const classes = useStyles()
    return (
        <Box flex="1" display="flex" flexDirection="column">
            <Link to="/index" className="back-btn"/>
            <Box
                style={{overflowY: "auto"}}
                display="flex"
                flex="1"
                flexDirection="column"
                padding="0 1rem"
                justifyContent="flex-start"
            >
                <FormControl className={classes.menu}>
                    <Typography variant="body2" component="div" gutterBottom>プライバシーポリシー</Typography>
                </FormControl>
                <div className={classes.line}></div>
                <Box 
                    style={{overflowY: "auto"}}
                    flex="1"
                    display="flex"
                    flexDirection="column"
                    justifyContent="flex-start"
                >
                    <Typography variant="body2" component="div" gutterBottom>まごころAI製作所（以下「当社」という）は、当社の提供する「TokenLive」のアプリ利用者（以下「ユーザー」という）に関する個人情報を含んだ情報（以下「ユーザー情報」という）の取扱いについて、以下のとおりプライバシーポリシー（以下「本ポリシー」という）を定めます。</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>１．情報を取得するアプリ提供者</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>まごころAI製作所</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>２．取得するユーザー情報と目的</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>本アプリではユーザー情報を取得及び管理をしておりません。ユーザー情報は以下の様に管理されます</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>1.アカウント情報はSymbolブロックチェーンにて作成されたアカウントを利用します。登録や削除はSymbolブロックチェーンの各種サービスより行なって下さい</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>2.ルームの予約情報はTokenLive Web版にて行います。予約情報もまたSymbolブロックチェーン上に記録されます。</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>3.ルーム内のチャット及びビデオデータについては、End to Endでリアルタイムにやり取りしており、運営側で保存はしていません。</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>３．取得方法</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>前述の通り本アプリ内でユーザー情報を取得及び保存は行いません。</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>４．通知・公表または同意取得の方法・利用者関与の方法</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>1.通知・公表　当社は、本ポリシーおよびまごころAI製作所プライバシーポリシーに関する通知・公表は本アプリケーションまたは当社のホームページに掲載する方法で行います。</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>2.同意取得の方法　ユーザー情報を取得しないため本アプリでは同意取得をおこなっておりません</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>3.利用者関与の方法　本アプリではユーザー情報を取得いたしません</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>５．外部送信・第三者提供・情報収集モジュール</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>1.外部送信　本アプリではユーザー情報を保持していないため、認証時にSymbolブロックチェーンの参照を行います。</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>2.第三者提供　本アプリで扱う情報は全てSymbolブロックチェーン上に記録されています。そのため第三者においても常にその情報を参照する事が可能です。</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>第6条（本サービスの提供の停止等）</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>1.本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>2.地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>3.コンピュータまたは通信回線等が事故により停止した場合</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>4.その他，当社が本サービスの提供が困難と判断した場合</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>６．お問い合わせ</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>当社のプライバシーポリシーに関する、ご意見、ご質問、苦情の申出その他ユーザー情報の取扱いに関するお問い合わせは、以下の窓口にご連絡ください。</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom> hossiiii0117@gmail.com</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>７．改定</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>当社は、当社の裁量に基づいて、本ポリシーを変更します。但し、取得するユーザー情報、利用目的、第三者提供に変更が発生した場合、本アプリケーションまたは当社のホームページで通知するとともに、本ポリシー変更後、本アプリケーションの初回起動時に改めてユーザーから同意を取得します。</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>８．制定日・改定日</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>制定：令和４年７月２４日</Typography>
                </Box>


            </Box>
        </Box>
    )
}

