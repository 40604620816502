import { useState } from "react"
import { db } from '../utils/firebase';
import { doc, updateDoc} from 'firebase/firestore';
import { Address, TransactionGroup , RepositoryFactoryHttp, MosaicId, MetadataType, Convert ,ChainHttp, Order} from 'symbol-sdk'
import { useFunction } from './useFunction';
import { useNft } from './useNft';
import {useGlobalState} from '../utils/container'
import axios from 'axios'

//後でfirebaseからsymbolのチェーンに書き換える。
export const useSymbol = () => {

  const stateCtx = useGlobalState()

  const {
    powerFloat,
    textToObject
} = useFunction()

const {
  checkDriveNft
} = useNft()

const [symbolUserMosaics,setSymbolUserMosaics] = useState([])
  const [symbolMosaicInfo,setSymbolMosaicInfo] = useState([])
  const [symbolMosaicsInfo,setSymbolMosaicsInfo] = useState([])
  const [symbolMosaicsAmount,setSymbolMosaicsAmount] = useState(0)
  const [symbolMetaInfo,setSymbolMetaInfo] = useState([])
  const [symbolMetasInfo,setSymbolMetasInfo] = useState([])
  const [symbolMosaicAccount,setSymbolMosaicAccount] = useState([])
  const [symbolMosaicsAccount,setSymbolMosaicsAccount] = useState([])
  const [symbolMosaicSerials,setSymbolMosaicSerials] = useState([])
  const [symbolMosaicsSerials,setSymbolMosaicsSerials] = useState({})
  const [symbolMosaicsBundlenames,setSymbolMosaicsBundlenames] = useState({})
  const [symbolTransactionFee,setSymbolTransactionFee] = useState({})
  const [symbolUserBalance,setSymbolUserBalance] = useState(0)
  const [checkedNode,setCheckedNode] = useState("")
  const [nodeLoading,setNodeLoading] = useState(true)

  const checkNode = async () => {
    let node = ""
    for(let i = 0; i < stateCtx.config.nodeUrlList.length; i++){
        await axios.get(`${stateCtx.config.nodeUrlList[i]}/node/health`,{timeout: 5000}
        ).then(res => {
            if(res.data.status.apiNode === "up" && res.data.status.db === "up"){
                console.log("is ok")
                console.log(stateCtx.config.nodeUrlList[i]);
                node = stateCtx.config.nodeUrlList[i]
            }
        }).catch(res =>console.log(res));
    }
    setCheckedNode(node)
    setNodeLoading(false)
    return node
  }

  const checkMosaic = (roomMosaicList,userMosaicList) => {
      let flag = false
      roomMosaicList.map((roomMosaic)=>{
          if(userMosaicList.includes(roomMosaic)) flag = true
      })
      return flag
  }

  const toHexList = (mosaics) => {
      let hexMosaics = []
      mosaics.map((mosaic)=>{
          hexMosaics = [...hexMosaics,mosaic.id.id.toHex()]
      })
      return hexMosaics
  }

  const getMosaicsFromUser = (address) => {
    const accountAddress = Address.createFromRawAddress(address)
    const nodeUrl = stateCtx.config.nodeUrl
    const repositoryFactory = new RepositoryFactoryHttp(nodeUrl)
    const accountHttp = repositoryFactory.createAccountRepository()
    accountHttp.getAccountInfo(accountAddress).subscribe(
      (accountInfo) => {
        setSymbolUserMosaics(toHexList(accountInfo.mosaics))
      },
      (err) => {
        setSymbolUserMosaics([stateCtx.config.xymAddress])
        console.error(err)
      }
    )
  }

  const getUserBalance = (address) => {
      const accountAddress = Address.createFromRawAddress(address)
      const nodeUrl = stateCtx.config.nodeUrl
      const repositoryFactory = new RepositoryFactoryHttp(nodeUrl)
      const accountHttp = repositoryFactory.createAccountRepository()
      accountHttp.getAccountInfo(accountAddress).subscribe(
        (accountInfo) => {
            setSymbolUserBalance(powerFloat(parseFloat(accountInfo.mosaics[0].amount.toString()),-6))
        },
        (err) => {
          console.error(err)
        }
      )
  }

  const getMosaicInfo = async (mosaicId) => {
    const symMosaicId = new MosaicId(mosaicId)
    const nodeUrl = stateCtx.config.nodeUrl
    const repositoryFactory = new RepositoryFactoryHttp(nodeUrl)
    const mosaicHttp = repositoryFactory.createMosaicRepository()
    const chainHttp = new ChainHttp(nodeUrl)
    let height = 0
    await chainHttp.getChainInfo().toPromise().then(
      (chainInfo) => {
        height = chainInfo.height
      }
    )

    await mosaicHttp.getMosaic(symMosaicId).toPromise().then(
      async (mosaicInfo) => {
        console.log(mosaicInfo)
        //期限切れをチェック
        if (mosaicInfo.duration.toString() === '0') {
          console.log('mosaic')
        } else if (height < mosaicInfo.startHeight.add(mosaicInfo.duration)) {
          console.log('mosaic')
        } else {
          throw Error('expired mosaic');
        }
        //divisibilityをチェック
        if(mosaicInfo.divisibility>0){
          throw Error('non zero divisibility');
        }
        //NFTかどうかをチェック
        if (!(parseInt(mosaicInfo.supply.toString())===1 && !mosaicInfo.flags.supplyMutable)) {
          if(mosaicInfo.flags.transferable){
            if(await checkDriveNft(mosaicId)){
              console.log("NFT-Driveのモザイク")
            }else{
              throw Error('FT mosaic');
            }
          }
        }
        //NFTDのブラックリストかどうかをチェック
        await axios.get(`https://us-central1-tokenlive-b98bd.cloudfunctions.net/getBlackList`).then(res => {
          for(let i = 0; i < res.data.data.length; i++){
            if(res.data.data[i][1] === mosaicId){
              throw Error('BlackList mosaic');
            }
          }
        })

        setSymbolMosaicInfo(mosaicInfo)
      }
    )
  }

  const getMosaicsInfo = async (mosaicIds) => {
      const symMosaicIds = mosaicIds.map((mosaicId)=> new MosaicId(mosaicId))
      const nodeUrl = stateCtx.config.nodeUrl
      const repositoryFactory = new RepositoryFactoryHttp(nodeUrl)
      const mosaicHttp = repositoryFactory.createMosaicRepository()
      await mosaicHttp.getMosaics(symMosaicIds).toPromise().then(
        (mosaicInfo) => {
          setSymbolMosaicsInfo(mosaicInfo)
          setSymbolMosaicsAmount(toAmount(mosaicInfo))
      },
        (err) => {
          console.error(err)
        }
      )
  }

  const toAmount = (mosaicInfos) => {
    let amount = 0
    mosaicInfos.map((mosaicInfo)=>{
        amount = amount + Math.trunc(parseInt(mosaicInfo.supply.toHex(), 16)/(10 ** mosaicInfo.divisibility))
    })
    return amount
  }

  const getMetasInfo = async () => {
    const symMosaicId = new MosaicId(stateCtx.config.adminMosaic)
    const nodeUrl = stateCtx.config.nodeUrl
    const repositoryFactory = new RepositoryFactoryHttp(nodeUrl)
    const metadataHttp = repositoryFactory.createMetadataRepository();
    let newSymbolMetasInfo = []
    let searchCriteria = {
      targetId: symMosaicId,
      metadataType: MetadataType.Mosaic,
      order: Order.Desc,
      pageNumber: 1,
      pageSize: 1000
    }
    await metadataHttp.search(searchCriteria).toPromise().then(
      (metadataEntries) => {
        if (metadataEntries.pageSize > 0) {
          console.log(metadataEntries.data)
          try{
            for(let j = 0; j < metadataEntries.data.length; j++){
              if(JSON.parse(Convert.decodeHex(metadataEntries.data[j].metadataEntry.value))["ed"]>Date.now()){//scopedMetadataKeyだと変更時に古い情報を参照してしまう.edを参照するように変更
                newSymbolMetasInfo = [...newSymbolMetasInfo,{id:metadataEntries.data[j].metadataEntry.scopedMetadataKey.toHex(),data:textToObject(Convert.decodeHex(metadataEntries.data[j].metadataEntry.value))}]
              }
            }
          }catch{
            console.log('\n The mosaic does not have metadata entries assigned.');
          }
        } else {
          console.log('\n The mosaic does not have metadata entries assigned.');
        }
      },
    )
    setSymbolMetasInfo(newSymbolMetasInfo)
  }

  const getMetaInfo = async (metaKey) => {
    const symMosaicId = new MosaicId(stateCtx.config.adminMosaic)
    const nodeUrl = stateCtx.config.nodeUrl
    const repositoryFactory = new RepositoryFactoryHttp(nodeUrl)
    const metadataHttp = repositoryFactory.createMetadataRepository();
    let newSymbolMetaInfo = {}
    let searchCriteria = {
      targetId: symMosaicId,
      metadataType: MetadataType.Mosaic,
      scopedMetadataKey: metaKey
    }
    await metadataHttp.search(searchCriteria).toPromise().then(
      (metadataEntries) => {
        if (metadataEntries.pageSize > 0) {
          console.log(metadataEntries.data)
          try{
            for(let j = 0; j < metadataEntries.data.length; j++){
              if(parseInt(metadataEntries.data[0].metadataEntry.scopedMetadataKey.toString())>Date.now()){
                newSymbolMetaInfo = {id:metadataEntries.data[0].metadataEntry.scopedMetadataKey.toHex(),data:textToObject(Convert.decodeHex(metadataEntries.data[0].metadataEntry.value))}
              }
            }
          }catch{
            console.log('\n The mosaic does not have metadata entries assigned.');
          }
        } else {
          console.log('\n The mosaic does not have metadata entries assigned.');
        }
      },
    )
    setSymbolMetaInfo(newSymbolMetaInfo)
  }
  
  const getMetaInfoDemo = async (metaKey) => {
    const symMosaicId = new MosaicId(stateCtx.config.adminMosaic)
    const nodeUrl = stateCtx.config.nodeUrl
    const repositoryFactory = new RepositoryFactoryHttp(nodeUrl)
    const metadataHttp = repositoryFactory.createMetadataRepository();
    let newSymbolMetaInfo = {}
    let searchCriteria = {
      targetId: symMosaicId,
      metadataType: MetadataType.Mosaic,
      scopedMetadataKey: metaKey
    }
    await metadataHttp.search(searchCriteria).toPromise().then(
      (metadataEntries) => {
        if (metadataEntries.pageSize > 0) {
          console.log(metadataEntries.data)
          try{
            for(let j = 0; j < metadataEntries.data.length; j++){
              newSymbolMetaInfo = {id:metadataEntries.data[0].metadataEntry.scopedMetadataKey.toHex(),data:textToObject(Convert.decodeHex(metadataEntries.data[0].metadataEntry.value))}
            }
          }catch{
            console.log('\n The mosaic does not have metadata entries assigned.');
          }
        } else {
          console.log('\n The mosaic does not have metadata entries assigned.');
        }
      },
    )
    setSymbolMetaInfo(newSymbolMetaInfo)
  }

  const getMosaicAccount = async (mosaicId) => {
    const symMosaicId = new MosaicId(mosaicId)
    const nodeUrl = stateCtx.config.nodeUrl
    const repositoryFactory = new RepositoryFactoryHttp(nodeUrl)
    const accountRepo = repositoryFactory.createAccountRepository();
    let newSymbolMetasAccount = []
    for(let i = 1; i < 11; i++){
      const accountSearchCriteria = {
        mosaicId: symMosaicId,
        pageSize: 100,
        pageNumber:i
      }
      await accountRepo.search(accountSearchCriteria).toPromise().then(
        (page) => {
          page.data.forEach(acc=>{
            newSymbolMetasAccount = [...newSymbolMetasAccount,acc.address.plain()]
          })
        }
      )
    }
    setSymbolMosaicAccount(newSymbolMetasAccount)
  }

  const getMosaicsAccount = async (mosaicIds) => {
    const nodeUrl = stateCtx.config.nodeUrl
    const repositoryFactory = new RepositoryFactoryHttp(nodeUrl)
    const accountRepo = repositoryFactory.createAccountRepository();
    let newSymbolMetasAccount = []
    for(let i = 0; i < mosaicIds.length; i++){
      for(let j = 1; j < 11; j++){
        const accountSearchCriteria = {
          mosaicId: new MosaicId(mosaicIds[i]),
          pageSize: 100,
          pageNumber:j
        }
        await accountRepo.search(accountSearchCriteria).toPromise().then(
          (page) => {
            page.data.forEach(acc=>{
              newSymbolMetasAccount = [...newSymbolMetasAccount,acc.address.plain()]
            })
          }
        )
      }
    }
    //重複の削除
    setSymbolMosaicsAccount(Array.from(new Set(newSymbolMetasAccount)))
  }

  const getMosaicSerials = async (address,mosaicId) => {
    const nodeUrl = stateCtx.config.nodeUrl
    const repositoryFactory = new RepositoryFactoryHttp(nodeUrl)
    const account = Address.createFromRawAddress(address);
    const accountHttp = repositoryFactory.createAccountRepository();
    const symMosaicId = new MosaicId(mosaicId)
    const transactionHttp = repositoryFactory.createTransactionRepository();
    let rxList = []
    let txList = []
    await accountHttp.getAccountInfo(account).toPromise().then(
      async (accountInfo) => {
          console.log(accountInfo.publicKey)
          let searchCriteria = {
              group: TransactionGroup.Confirmed,
              signerPublicKey:accountInfo.publicKey,
              embedded:true,
              transferMosaicId:symMosaicId,
              order: Order.Desc,
              pageNumber: 1,
              pageSize: 1000
          }
          await transactionHttp.search(searchCriteria).toPromise().then(page=>{
              if (page.pageSize > 0) {
                  console.log(page.data)
                  rxList = page.data
              }
          })

          let searchCriteria2 = {
          group: TransactionGroup.Confirmed,
          recipientAddress:Address.createFromRawAddress(address),
          embedded:true,
          transferMosaicId:symMosaicId,
          order: Order.Desc,
          pageNumber: 1,
          pageSize: 1000
          }
          await transactionHttp.search(searchCriteria2).toPromise().then(page=>{
              if (page.pageSize > 0) {
                  console.log(page.data)
                  txList = page.data
              }
          })
      },
      (err) => console.error(err),
    );

    let serialList = []
    rxList.forEach(transaction=>{
        serialList = [...serialList,transaction.message.payload]
    })
    txList.forEach(transaction=>{
        serialList = [...serialList,transaction.message.payload]
    })
    //ここで奇数のだけをリスト化
    let dict = {};
    for(let key of serialList){
        dict[key] = serialList.filter(function(x){return x === key}).length;
    }
    console.log(dict)
    let uniqeSerialList = []
    Object.keys(dict).forEach(key => {
        if(dict[key]%2 === 1){
            uniqeSerialList = [...uniqeSerialList,key]
        }
    })
    console.log(uniqeSerialList)
    setSymbolMosaicSerials(uniqeSerialList)
  }

  const getMosaicsSerials = async (address,mosaicIds) => {
    const nodeUrl = stateCtx.config.nodeUrl
    const repositoryFactory = new RepositoryFactoryHttp(nodeUrl)
    const account = Address.createFromRawAddress(address);
    const accountHttp = repositoryFactory.createAccountRepository();
    const transactionHttp = repositoryFactory.createTransactionRepository();
    const metadataHttp = repositoryFactory.createMetadataRepository();
    let mosaicSerialMap = {}
    for(let i = 0; i < mosaicIds.length; i++){
      let rxList = []
      let txList = []
      let name = ""
      let title = ""
      let version = ""
      let thumbnail = ""
      let symMosaicId = new MosaicId(mosaicIds[i])
      let searchCriteriaMeta = {
        targetId: symMosaicId,
        metadataType: MetadataType.Mosaic,
      }
      await metadataHttp.search(searchCriteriaMeta).toPromise().then(
        (metadataEntries) => {
          if (metadataEntries.pageSize > 0) {
            // console.log(metadataEntries.data)
            try{
              for(let j = 0; j < metadataEntries.data.length; j++){
                if(metadataEntries.data[j].metadataEntry.scopedMetadataKey.toHex() === "8E0823CEF8A40075"){ //ver1.1
                  console.log(Convert.decodeHex(textToObject(metadataEntries.data[j].metadataEntry.value)["name"]))
                  name = Convert.decodeHex(textToObject(metadataEntries.data[j].metadataEntry.value)["name"])
                  console.log(Convert.decodeHex(textToObject(metadataEntries.data[j].metadataEntry.value)["title"]))
                  title = Convert.decodeHex(textToObject(metadataEntries.data[j].metadataEntry.value)["title"])
                }
                if(metadataEntries.data[j].metadataEntry.scopedMetadataKey.toHex() === "DA030AA7795EBE75"){ //ver1.0
                  console.log(Convert.decodeHex(textToObject(metadataEntries.data[j].metadataEntry.value)["name"]))
                  name = Convert.decodeHex(textToObject(metadataEntries.data[j].metadataEntry.value)["name"])
                  console.log(Convert.decodeHex(textToObject(metadataEntries.data[j].metadataEntry.value)["title"]))
                  title = Convert.decodeHex(textToObject(metadataEntries.data[j].metadataEntry.value)["title"])
                }
                if(metadataEntries.data[j].metadataEntry.scopedMetadataKey.toHex() === "8D9A3BDD21391AA2"){
                  console.log(metadataEntries.data[j].metadataEntry.value)
                  version = metadataEntries.data[j].metadataEntry.value
                }
                if(metadataEntries.data[j].metadataEntry.scopedMetadataKey.toHex() === "C66A4EBE09577AF6"){
                  console.log(metadataEntries.data[j].metadataEntry.value)
                  thumbnail = metadataEntries.data[j].metadataEntry.value.slice(2,thumbnail.length - 2)
                }
              }
            }catch{
              console.log('\n The mosaic does not have metadata entries assigned.');
            }
          } else {
            console.log('\n The mosaic does not have metadata entries assigned.');
          }
        },
      )
      //ncftの場合のみ
      if(version.indexOf('ncft')>0){
        await accountHttp.getAccountInfo(account).toPromise().then(
          async (accountInfo) => {
              console.log(accountInfo.publicKey)
              let searchCriteria = {
                  group: TransactionGroup.Confirmed,
                  signerPublicKey:accountInfo.publicKey,
                  embedded:true,
                  transferMosaicId:symMosaicId,
                  order: Order.Desc,
                  pageNumber: 1,
                  pageSize: 1000
              }
              await transactionHttp.search(searchCriteria).toPromise().then(page=>{
                  if (page.pageSize > 0) {
                      console.log(page.data)
                      rxList = page.data
                  }
              })
              let searchCriteria2 = {
              group: TransactionGroup.Confirmed,
              recipientAddress:Address.createFromRawAddress(address),
              embedded:true,
              transferMosaicId:symMosaicId,
              order: Order.Desc,
              pageNumber: 1,
              pageSize: 1000
              }
              await transactionHttp.search(searchCriteria2).toPromise().then(page=>{
                  if (page.pageSize > 0) {
                      console.log(page.data)
                      txList = page.data
                  }
              })
          },
          (err) => console.error(err),
        );
      }
      let serialList = []
      rxList.forEach(transaction=>{
          serialList = [...serialList,transaction.message.payload]
      })
      txList.forEach(transaction=>{
          serialList = [...serialList,transaction.message.payload]
      })
      //ここで奇数のだけをリスト化
      let dict = {};
      for(let key of serialList){
          dict[key] = serialList.filter(function(x){return x === key}).length;
      }
      let uniqeSerialList = []
      Object.keys(dict).forEach(key => {
          if(dict[key]%2 === 1){
              uniqeSerialList = [...uniqeSerialList,key]
          }
      })
      console.log(uniqeSerialList)
      mosaicSerialMap[[mosaicIds[i]]] = {
        "name":name,
        "title":title,
        "version":version,
        "thumbnail":thumbnail,
        "serial":uniqeSerialList
      }
    }
    setSymbolMosaicsSerials(mosaicSerialMap)
  }

  const getSymbolMosaicsBundlenames = async (mosaicIds) => {
    const nodeUrl = stateCtx.config.nodeUrl
    const repositoryFactory = new RepositoryFactoryHttp(nodeUrl)
    const metadataHttp = repositoryFactory.createMetadataRepository();
    let mosaicSerialMap = {}
    for(let i = 0; i < mosaicIds.length; i++){
      let name = ""
      let title = ""
      let version = ""
      let thumbnail = ""
      let symMosaicId = new MosaicId(mosaicIds[i])
      let searchCriteriaMeta = {
        targetId: symMosaicId,
        metadataType: MetadataType.Mosaic,
      }

      await metadataHttp.search(searchCriteriaMeta).toPromise().then(
         (metadataEntries) => {
          if (metadataEntries.pageSize > 0) {
            // console.log(metadataEntries.data)
            try{
              for(let j = 0; j < metadataEntries.data.length; j++){
                if(metadataEntries.data[j].metadataEntry.scopedMetadataKey.toHex() === "8E0823CEF8A40075"){ //ver1.1
                  console.log(Convert.decodeHex(textToObject(metadataEntries.data[j].metadataEntry.value)["name"]))
                  name = Convert.decodeHex(textToObject(metadataEntries.data[j].metadataEntry.value)["name"])
                  console.log(Convert.decodeHex(textToObject(metadataEntries.data[j].metadataEntry.value)["title"]))
                  title = Convert.decodeHex(textToObject(metadataEntries.data[j].metadataEntry.value)["title"])
                }
                if(metadataEntries.data[j].metadataEntry.scopedMetadataKey.toHex() === "DA030AA7795EBE75"){ //ver1.0
                  console.log(Convert.decodeHex(textToObject(metadataEntries.data[j].metadataEntry.value)["name"]))
                  name = Convert.decodeHex(textToObject(metadataEntries.data[j].metadataEntry.value)["name"])
                  console.log(Convert.decodeHex(textToObject(metadataEntries.data[j].metadataEntry.value)["title"]))
                  title = Convert.decodeHex(textToObject(metadataEntries.data[j].metadataEntry.value)["title"])
                }
                if(metadataEntries.data[j].metadataEntry.scopedMetadataKey.toHex() === "8D9A3BDD21391AA2"){
                  console.log(metadataEntries.data[j].metadataEntry.value)
                  version = metadataEntries.data[j].metadataEntry.value
                }
                if(metadataEntries.data[j].metadataEntry.scopedMetadataKey.toHex() === "C66A4EBE09577AF6"){
                  console.log(metadataEntries.data[j].metadataEntry.value)
                  thumbnail = metadataEntries.data[j].metadataEntry.value.slice(2,thumbnail.length - 2)
                }
              }
            }catch{
              console.log('\n The mosaic does not have metadata entries assigned.');
            }
          } else {
            console.log('\n The mosaic does not have metadata entries assigned.');
          }
        },
      )

      //NFTDRIVEの場合バージョンを指定
      if (await checkDriveNft(mosaicIds[i])){
        version = "nftdrive"
      }

      mosaicSerialMap[[mosaicIds[i]]] = {
        "name":name,
        "title":title,
        "version":version,
        "thumbnail":thumbnail,
      }
    }
    setSymbolMosaicsBundlenames(mosaicSerialMap)
    return mosaicSerialMap
  }

  const getTransactionFee = () => {
      const nodeUrl = stateCtx.config.nodeUrl
      const repositoryFactory = new RepositoryFactoryHttp(nodeUrl)
      const networkHttp = repositoryFactory.createNetworkRepository()
      networkHttp.getTransactionFees().toPromise().then(
        (medianFeeMultiplier) => {
          console.log(medianFeeMultiplier)
          setSymbolTransactionFee(medianFeeMultiplier)
      }
      )
  }

  const setSymbollRoom = async (
          roomName,
          roomMosaicList,
          roomTitle,
          roomStartTime,
          roomEndTime,
          roomAmountOfPeople,
          ownerName,
          ownerAddress,
          ownerPublicKey
      ) => {
      await updateDoc(doc(db, "tokenroom", roomName), {
          mosaic: roomMosaicList,
          title: roomTitle,
          start_time: roomStartTime,
          end_time: roomEndTime,
          amount: roomAmountOfPeople,
          owner_name: ownerName,
          owner_address: ownerAddress,
          owner_public_key: ownerPublicKey,
      })
  }

  return {
      setSymbollRoom,
      getMosaicsFromUser,symbolUserMosaics,
      checkMosaic,
      getMosaicInfo,symbolMosaicInfo,
      getMosaicsInfo,symbolMosaicsInfo,symbolMosaicsAmount,
      getTransactionFee,symbolTransactionFee,
      getUserBalance,symbolUserBalance,
      getMetaInfo,symbolMetaInfo,
      getMetasInfo,symbolMetasInfo,
      getMosaicAccount,symbolMosaicAccount,
      getMosaicsAccount,symbolMosaicsAccount,
      getMosaicSerials,symbolMosaicSerials,
      getMosaicsSerials,symbolMosaicsSerials,
      getSymbolMosaicsBundlenames,symbolMosaicsBundlenames,
      getMetaInfoDemo,
      checkNode,checkedNode,nodeLoading
  }
}