import React from 'react'
import {Switch, Route ,Link} from 'react-router-dom'
import { Typography ,Box ,Card} from '@mui/material'
import {makeStyles} from '@material-ui/core/styles'
import IndexCard from './index-card'
import LoginCard from './login-card'
import LoginSSSCard from './login-sss-card'
import LoginAccountCard from './login-account-card'
import ReserveCard from './reserve'
import TermsCard from './terms'
import PrivacyCard from './privacy'
import HowToUseCard from './how-to-use'
import NcftCard from './ncft-card'
import { isMobile } from "react-device-detect"

const useStyles = makeStyles(theme => ({
    item: {
        flex: 1,
        display: 'flex',
        alignItems: 'center'
    },
    coverLeft: {
        background: 'linear-gradient(to bottom, #307AFF, 50%, #46cdff)',
        alignItems: 'center',
        flex: 1,
        display: 'flex',
        flexDirection: 'column'
    },
    coverContent: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        color: '#fff',
        position: 'relative'
    },
    coverRight: {
        position: 'relative',
        flex: 1,
        display: 'flex'
    },
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    card: {
        display: 'flex',
        minWidth: 700,
        minHeight: 600,
        maxHeight: 600,
        borderRadius: '10px',
        boxShadow: '0px 6px 18px 0px rgba(0,0,0,0.2)'
    },
    mobaileCard: {
        padding: 10,
        minWidth: 300,
        minHeight: 300,
        display: 'flex',
        borderRadius: '10px',
        boxShadow: '0px 6px 18px 0px rgba(0,0,0,0.2)'
    },
}))

export default function CardPage() {
    const classes = useStyles()

    return (
        <Box display="flex" alignItems="center" justifyContent="center">
            <Card className={(!isMobile)?classes.card:classes.mobaileCard}>
                <Box display="flex" flex="1">
                    {!isMobile?
                    <Box className={classes.coverLeft}>
                        <Box className={classes.item}>
                            <Box className='cover-image'/>
                        </Box>
                        <Box className={classes.item}>
                            <Box className={classes.coverContent}>
                                <Box textAlign="center" fontSize="h6.fontSize" className={classes.fontStyle}>Welcome
                                    to</Box>
                                <Box textAlign="center" fontWeight="fontWeightRegular" fontSize="h4.fontSize"
                                        className={classes.midItem}>TOKEN LIVE</Box>
                                <Box margin={2}>
                                    <Typography variant="caption" component="div" style={{ color: 'white' }} gutterBottom>
                                        {`TokenLiveとは、Symbolのmosaicを活用したライブ配信サービスです。配信者が指定した特定のunique（NFT）、もしくはbundle（譲渡不可なFT）を所有している人のみが参加できます`}
                                    </Typography>
                                </Box>
                                <Box display={"flex"} justifyContent={"space-around"}>
                                    <Link to="/howtouse" style={{ textDecoration: 'none' }}>

                                            <Typography variant="body2" component="div" style={{ color: 'white' }} gutterBottom>
                                                {`TokenLiveの使い方`}
                                            </Typography>
                                    </Link>

                                    <Link to="/terms" style={{ textDecoration: 'none' }}>

                                            <Typography variant="body2" component="div" style={{ color: 'white' }} gutterBottom>
                                                {`利用規約`}
                                            </Typography>
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    :<></>}
                    <Box className={classes.coverRight}>
                        <Switch>
                        <Route exact path="/" component={LoginCard}></Route>
                            <Route path="/loginSSS" component={LoginSSSCard}></Route>
                            <Route path="/loginAccount" component={LoginAccountCard}></Route>
                            <Route path="/index" component={IndexCard}></Route>
                            <Route path="/reserve" component={ReserveCard}></Route>
                            <Route path="/terms" component={TermsCard}></Route>
                            <Route path="/howtouse" component={HowToUseCard}></Route>
                            <Route path="/privacy" component={PrivacyCard}></Route>
                            <Route path="/ncft" component={NcftCard}></Route>
                        </Switch>
                    </Box>
                </Box>
            </Card>
        </Box>
    )
}
