import React, {useMemo, useState, useEffect} from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { Box, Tooltip } from '@material-ui/core'
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import {useGlobalState} from '../../utils/container'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

StreamPlayerMobile.propTypes = {
    uid: PropTypes.number.isRequired
}

export default function StreamPlayerMobile(props) {
    const stateCtx = useGlobalState()

    const {
        host,
        uid,
        isLocal,
        videoTrack,
        audioTrack,
        muteVideo,
        muteAudio,
        showInfo,
        rtcClient,
        isPlayAudioTrack,
        toggleIcon,
        toggleStreemPlayer,
        hideStreemPlayer,
        showStreemPlayer,
        boxHide,
        ownerAddress,
        backgroundColor,
        boxId,
        offerRoleChangeAudience,
        giftClick,
        userName,
        guestName
    } = props

    // function toggleStreemPlayer() {
    //     props.toggleStreemPlayer()
    // }

    useEffect(() => {
        if (videoTrack != null) {
            if(videoTrack instanceof Array){
                if (muteVideo === true) {
                    videoTrack[0].stop()
                } else if (muteVideo === false) {
                    videoTrack[0].play(`stream-player-${uid}`, { fit: 'contain' , mirror:false})
                }
            }else{
                if (muteVideo === true) {
                    videoTrack.stop()
                } else if (muteVideo === false) {
                    videoTrack.play(`stream-player-${uid}`, { fit: 'contain' , mirror:false})
                }
            }
        }
    }, [muteVideo, videoTrack])

    useEffect(() => {
        if (audioTrack != null) {
            if (muteAudio === true) {
                audioTrack.stop()
            } else if (muteAudio === false) {
                if (isPlayAudioTrack) audioTrack.play()
            }
        }
    }, [muteAudio, audioTrack])


    // useEffect(() => {
    //     if (videoTrack != null) {
    //         if (muteVideo === true) {
    //             videoTrack.stop()
    //         } else if (muteVideo === false) {
    //             videoTrack.play(`stream-player-${uid}`)
    //         }
    //     }
    // }, [muteVideo, videoTrack])

    const [state, setState] = useState({
        accessDelay: 0,
        fps: 0,
        resolution: 0
    })

    const analytics = useMemo(() => state, [state])

    useEffect(() => {
        const timer = setInterval(() => {
            if (isLocal) {
                const stats = rtcClient.getLocalVideoStats()
                const width = stats.captureResolutionWidth
                const height = stats.captureResolutionHeight
                const fps = stats.captureFrameRate
                setState({
                    accessDelay: `${stats.accessDelay ? stats.accessDelay : 0}`,
                    resolution: `${width}x${height}`,
                    fps: `${fps || 0}`
                })
            } else {
                const stats = rtcClient.getRemoteVideoStats()
                const width = stats.captureResolutionWidth
                const height = stats.captureResolutionHeight
                const fps = stats.captureFrameRate
                setState({
                    accessDelay: `${stats.accessDelay ? stats.accessDelay : 0}`,
                    resolution: `${width}x${height}`,
                    fps: `${fps || 0}`
                })
            }
        }, 500)

        return () => {
            clearInterval(timer)
        }
    }, [isLocal, rtcClient])

    return (
        <>
        <Box
        className={clsx(
            'stream-player',
        )}
        id={`stream-player-${uid}`}
        style={(boxHide)?{backgroundColor:"transparent",backgroundImage:"none",border:"none",height:"0px",width:"0px"}:{backgroundColor:backgroundColor}}
        >
            {props.children}
            {(!stateCtx.config.demo)?
            (boxHide)?
            <Box className="stream-hide" onClick={()=>showStreemPlayer(boxId)} sx={{width: 0, height: 0, cursor: 'pointer'}}><Tooltip title="画面を戻す"><NavigateNextIcon/></Tooltip></Box>
            :
            <>
                {(!toggleIcon)?
                <Box className="stream-uid" sx={(uid===ownerAddress)?{color:"red",fontWeight:"bold"}:{}}>
                    {(isLocal)?
                    <>
                    <Box
                    sx={(stateCtx.config.host)?{color:"red",fontWeight:"bold"}:{}}
                    >{userName}</Box>
                    </>
                    :
                    <>
                    <Box width={"150px"}>
                        {guestName[uid]??`ホスト`}　
                    </Box>
                    <Box className={"xym-avatar"} id={uid} onClick={giftClick}></Box>
                    </>
                    }
                </Box>
                :
                <Box className="stream-uid-mobile" sx={(uid===ownerAddress)?{color:"red",fontWeight:"bold"}:{}}>
                    {(isLocal)?
                    <></>
                    :<Box className={"xym-avatar-mobile"} id={uid} onClick={giftClick} ></Box>
                    }
                </Box>
                }
                {(host && !isLocal)?<Box className="stream-stop" onClick={()=>offerRoleChangeAudience(uid)} sx={{ width: 0, height: 0, cursor: 'pointer'}}><Tooltip title="配信の停止"><HighlightOffIcon /></Tooltip></Box>:<></>}
                {(toggleIcon)?<Box className="stream-icon" onClick={()=>toggleStreemPlayer(boxId)} sx={{ width: 0, height: 0, cursor: 'pointer'}}><Tooltip title="画面切り替え"><ZoomOutMapIcon/></Tooltip></Box>:<></>}
                {(toggleIcon)?<Box className="stream-hide" onClick={()=>hideStreemPlayer(boxId)} sx={{ width: 0, height: 0, cursor: 'pointer'}}><Tooltip title="画面を隠す"><NavigateBeforeIcon/></Tooltip></Box>:<></>}
            </>
            :<></>}
        </Box>
        </>


    )
}