import React, {useState} from 'react'
import clsx from 'clsx'
import Tooltip from '@material-ui/core/Tooltip'
import {makeStyles} from '@material-ui/core/styles'
import { Avatar, Slider, Stack } from '@mui/material'
import { grey , green} from '@material-ui/core/colors'
import { Box, Typography} from '@material-ui/core'
import PlayArrow from '@material-ui/icons/PlayArrow';
import PauseIcon from '@material-ui/icons/Pause';
import StopIcon from '@material-ui/icons/Stop';
import { VolumeDown, VolumeUp } from '@material-ui/icons'
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import {useGlobalState, useGlobalMutation} from '../../utils/container'
import useDevices from '../../utils/use-devices'

const useStyles = makeStyles({
    menu: {
        height: '150px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    customBtn: {
        width: '50px',
        height: '50px',
        marginLeft: '20px',
        borderRadius: '26px',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        backgroundSize: '50px',
        cursor: 'pointer'
    },
    customBgmBtn: {
        width: '50px',
        height: '50px',
        marginLeft: '50px',
        marginRight: '50px',
        borderRadius: '26px',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        backgroundSize: '50px',
        cursor: 'pointer'
    },

    leftAlign: {
        display: 'flex',
        flex: '1',
        justifyContent: 'space-evenly'
    },
    rightAlign: {
        display: 'flex',
        flex: '1',
        justifyContent: 'center'
    },
    menuContainer: {
        width: '100%',
        height: '100%',
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        zIndex: '2'
    }
})

export default function StreamMenu(props) {
    const stateCtx = useGlobalState()
    const mutationCtx = useGlobalMutation()
    const [camera,mic] = useDevices()

    const [sliderVolume, setSliderVolume] = useState(10);
    const {
        uid,
        muteVideo,
        muteAudio,
        videoTrack,
        audioTrack,
        volumeLevel,
        shareScreen,
        isEnableMenu,
        ownerAddress,
        remoteUsers,
        requestRoleChangeAudience,
        selecthandleOpen,
        devicehandleOpen,
        devicehandleClose,
        deviceOpen,
        bgmhandleOpen,
        audioMixingTrack,
        bgmFile,
        rtcClient,
        bgmState,
        setBgmState,
        isGuestInvite,
        offerCancelRoleChangeHost,
        guestUser,
        toggleResolution,
        quality,
        demo,
        virtualBghandleOpen,
        virtualBackgroundEnabled,
        virtualBgStop,
        setCameraList,
        setMicrophoneList,
    } = props

    const classes = useStyles()

    function toggleVideo() {
        props.toggleVideo()
    }

    function toggleAudio() {
        props.toggleAudio()
    }

    function toggleShareScreen() {
        props.toggleShareScreen()
    }

    setCameraList(camera)
    setMicrophoneList(mic)

    return (
        <div className={classes.menuContainer}>
            {(isEnableMenu)?
            <div className={classes.menu}>
                {(demo)?
                <Tooltip placement="top" title={(shareScreen)?'画面共有時は画質を変更できません':'画質の切り替え（デモのみ、配信画質確認用）'}>
                    <i
                        onClick={()=>(shareScreen)?null:toggleResolution()}
                        className={clsx(
                            classes.customBtn,
                            (shareScreen && quality===0)?'resolution-low-disable':(shareScreen && quality===1)?'resolution-high-disable':(quality===0)?'resolution-low':'resolution-high'
                        )}
                    />
                </Tooltip>
                :<></>}
                <Tooltip placement="top" title={'カメラ/マイクの切り替え'}>
                    <i
                        onClick={()=>(!deviceOpen)?devicehandleOpen():devicehandleClose()}
                        className={clsx(
                            classes.customBtn,
                            (!deviceOpen)?'rotate-camera':'off-rotate-camera'
                        )}
                    />
                </Tooltip>
                <Tooltip placement="top" title={!videoTrack ? "Track無し" : muteVideo ? 'ビデオを開始する' : 'ビデオを停止する'}>
                    <i
                        onClick={!videoTrack ? null : toggleVideo}
                        className={clsx(
                            classes.customBtn,
                            !videoTrack ? "disable-video" : muteVideo ? 'mute-video' : 'unmute-video'
                        )}
                    />
                </Tooltip>
                <Tooltip placement="top" title={!audioTrack ? "Track無し" : muteAudio ? 'マイクを開始する' : 'マイクを停止する'}>
                    <i
                        onClick={!audioTrack ? null : toggleAudio}
                        className={clsx(
                            classes.customBtn,
                            !audioTrack ? "disable-audio" : muteAudio ? 'mute-audio' :'unmute-audio'
                        )}
                    />
                </Tooltip>
                <Avatar className={(!muteAudio && volumeLevel>0.1)?"live-text":""} sx={(!muteAudio && volumeLevel>0.1)?{ width: 10, height: 10 , backgroundColor:green[500]}:{ width: 10, height: 10 , backgroundColor:grey[500]}}>　</Avatar>
                <Tooltip placement="top" title={shareScreen ? '画面の共有を停止する' : '画面の共有する'}>
                    <i
                        onClick={toggleShareScreen}
                        className={clsx(
                            classes.customBtn,
                            shareScreen ? 'start-screen-share' : 'off-screen-share'
                        )}
                    />
                </Tooltip>
                {(!stateCtx.config.demo)?<>
                    {(uid===ownerAddress)?
                    <Tooltip placement="top" title={(quality===1)?"高画質シングル配信モードではゲストを招待することはできません":(isGuestInvite)?"招待を中止する":(Object.keys(remoteUsers).length<3)?'ゲストを配信に招待する':'上限に達したため招待できません'}>
                        <i
                            onClick={()=>(quality===1)?null:(isGuestInvite)?offerCancelRoleChangeHost(guestUser):(Object.keys(remoteUsers).length<3)?selecthandleOpen():null}
                            className={clsx(
                                classes.customBtn,
                                (quality===1)?'disable-guest':(isGuestInvite)?'leave-guest':(Object.keys(remoteUsers).length<3)?'join-guest':'disable-guest'
                            )}
                        />
                    </Tooltip>
                    :
                    <Tooltip placement="top" title={'配信を停止する'}>
                        <i
                            onClick={()=>{
                                requestRoleChangeAudience(ownerAddress)
                            }}
                            className={clsx(
                                classes.customBtn,
                                'disconnect'
                            )}
                        />
                    </Tooltip>
                    }
                </>:<>
                    <Tooltip placement="top" title={'デモ時はゲスト招待機能は使えません'}>
                        <i
                            className={clsx(
                                classes.customBtn,
                                'disable-guest'
                            )}
                        />
                    </Tooltip>
                </>}
                {(bgmState=='SETTING')?
                <Tooltip placement="top" title={`BGMを選曲する`}>
                    <i
                        onClick={()=>bgmhandleOpen()}
                        className={clsx(
                            classes.customBtn,'bgm'
                        )}
                    />
                </Tooltip>
                :
                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} style={{ width: '200px' }}>
                    <Box display={"flex"}>
                        <Typography variant="caption" component="div" gutterBottom style={{ color: 'white' }}>
                            {`${bgmFile.name}`}
                        </Typography>
                    </Box>
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        <Box display={"flex"} marginRight={1}>
                            {(bgmState=="STOP")?
                                <PlayArrow style={{ color: 'white' ,cursor: 'pointer'}} onClick={
                                    async ()=>{
                                        if (bgmFile) {
                                            if(!stateCtx.config.demo) await rtcClient.publish(audioMixingTrack);
                                            audioMixingTrack.play();
                                            audioMixingTrack.setVolume(1)
                                            audioMixingTrack.startProcessAudioBuffer({ loop: true });
                                            setBgmState("PLAYING")
                                        }
                                    }
                                }/>
                            :
                            (bgmState=="PLAYING")?
                                <PauseIcon style={{ color: 'white' ,cursor: 'pointer'}} onClick={
                                    async ()=>{
                                        audioMixingTrack.pauseProcessAudioBuffer();
                                        setBgmState("PAUSE")
                                    }
                                }/>
                            :
                            (bgmState=="PAUSE")?
                                <PlayArrow style={{ color: 'white' ,cursor: 'pointer'}} onClick={
                                    async ()=>{
                                        audioMixingTrack.resumeProcessAudioBuffer();
                                        setBgmState("PLAYING")
                                    }
                                }/>
                            :<></>
                            }
                        </Box>
                        <Box display={"flex"} marginRight={1}>
                            <StopIcon style={{ color: 'white' ,cursor: 'pointer'}} onClick={
                                async ()=>{
                                    if (bgmFile) {
                                        audioMixingTrack.stopProcessAudioBuffer();
                                        audioMixingTrack.stop();
                                        if(!stateCtx.config.demo) await rtcClient.unpublish(audioMixingTrack);
                                        setBgmState("SETTING")
                                    }
                                }
                            }/>
                        </Box>
                        <Box display={"flex"} alignItems="center">
                            <Stack spacing={1} direction="row" sx={{ mb: 1 }} alignItems="center">
                                {(sliderVolume>0)?<VolumeDown style={{ color: 'white' }}/>:<VolumeOffIcon style={{ color: 'white' }}/>}
                                <Slider
                                    size="small"
                                    aria-label="bgm volume"
                                    defaultValue={10}
                                    getAriaValueText={(value)=>{
                                        setSliderVolume(value)
                                        if(audioMixingTrack) audioMixingTrack.setVolume(value/10)
                                    }}
                                    step={10}
                                    min={0}
                                    max={100}
                                    style={{ color: 'white' , width:"50px"}}
                                /> 
                                <VolumeUp style={{ color: 'white' }}/>
                            </Stack>
                        </Box>
                    </Box>
                </Box>
                }
                <Tooltip placement="top" title={(virtualBackgroundEnabled)?'バーチャル背景を停止します':'バーチャル背景を設定します'}>
                    <i
                        onClick={()=>(virtualBackgroundEnabled)?virtualBgStop():virtualBghandleOpen()}
                        className={clsx(
                            classes.customBtn,
                            (virtualBackgroundEnabled)?'virtual':'off-virtual'
                        )}
                    />
                </Tooltip>
            </div>
            :
            <div className={classes.menu}>
                <Tooltip placement="top" title={"ライブ開始まで操作できません"}>
                    <i
                        className={clsx(
                            classes.customBtn,
                            'disable-rotate-camera'
                        )}
                    />
                </Tooltip>
                <Tooltip placement="top" title={"ライブ開始まで操作できません"}>
                    <i
                        className={clsx(
                            classes.customBtn,
                            'disable-video'
                        )}
                    />
                </Tooltip>
                <Tooltip placement="top" title={"ライブ開始まで操作できません"}>
                    <i
                        className={clsx(
                            classes.customBtn,
                            'disable-audio'
                        )}
                    />
                </Tooltip>
                <Tooltip placement="top" title={"ライブ開始まで操作できません"}>
                    <i
                        className={clsx(
                            classes.customBtn,
                            'disable-screen-share'
                        )}
                    />
                </Tooltip>
                <Tooltip placement="top" title={"ライブ開始まで操作できません"}>
                    <i
                        className={clsx(
                            classes.customBtn,
                            'disable-guest'
                        )}
                    />
                </Tooltip>
                <Tooltip placement="top" title={"ライブ開始まで操作できません"}>
                    <i
                        className={clsx(
                            classes.customBtn,
                            'disable-bgm'
                        )}
                    />
                </Tooltip>
                <Tooltip placement="top" title={"ライブ開始まで操作できません"}>
                    <i
                        className={clsx(
                            classes.customBtn,
                            'disable-virtual'
                        )}
                    />
                </Tooltip>

            </div>
            }
        </div>
    )
}