import { initializeApp } from "firebase/app";
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: "AIzaSyD2IOz18G0MR65KeE-z1vY3jCieYbjuNpg",
  authDomain: "tokenlive-b98bd.firebaseapp.com",
  projectId: "tokenlive-b98bd",
  storageBucket: "tokenlive-b98bd.appspot.com",
  messagingSenderId: "358238757773",
  appId: "1:358238757773:web:4e6555c8f44415b53c93c4",
  measurementId: "G-E4HDJLF7MB"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore();

export default app;