import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import {Link} from 'react-router-dom'
import { Box,Typography,Alert,FormControl,Accordion,AccordionSummary,AccordionDetails} from '@mui/material'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { HashLink } from 'react-router-hash-link';

const useStyles = makeStyles((theme) => ({
    menuTitle: {
        color: '#333333',
        textAlign: 'center',
        fontSize: 'h6.fontSize',
        position: 'relative',
        top: '7px'
    },
    menu: {
        margin: '0.25rem 0',
        position: 'relative',
        height: '39px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    line: {
        marginTop: '0.2rem',
        marginBottom: '0.5rem',
        borderBottom: '1px solid #EAEAEA'
    },
}))

export default function HowToUseCard() {
    const classes = useStyles()
    return (
        <Box flex="1" display="flex" flexDirection="column" height={"90vh"}>
            <Link to="/index" className="back-btn"/>
            <FormControl className={classes.menu}>
                <span className={classes.menuTitle}>TokenLiveの使い方</span>
            </FormControl>
            <Box className={classes.line} marginTop={2}></Box>
            <Box 
                style={{overflowY: "auto"}}
                flex="1"
                display="flex"
                flexDirection="column"
                padding="0 1rem"
                justifyContent="flex-start"
            >
                <Box margin={2}>
                    <Accordion style={{backgroundColor:"#f5f5f5"}}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                        <Typography>目次</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#100">1.前提条件</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#200">2.ログイン方法</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#201">　2.1.アドレスのみでログイン</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#202">　2.2.SSS Extensionでログイン</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#300">3.ルームに入室する</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#400">4.ルーム内での操作</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#410">　4.1.チャット機能</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#411">　　4.1.1.アイコンと表示名</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#412">　　4.1.2.テキスト/リアクションの入力</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#413">　　4.1.3.チャット表示部分</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#420">　4.2.投げ銭機能</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#430">　4.3.メインメニュー</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#431">　　4.3.1.カメラ/マイクの設定</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#432">　　4.3.2.カメラ/マイクのオンオフ</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#433">　　4.3.3.画面共有のオンオフ</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#434">　　4.3.4.BGMのオンオフ</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#435">　　4.3.5.ゲストを招待する（ホストのみの機能）</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#500">5.ルームの予約</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#510">　5.1.参加用mosaicの条件</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#520">　5.2.参加用mosaicの作成</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#521">　　5.2.1.SSSのデモページで作成</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#522">　　5.2.2.Symbolウォレットでの作成</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#523">　　5.2.3.Arcanaウォレットでの作成</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#530">　5.3.参加用mosaicの配布</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#540">　5.1.ルームの予約</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#600">6.ルームの予約変更</HashLink><br/>
                        <HashLink style={{fontSize:"12px" ,textDecoration: 'none'}} smooth to="#700">7.注意事項</HashLink><br/>
                        </AccordionDetails>
                    </Accordion>
                </Box>
                <Typography variant="body1" component="div" gutterBottom id="100">1.前提条件</Typography>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>TokenLiveの利用には以下の環境が必要です</Typography>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>✔︎ デバイス：Windows、Mac、iPhone、Android端末</Typography>
                <Typography variant="caption" component="div" gutterBottom>✔︎ ブラウザ：Chrome、Safari</Typography>
                <br/>
                <br/>
                <Typography variant="body1" component="div" gutterBottom id="200">2.ログイン方法</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse058.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>TokenLiveでは現在２つの方法でログインが可能です</Typography>
                <br/>
                <Typography variant="caption" component="div" gutterBottom style={{fontWeight:"bold"}}>SSS Extensionでログイン:</Typography>
                <Typography variant="caption" component="div" gutterBottom>GoogleChromeの拡張機能である「SSS Extension」を使って秘密鍵、またはニーモニックを登録しログインします。一度登録すれば、投げ銭やログインが簡単に行え、ルームの予約も長時間、高画質を選択する事ができるようになります。</Typography>
                <br/>
                <Alert style={{fontSize:"11px"}} severity="warning">SSS Extensionもオープンソースであり、暗号化によって秘密鍵を安全にブラウザ上に保管できますが、ブラウザ上に保管する限り絶対はありません。利用する場合は最低限のトークンやXYMのみを入れて使うようにして下さい</Alert>
                <br/>
                <Typography variant="caption" component="div" gutterBottom style={{fontWeight:"bold"}}>アドレスのみでログイン: </Typography>
                <Typography variant="caption" component="div" gutterBottom>COMSA walletや他のウォレットからメッセージを送ることでログインします。秘密鍵を扱わないので安全にサービスを利用できますが、投げ銭やログインの都度ウォレットの操作が必要になり、ルームの予約に関しても制限があります</Typography>
                <br/>
                <Alert style={{fontSize:"11px"}} severity="warning">アドレスのみでログインの場合、ログインを行うたびにトランザクション手数料が別途必要になります。（２０２２年１１月現在で0.03xym程度。ブロックチェーンのネットワーク状況によって手数料は前後します。）</Alert>
                <br/>
                <Typography variant="caption" component="div" gutterBottom style={{fontWeight:"bold"}} id="201">2.1.アドレスのみでログイン</Typography>
                <Typography variant="caption" component="div" gutterBottom>COMSA walletを例にしてログインの方法を解説します。</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse059.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>DEPOSITからSYMBOLのアドレス（Nから始まる文字列）をコピーする</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse060.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>TokenLiveへ戻り「アドレスのみでログイン」から上記ページへ移動後、先ほどのSYMBOLのアドレスを入力して認証開始をクリックする</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse061.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>表示されたダイアログに沿って、COMSAウォレットのWITHDRAWへ情報を転記する</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse062.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>　宛先アドレス: ダイアログに表示されている宛先アドレスを入力</Typography>
                <Typography variant="caption" component="div" gutterBottom>　送金数量: 0が指定できないため、0.0000001を指定</Typography>
                <Typography variant="caption" component="div" gutterBottom>　メッセージ: ダイアログに表示されている４桁の数字を入力</Typography>
                <Typography variant="caption" component="div" gutterBottom>　トランザクション手数料: 遅い</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse063.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>TokenLiveへ戻りこの画面になっていればログイン完了です</Typography>
                <br/>
                <br/>
                <Typography variant="caption" component="div" gutterBottom style={{fontWeight:"bold"}} id="202">2.2.SSS Extensionでログイン</Typography>
                <Typography variant="caption" component="div" gutterBottom>SSS Extensionでログインする方法を解説します。</Typography>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>以下リンクからSSSを追加する</Typography>
                <Typography variant="body2" component="div" style={{ color: 'white' }} gutterBottom>
                    <a href='https://chrome.google.com/webstore/detail/sss-extension/llildiojemakefgnhhkmiiffonembcan/'
                        style={{ textDecoration: 'none'}}
                        rel="noreferrer"
                        >
                        　SSS Extension
                    </a>
                </Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse066.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>追加後表示されるダイアログにてInport Mnemonicβをクリック</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse067.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>以下入力して次へ進む</Typography>
                <Typography variant="caption" component="div" gutterBottom>　Name: 好きな名前を入力</Typography>
                <Typography variant="caption" component="div" gutterBottom>　Mnemonic: COMSAアカウント登録時にバックアップした24文字の英単語を半角スペースで区切り入力</Typography>
                <Typography variant="caption" component="div" gutterBottom>　Password: SSS利用時のパスワード</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse068.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>インポートが成功すると、アドレスが表示されるのでDoneで完了する</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"100%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse069.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>SSS Extensionのホーム画面にアカウントが追加されているのが確認できる</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse058.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>TokenLive側に戻りSSS Extensionでログインするをクリックする</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"40%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse002.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>TokenLiveページ内で右クリックもしくは同時タップを行い『SSSと連携する』をクリックする</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"100%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse070.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>Symbolアカウントでログインするをクリックする</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"100%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse071.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>SSS Extensionを登録した時のパスワードを入力しLOGINをクリック</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"100%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse072.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>SIGNをクリックして認証を行うためのサインを行う</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse063.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>この画面になっていればログイン完了です</Typography>
                <br/>
                <br/>
                <Typography variant="body1" component="div" gutterBottom id="300">3.ルームに入室する</Typography>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>視聴者は事前に参加資格用のmosaicを受け取っている必要があります。参加資格用mosaicを所有した状態でTokenLiveにアクセスすると予約情報を確認する事ができます。『入室』をクリックするとルームに入る事ができます。</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse005.png" alt="" />
                </Box>
                <br/>
                <br/>
                <Typography variant="body1" component="div" gutterBottom id="400">4.ルーム内での操作</Typography>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>ルーム内での操作を説明します。配信者と視聴者ではメニュー部分の表示が異なります。</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse006.png" alt="" />
                </Box>
                <br/>
                <br/>
                <Typography variant="body2" component="div" gutterBottom>配信者/視聴者共通操作</Typography>
                <Typography variant="caption" component="div" gutterBottom style={{fontWeight:"bold"}} id="410">4.1.チャット機能</Typography>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>チャット機能は配信者、参加者同士で交流するためのものです。予約されたルーム内ではライブが始まる前からチャット機能を使う事ができます</Typography>
                <br/>
                <Typography variant="caption" component="div" gutterBottom style={{fontWeight:"bold"}} id="411">4.1.1.アイコンと表示名</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse007.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>赤枠部分をクリックするとチャット上に表示するアイコンと表示名を変更する事ができます。アイコンの画像はインターネット上の画像のURLを入力する必要があります。</Typography>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>例)Twitterのアイコンを使う場合</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse008.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>メニューのプロフィールで自分のプロフィールページを開き、プロフィール画像をクリック。その後画像に対して右クリックor同時タップをし『画像アドレスをコピー』でURLを取得できます。</Typography>
                <br/>
                <Typography variant="caption" component="div" gutterBottom style={{fontWeight:"bold"}} id="412">4.1.2.テキスト/リアクションの入力</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse009.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>テキストを最大２００文字まで入力可能。送信ボタンを押すかCtrl+Enterで送信可能です。また、リアクションボタンはワンクリックで送信可能です。</Typography>
                <br/>
                <Typography variant="caption" component="div" gutterBottom style={{fontWeight:"bold"}} id="413">4.1.3.チャット表示部分</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse010.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>メッセージの右にある小さなバツボタンを押すと、押した発言者のメッセージが全て非表示になります。</Typography>
                <br/>
                <Typography variant="caption" component="div" gutterBottom style={{fontWeight:"bold"}} id="420">4.2.投げ銭機能</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse011.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>ホスト（赤色）もしくはゲストの名前の横にあるXYMアイコンをクリックして投げ銭（xym）を行う事ができます。アイコンをクリックすると、送金画面が表示されます。</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse012.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>送金するxymを設定し、トランザクション手数料（通常は普通でOK）、メッセージを入力し『xymを送る』をクリックします。</Typography>
                <Typography variant="caption" component="div" gutterBottom>この時点ではまだ送金は完了していません。ポップアップを閉じた後SSSを開きます。</Typography>
                <br/>
                <Alert style={{fontSize:"11px"}} severity="warning">SSSはTokenLiveのルームページをアクティブにした状態で開いて下さい。そうしないとTokenLive側でSSSの署名を検知できないためエラーとなり送金が出来ません</Alert>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse014.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>初期設定の2.2.Symbolアカウントを登録するで登録したPasswordを入力し、SSSにログインします</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse015.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>Mosaicsの数量（6BED913FA20223F8はXYMのmosaicIDです）を確認し『SIGN』をクリックします</Typography>
                <br/>
                <Alert style={{fontSize:"11px"}} severity="warning">SSSの署名は60秒以内に行わないと無効になります。</Alert>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse016.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>署名後、30秒以上待つと相手に投げ銭が届き、チャット画面に送金メッセージが送信されます</Typography>
                <br/>
                <br/>
                <Typography variant="body2" component="div" gutterBottom>配信者（ゲスト含む）操作</Typography>
                <br/>
                <Typography variant="caption" component="div" gutterBottom style={{fontWeight:"bold"}} id="430">4.3.メインメニュー</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse017.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom style={{fontWeight:"bold"}} id="431">4.3.1.カメラ/マイクの設定</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse017_1.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>配信に使うカメラとマイクを確認および変更する事ができます。マイクはOKと出ていれば認識されています。カメラは表示されていれば認識されています。</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse018.png" alt="" />
                </Box>
                <br/>
                <Alert style={{fontSize:"11px"}} severity="warning">もしカメラが真っ黒で何も表示されない場合、Chrome自体の終了/起動で回復する場合があります</Alert>
                <br/>
                <Typography variant="caption" component="div" gutterBottom style={{fontWeight:"bold"}} id="432">4.3.2.カメラ/マイクのオン/オフ</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse017_2.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>配信に使うカメラとマイクをオンオフにする事ができます</Typography>
                <br/>
                <Alert style={{fontSize:"11px"}} severity="info">マイクのオンを忘れたまましゃべっている事があります。マイク右上の小さな丸が緑の時は音声が配信されている状態になります</Alert>
                <br/>
                <Typography variant="caption" component="div" gutterBottom style={{fontWeight:"bold"}} id="433">4.3.3.画面共有のオン/オフ</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse017_3.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>ローカルのPC内のウィンドウやタブなどを配信する事ができます。</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse019.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom style={{fontWeight:"bold"}} id="434">4.3.4.BGMのオン/オフ</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse017_4.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>ローカルのPC内の音楽をBGMとして配信する事ができます</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse021.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom style={{fontWeight:"bold"}} id="435">4.3.5.ゲストを招待する（ホストのみの機能）</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse017_5.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>上記のアイコン、もしくはチャット内ユーザーのアイコンをクリックする事で参加者を配信に参加させる事が可能です</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse022.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>クリックするとポップアップが表示されるため対象のアカウントアドレスを指定して『招待する』をクリックします</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse023.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>招待された参加者は、招待を受けるか辞退するかを選択できます。招待を受けると配信に参加されます</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse024.png" alt="" />
                </Box>
                <br/>
                <Alert style={{fontSize:"11px"}} severity="info">招待を受けたタイミングではカメラとマイクはオフになっていますのでご注意下さい。</Alert>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>制限時間が来る前でも、配信を終了する事ができます。</Typography>
                <Typography variant="caption" component="div" gutterBottom>ホスト側から終了させる場合</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse026.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>ゲスト側から終了させる場合</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse025.png" alt="" />
                </Box>
                <br/>
                <br/>
                <Typography variant="body1" component="div" gutterBottom id="500">5.ルームの予約</Typography>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>TokenLiveを行うには、配信者がルームを予約する必要があります。COMSAで出品するなどで参加用mosaicを作成してからルームの予約から進めて下さい</Typography>
                <br/>
                <Typography variant="caption" component="div" gutterBottom style={{fontWeight:"bold"}} id="510">5.1.参加用mosaicの条件</Typography>
                <Typography variant="caption" component="div" gutterBottom>参加用mosaicは暗号資産に該当しないmosaic（NFT、もしくはNCFT）である必要があります。mosaic作成時の条件は以下の通りです</Typography>
                <br/>
                <Box display={"flex"} style={{fontSize:"11px"}}>
                    <Box width={"40%"} border={"solid 1px"} borderColor={"#696969"} display={"flex"} justifyContent={"center"} alignItems={"center"}>項目</Box>
                    <Box width={"30%"} border={"solid 1px"} borderColor={"#696969"} display={"flex"} justifyContent={"center"} alignItems={"center"}>NFT</Box>
                    <Box width={"30%"} border={"solid 1px"} borderColor={"#696969"} display={"flex"} justifyContent={"center"} alignItems={"center"}>NCFT</Box>
                </Box>
                <Box display={"flex"} style={{fontSize:"11px"}}>
                    <Box width={"40%"} border={"solid 1px"} borderColor={"#696969"} display={"flex"} justifyContent={"center"} alignItems={"center"}>Divisibility</Box>
                    <Box width={"30%"} border={"solid 1px"} borderColor={"#696969"} display={"flex"} justifyContent={"center"} alignItems={"center"}>0</Box>
                    <Box width={"30%"} border={"solid 1px"} borderColor={"#696969"} display={"flex"} justifyContent={"center"} alignItems={"center"}>0</Box>
                </Box>
                <Box display={"flex"} style={{fontSize:"11px"}}>
                    <Box width={"40%"} border={"solid 1px"} borderColor={"#696969"} display={"flex"} justifyContent={"center"} alignItems={"center"}>Supply</Box>
                    <Box width={"30%"} border={"solid 1px"} borderColor={"#696969"} display={"flex"} justifyContent={"center"} alignItems={"center"}>1</Box>
                    <Box width={"30%"} border={"solid 1px"} borderColor={"#696969"} display={"flex"} justifyContent={"center"} alignItems={"center"}>自由</Box>
                </Box>
                <Box display={"flex"} style={{fontSize:"11px"}}>
                    <Box width={"40%"} border={"solid 1px"} borderColor={"#696969"} display={"flex"} justifyContent={"center"} alignItems={"center"}>Transferable</Box>
                    <Box width={"30%"} border={"solid 1px"} borderColor={"#696969"} display={"flex"} justifyContent={"center"} alignItems={"center"}>自由</Box>
                    <Box width={"30%"} border={"solid 1px"} borderColor={"#696969"} display={"flex"} justifyContent={"center"} alignItems={"center"}>False（空白）</Box>
                </Box>
                <Box display={"flex"} style={{fontSize:"11px"}}>
                    <Box width={"40%"} border={"solid 1px"} borderColor={"#696969"} display={"flex"} justifyContent={"center"} alignItems={"center"}>Supply mutable</Box>
                    <Box width={"30%"} border={"solid 1px"} borderColor={"#696969"} display={"flex"} justifyContent={"center"} alignItems={"center"}>False（空白）</Box>
                    <Box width={"30%"} border={"solid 1px"} borderColor={"#696969"} display={"flex"} justifyContent={"center"} alignItems={"center"}>自由</Box>
                </Box>
                <Box display={"flex"} style={{fontSize:"11px"}}>
                    <Box width={"40%"} border={"solid 1px"} borderColor={"#696969"} display={"flex"} justifyContent={"center"} alignItems={"center"}>Restrictable</Box>
                    <Box width={"30%"} border={"solid 1px"} borderColor={"#696969"} display={"flex"} justifyContent={"center"} alignItems={"center"}>自由</Box>
                    <Box width={"30%"} border={"solid 1px"} borderColor={"#696969"} display={"flex"} justifyContent={"center"} alignItems={"center"}>自由</Box>
                </Box>
                <Box display={"flex"} style={{fontSize:"11px"}}>
                    <Box width={"40%"} border={"solid 1px"} borderColor={"#696969"} display={"flex"} justifyContent={"center"} alignItems={"center"}>Revokable</Box>
                    <Box width={"30%"} border={"solid 1px"} borderColor={"#696969"} display={"flex"} justifyContent={"center"} alignItems={"center"}>自由</Box>
                    <Box width={"30%"} border={"solid 1px"} borderColor={"#696969"} display={"flex"} justifyContent={"center"} alignItems={"center"}>自由</Box>
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom style={{fontSize:"11px"}}>NFT・・・世界に一つしかないトークン</Typography>
                <Typography variant="caption" component="div" gutterBottom style={{fontSize:"11px"}}>NCFT・・・転送不可にする事で譲渡できないようにしたトークン</Typography>
                <Typography variant="caption" component="div" gutterBottom style={{fontSize:"11px"}}>Divisibility・・・小数点いくつまで持たせる事ができるか</Typography>
                <Typography variant="caption" component="div" gutterBottom style={{fontSize:"11px"}}>Supply・・・発行枚数</Typography>
                <Typography variant="caption" component="div" gutterBottom style={{fontSize:"11px"}}>Transferable・・・転送を許可するかどうか</Typography>
                <Typography variant="caption" component="div" gutterBottom style={{fontSize:"11px"}}>Supply mutable・・・発行枚数を変更可能にするかどうか</Typography>
                <Typography variant="caption" component="div" gutterBottom style={{fontSize:"11px"}}>Restrictable・・・送信先制限を可能にするかどうか</Typography>
                <Typography variant="caption" component="div" gutterBottom style={{fontSize:"11px"}}>Revokable・・・発行者が回収可能にするかどうか</Typography>
                <br/>
                <Alert style={{fontSize:"11px"}} severity="info">参加用mosaicは配信者自身が発行したmosaicである必要はありません。例えばmosaic発行代行者が発行したmosaicであっても登録する事が可能です</Alert>
                <br/>
                <Typography variant="caption" component="div" gutterBottom style={{fontWeight:"bold"}} id="520">5.2.参加用mosaicの作成</Typography>
                <Typography variant="caption" component="div" gutterBottom>参加用mosaicを所有していない場合、SSSのデモページもしくは、Symbolウォレット、Arcanaウォレットにて作成する事が可能です。この章ではNCFTの作成方法を記載します</Typography>
                <br/>
                <Typography variant="caption" component="div" gutterBottom style={{fontWeight:"bold"}} id="521">5.2.1.SSSのデモページで作成</Typography>
                <Typography variant="caption" component="div" gutterBottom>以下のリンクからSSSのデモページへアクセスします。SSSをインストールしていない方は2.初期設定を先に実施して下さい</Typography>
                <br/>
                <Typography variant="body2" component="div" style={{ color: 'white' }} gutterBottom>
                    <a href='https://inatatsu-tatsuhiro.github.io/SSS-Demo/main1'
                        style={{ textDecoration: 'none'}}
                        target="_blank"
                        rel="noreferrer"
                        >
                        　SSS Extensionでmosaicの作成
                    </a>
                </Typography>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>SSSデモページ内で右クリックもしくは同時タップを行い『SSSと連携する』をクリック後、ブラウザをリロードする</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"40%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse002.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>第三者へと譲渡できるかを空白に、可分性を0に指定し『SSSで署名』をクリック　※その他設定は自由です</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse042.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>SSSを開き、署名を行います。</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse043.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>再度SSSを開き、右上の設定アイコンをクリック</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse045.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>Mosaicsの部分に新しいMosaicができている事を確認して下さい</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse046.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom style={{fontWeight:"bold"}} id="522">5.2.2.Symbolウォレットでの作成</Typography>
                <Typography variant="caption" component="div" gutterBottom>Mosaicsの「Create new mosaics」にてDivisibilityを0に、Transferableを空白にしてSendをクリック　※その他設定は自由です</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse047.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>作成後、Mosaicsをクリックし、新しいMosaicが作成されていてDivisibilityが0、Transferableがfalseになっている事を確認する</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse051.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom style={{fontWeight:"bold"}} id="523">5.2.3.Arcanaウォレットでの作成</Typography>
                <Typography variant="caption" component="div" gutterBottom>トップページより、右下の機能アイコンをクリック後、モザイクをクリックする</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"40%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse048.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>可分性を0に指定し、転送可をオフにして『作成』をクリック　※その他設定は自由です</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"40%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse049.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>作成後、左下のホームアイコンをクリックし、新しいMosaicが作成されていて可分性が0、転送可がfalseになっている事を確認する</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"40%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse050.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom style={{fontWeight:"bold"}} id="530">5.3.参加用mosaicの配布</Typography>
                <Typography variant="caption" component="div" gutterBottom>NCFTを配布の場合、数が多いと送付が手間なのでSymbolウォレットのアグリゲートトランザクションをお勧めします。</Typography>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>一番左下の『Aggregate』タブをクリック</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse052.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>Toに送りたいアカウントアドレス、Mosaicで送付するMosaicIDを選択し『save』をクリック。これをアカウントの数だけ繰り返す</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse053.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>右の欄に作成したトランザクションが追加されているので、一つずつ開く</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse054.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>Mosaicの数量を1に、MessageにTokenLiveの内容を入力して『save』をクリック。これをトランザクションの数だけ繰り返す</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse055.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>すべてのトランザクションの修正が終わったら、右下の『Send aggregate』をクリック</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse056.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>最終確認では特に、MosaicのIDと数量が1になっている事。Messageに漏れがない事を確認し送信する</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse057.png" alt="" />
                </Box>
                <br/>
                <Alert style={{fontSize:"11px"}} severity="warning">送信が完了してもHistoryに表示されない場合、いずれかのアカウントアドレスのスペルが間違っているか、アドレス制限などをかけている可能性があります。その場合は一つずつ送りどのアカウントアドレスが送れないか調べる必要があります。</Alert>
                <br/>
                <Typography variant="caption" component="div" gutterBottom style={{fontWeight:"bold"}} id="540">5.1.ルームの予約</Typography>
                <Typography variant="caption" component="div" gutterBottom>ルームの選択から『新規ルーム予約』を選択し、『新規予約』をクリック</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse027.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>イベントタイトル、ライブ開始時間、予約時間、配信モードを設定します</Typography>
                <br/>
                <Alert style={{fontSize:"11px"}} severity="info">配信モードについて、通常は「中画質マルチ配信モード」で問題ありません。細かい文字や描写の配信が必要な場合は高画質にする必要があります。</Alert>
                <br/>
                <Alert style={{fontSize:"11px"}} severity="warning">「高画質シングル配信モード」ではゲスト招待を行う事ができません</Alert>
                <br/>
                <Alert style={{fontSize:"11px"}} severity="warning">「高画質マルチ配信モード」では料金が他のモードの２倍になります</Alert>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse028.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>COMSAの場合は、この部分でmosaicIDを調べる事ができます。</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse073.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>参加可能なmosaicを指定し、左の鍵アイコンをクリックしてmosaicのチェックを行なって下さい</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse030.png" alt="" />
                </Box>
                <br/>
                <Alert style={{fontSize:"11px"}} severity="warning">存在しないmosaicや、参加用mosaicの条件に合わないモザイクの場合エラーが発生し登録する事ができません</Alert>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>mosaicのチェックが完了すると、参加人数を指定する事ができます。</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse031.png" alt="" />
                </Box>
                <br/>
                <Alert style={{fontSize:"11px"}} severity="info">指定した参加者以上の人数がルームに入る事はできません。この人数はいつでも（ライブが始まった後でも）変更する事ができるため予約時点では必ず参加する人数を指定することをお勧めします。</Alert>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>mosaicの総発行量、現在の保有アカウントを確認したい場合は左の人型アイコンをクリックして下さい</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse032.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>赤字で予約に必要な手数料（xym）が表示されます。特に問題なければ『予約確認へ』をクリックします</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse031.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>予約内容を確認し、『支払いを行う』をクリック</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse033.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>この時点ではまだ予約は完了していません</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse035.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>Chromeの拡張機能SSSにログインして署名内容を確認後、署名を行います</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"70%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse036.png" alt="" />
                </Box>
                <br/>
                <Alert style={{fontSize:"11px"}} severity="warning">SSSの署名は60秒以内に行わないと無効になります。</Alert>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>署名後、30秒以上そのまま待ちます</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse037.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>情報がブロックチェーンに承認されると予約完了になります。『トップページへ移動する』をクリックして下さい</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse038.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>トップページに自分が予約したルームがあることを確認して下さい</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse039.png" alt="" />
                </Box>
                <br/>
                <br/>
                <Typography variant="body1" component="div" gutterBottom id="600">6.ルームの予約変更</Typography>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>ルーム予約後に、開始時間や参加人数などを変更する事ができます</Typography>
                <Typography variant="caption" component="div" gutterBottom>変更したいルームを選択します。</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse040.png" alt="" />
                </Box>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>各種設定を変更できます。</Typography>
                <br/>
                <Box display={"flex"} justifyContent={"center"}>
                    <img style={{maxWidth:"90%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="howtouse041.png" alt="" />
                </Box>
                <br/>
                <Alert style={{fontSize:"11px"}} severity="info">ライブ開始後は、参加人数と予約時間のみ変更する事ができます</Alert>
                <br/>
                <Alert style={{fontSize:"11px"}} severity="warning">追加の変更には差分のxymが必要になります。予約人数や予約時間などを減らしたとしても一度支払ったxymを返却する事はできませんのでご注意ください</Alert>
                <br/>
                <Typography variant="caption" component="div" gutterBottom>変更内容を確認し、予約変更手続きを進めて下さい</Typography>
                <br/>
                <br/>
                <Typography variant="body1" component="div" gutterBottom id="700">7.注意事項</Typography>
                <br/>
                <Alert style={{fontSize:"11px"}} severity="warning">ルーム内で、固まる、表示がおかしくなるなど不具合が出た場合ブラウザのリロードをする事で改善する事があります。</Alert>
                <br/>
                <Alert style={{fontSize:"11px"}} severity="warning">配信者は事前にデモルームで配信のシミュレーションを行い、カメラ、マイクが正常に認識されるか確認して下さい。</Alert>
                <br/><br/><br/><br/><br/><br/><br/><br/><br/>
            </Box>
        </Box>
    )
}
