import React , { useEffect, useState } from 'react'
import {useGlobalState, useGlobalMutation} from '../../utils/container'
import PropTypes from 'prop-types'
import {makeStyles} from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Box from '@material-ui/core/Box'
import {Link} from 'react-router-dom'
import { EncryptedMessage, PublicAccount } from 'symbol-sdk'
import Button from '@material-ui/core/Button'
import useRouter from '../../utils/use-router'
import { Typography } from '@material-ui/core'
import { Bars } from 'react-loader-spinner'
import {useSymbol} from '../../hooks/useSymbol'
import axios from 'axios'

LoginSSSCard.propTypes = {
    name: PropTypes.string,
    resolution: PropTypes.string,
    cameraDevice: PropTypes.string,
    microphoneDevice: PropTypes.string,
    video: PropTypes.bool,
    audio: PropTypes.bool
}

const useStyles = makeStyles((theme) => ({
    menuTitle: {
        color: '#333333',
        textAlign: 'center',
        fontSize: 'h6.fontSize',
        position: 'relative',
        top: '7px'
    },
    menu: {
        margin: '0.25rem 0',
        position: 'relative',
        height: '39px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    line: {
        marginTop: '0.2rem',
        marginBottom: '0.5rem',
        borderBottom: '1px solid #EAEAEA'
    },
    button: {
        lineHeight: '21px',
        color: 'rgba(255,255,255,1)',
        fontSize: '17px',
        textTransform: 'none',
        height: '44px',
        width: '300px',
        '&:hover': {
            backgroundColor: '#82C2FF'
        },
        margin: theme.spacing(1),
        marginTop: '33px',
        backgroundColor: '#44a2fc',
        borderRadius: '30px'
    },
}))


export default function LoginSSSCard() {
    const classes = useStyles()
    const routerCtx = useRouter()
    const stateCtx = useGlobalState()
    const mutationCtx = useGlobalMutation()

    const [isChrome, setIsChrome] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [installed, setInstalled] = useState(true)
    const [account, setAccount] = useState(null)
    const [status, setStatus] = useState("")

    const {
        checkNode,checkedNode,nodeLoading
    } = useSymbol()

    useEffect(() => {
        const agent = window.navigator.userAgent.toLowerCase()
        if (agent.indexOf("chrome") != -1) {
            setIsChrome(true)
        }
        setTimeout(() => {
        checkNode()
        try {
            window.requestSSS()
            console.log('installed')
            const acc = PublicAccount.createFromPublicKey(
                window.SSS.activePublicKey,
                stateCtx.config.networkType
            )
            setAccount(acc)
        } catch (e) {
            console.log('not installed')
            setInstalled(false)
        }
        setIsLoading(false)
        }, 2000) // SSSのプログラムがwindowに挿入されるよりも後に実行するために遅らせる
    }, [])

    const buttonClick = () => {
        alert("アカウントを証明するためにSSSで署名を行って下さい")
        setStatus("SSS")
        const NODE = stateCtx.config.nodeUrl
        window.SSS.getActiveAccountToken(stateCtx.config.adminPublicKey)
        .then((payload)=>{
            setStatus("")
            setIsLoading(true)
            console.log(payload)
            const enMsg = new EncryptedMessage(payload,account)
            console.log(enMsg)
            axios.get("https://us-central1-tokenlive-b98bd.cloudfunctions.net/accountAuthentication",{
                params: {
                  enMsg: {
                    enMsg: enMsg
                  },
                  pubkey: {
                    pubkey: window.SSS.activePublicKey
                  },
                  node: {
                    node: checkedNode
                  },
                }
            }).then((res)=>{
                console.log(res.data.signerAddress)
                if(res.data.signerAddress!==""){
                    mutationCtx.updateConfig({
                        userAddress:res.data.signerAddress,
                        userPublicKey:account.publicKey,
                        nodeUrl:checkedNode,
                        loginType:"SSS"
                        })
                        routerCtx.history.push("/index");
                }else{
                    setStatus("ERROR")
                    console.log("不正なアクセスと判断されました")
                }
            }).catch(()=>{
                setStatus("ERROR")
                console.log("不正なアクセスと判断されました")
            }).finally(()=>{
                setIsLoading(false)
            })
        })
    }

    return (
        <Box flex="1" display="flex" flexDirection="column">
            <Link to="/" className="back-btn"/>
            <Box
                display="flex"
                flex="1"
                flexDirection="column"
                padding="0 1rem"
                justifyContent="flex-start"
            >
                <FormControl className={classes.menu}>
                    <span className={classes.menuTitle}>SSS Extensionでログイン</span>
                </FormControl>
                <div className={classes.line}></div>
                {(isLoading)?<>
                    <Box
                        flex="1"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <Bars height={50} width={50} color={"#44A2FC"}></Bars>
                    </Box>
                </>:
                (nodeLoading)?<>
                    <Box
                        flex="1"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        flexDirection="column"
                    >
                        <Bars height={50} width={50} color={"#44A2FC"}></Bars>
                    </Box>
                </>:
                (status==="NOACCOUNT")?
                <Box>
                    <Typography variant="caption" component="div" gutterBottom>
                        {`SSSにてアカウントが登録されておりません`}
                    </Typography>
                    <Typography variant="caption" component="div" gutterBottom>
                        {`メインネットのアカウントを登録後、再度ブラウザをリロードして下さい`}
                    </Typography>
                </Box>:
                (status==="SSS")?
                <Box>
                    <Typography variant="caption" component="div" gutterBottom className='info-text'>
                        {`アカウントを証明するためにSSSで署名を行います`}
                    </Typography>
                    <Typography variant="caption" component="div" gutterBottom className='info-text'>
                        {`右上のChromeの拡張機能からSSS Extensionを開いて署名を行って下さい`}
                    </Typography>
                </Box>:
                (status==="ERROR")?
                <Box>
                    <Typography variant="caption" component="div" gutterBottom>
                        {`不正な方法でログインしようとしています`}
                    </Typography>
                    <Typography variant="caption" component="div" gutterBottom>
                        {`SSS Extensionを利用した正規の方法でログインして下さい`}
                    </Typography>
                </Box>:
                !isChrome?
                <Box>
                    <Typography variant="caption" component="div" gutterBottom>
                        {`現在Chrome以外のブラウザをお使いです。`}
                    </Typography>
                    <Typography variant="caption" component="div" gutterBottom>
                        {`TokenLiveを使うにはChromeブラウザが必要になります。`}
                    </Typography>
                </Box>:
                installed?
                <Box display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
                    <Typography variant="body1" component="div" gutterBottom>
                        {`TokenLiveを利用可能です`}
                    </Typography>
                    <FormControl className={classes.grid}>
                        <Button
                            onClick={buttonClick}
                            variant="contained"
                            color="primary"
                            className={classes.button}
                        >
                            Symbolアカウントでログインする
                        </Button>
                    </FormControl>
                </Box>
                :
                <Box>
                    <Typography variant="caption" component="div" gutterBottom>
                        {`SSSが未インストールまたは、SSSが有効になっていません。`}
                    </Typography>
                    <br />
                    <Typography variant="caption" component="div" gutterBottom >
                    <a
                        href="https://chrome.google.com/webstore/detail/sss-extension/llildiojemakefgnhhkmiiffonembcan"
                        target="_blank"
                        rel="noreferrer">
                        1. ChromeでSSS Extensionをインストールする
                    </a>
                    </Typography>
                    <br />
                    <Typography variant="caption" component="div" gutterBottom>
                        {`2.SymbolメインネットのアカウントをSSSに登録する`}
                    </Typography>
                    <br />
                    <Typography variant="caption" component="div" gutterBottom>
                        {`3.右クリック（もしくは同時タップ）して「SSSと連携する」を選択する`}
                    </Typography>
                    <br />
                    <Typography variant="caption" component="div" gutterBottom>
                        {`4.ブラウザをリロードする`}
                    </Typography>
                </Box>
                }
            </Box>
        </Box>
    )
}
