import React, { useEffect, useMemo ,useState} from 'react'
import { Convert } from 'symbol-sdk'
import useRouter from '../../utils/use-router'
import {useGlobalState, useGlobalMutation} from '../../utils/container'
import {useSymbol} from '../../hooks/useSymbol'
import {useFunction} from '../../hooks/useFunction'
import {makeStyles} from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Popover from '@material-ui/core/Popover'
import { Card, CardContent, Typography } from '@mui/material'
import { isMobile } from "react-device-detect"
import { Link } from 'react-router-dom'
import Carousel from 'react-material-ui-carousel';
import ArrowBackIosSharpIcon from '@mui/icons-material/ArrowBackIosSharp';//左矢印アイコン
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';//右矢印アイコン
import {useNft} from '../../hooks/useNft'
import { Bars } from 'react-loader-spinner'

const Icon = 'lightskyblue';
const ActiveIcon = 'midnightblue';

export default function LoginCard() {
    const routerCtx = useRouter()
    const stateCtx = useGlobalState()
    const mutationCtx = useGlobalMutation()
    const {
            checkNode, // retunで取得したため
            getMetasInfo,symbolMetasInfo,
            getSymbolMosaicsBundlenames, // retunで取得したため
        } = useSymbol()

    const {
            unixTimeToString,
            copyTextToClipboard
        } = useFunction()

    const {getComsaNft,getDriveNft} = useNft()

    useEffect(async() => {
        await checkNode().then((node)=>{
            mutationCtx.updateConfig({
                nodeUrl:node
            })
        })
    }, [])

    //Popover関連
    const [copyAnchorEl, setCopyAnchorEl] = useState(null);
    const copyPopoverOpen = Boolean(copyAnchorEl);
    const copyPopoverid = copyPopoverOpen ? 'simple-popper' : undefined;

    const copyClick = (event) => {
        setCopyAnchorEl(copyAnchorEl ? null : event.currentTarget);
        copyTextToClipboard(event.currentTarget.id);
    }
    const copyClose = () => {
        setCopyAnchorEl(null);
    }
    
    const [copy2AnchorEl, setCopy2AnchorEl] = useState(null);
    const copy2PopoverOpen = Boolean(copy2AnchorEl);
    const copy2Popoverid = copy2PopoverOpen ? 'simple-popper' : undefined;

    const copy2Click = (event) => {
        setCopy2AnchorEl(copy2AnchorEl ? null : event.currentTarget);
        copyTextToClipboard(event.currentTarget.id);
    }
    const copy2Close = () => {
        setCopy2AnchorEl(null);
    }

    const [menuBoxList,setMenuBoxList] = useState([])
    const [myRoomKeys,setMyRoomKeys] = useState([])
    const [isSymbolMetasInfo,setIsSymbolMetasInfo] = useState(false)
    const [isSymbolSerialsInfo,setIsSymbolSerialsInfo] = useState(false)

    useEffect(async () => {
        if(!isSymbolMetasInfo && stateCtx.config.nodeUrl != ""){
            getMetasInfo().then(()=>{
                setIsSymbolMetasInfo(true)
                console.log(symbolMetasInfo)
            })
        }
        if(isSymbolMetasInfo && !isSymbolSerialsInfo){
            let newMyRoomKeys = []
            let newBoxItemList = []

            for(let i = 0; i < symbolMetasInfo.length; i++){
                await getSymbolMosaicsBundlenames(symbolMetasInfo[i].data.mosaic).then((bundle)=>{
                    newMyRoomKeys = [...newMyRoomKeys,[symbolMetasInfo[i],bundle]]
                })

            }
            for(let i = 0; i < newMyRoomKeys.length; i++){
                //ここでNFT取得処理をいれる
                let image = stateCtx.config.nft
                console.log(newMyRoomKeys[i][1][`${newMyRoomKeys[i][0].data.mosaic[0]}`].version)
                console.log(newMyRoomKeys[i][1][`${newMyRoomKeys[i][0].data.mosaic[0]}`].thumbnail)
                newBoxItemList = [...newBoxItemList,
                    <Box key={`box1-${i}`}>
                        <Box key={`box1-image-${i}`} display="flex" flexDirection="row" alignContent="center" justifyContent="center">
                            {(newMyRoomKeys[i][1][`${newMyRoomKeys[i][0].data.mosaic[0]}`].version === "")?
                            <img src={`${image}`} style={{width:"auto",height:"auto",maxWidth:"150px",maxHeight:"150px"}} alt="thumbnail"/>
                            :(newMyRoomKeys[i][1][`${newMyRoomKeys[i][0].data.mosaic[0]}`].version === "nftdrive")?
                            <img src={`${await getDriveNft(`${newMyRoomKeys[i][0].data.mosaic[0]}`)}`} style={{width:"auto",height:"auto",maxWidth:"150px",maxHeight:"150px"}} alt="thumbnail"/>
                            :<img src={`${await getComsaNft(newMyRoomKeys[i][1][`${newMyRoomKeys[i][0].data.mosaic[0]}`].thumbnail,newMyRoomKeys[i][1][`${newMyRoomKeys[i][0].data.mosaic[0]}`].version)}`} style={{width:"auto",height:"auto",maxWidth:"150px",maxHeight:"150px"}} alt="thumbnail" />
                            }
                        </Box>
                        <Box key={`box1-time-${i}`} display="flex" flexDirection="row" alignContent="center" justifyContent="center" mt={1}>
                            <Typography key={`text2-${i}`} variant="caption" component="div" gutterBottom style={{fontSize:"10px"}}>{`📢　開催予定　`}</Typography>
                            <Typography key={`text2-${i}`} variant="caption" component="div" gutterBottom style={{fontSize:"12px"}}>{`${unixTimeToString(newMyRoomKeys[i][0].data.st)} ~`}</Typography>
                        </Box>
                        <Box key={`box1-title-${i}`} display="flex" flexDirection="row" alignContent="center" justifyContent="center">
                            <Typography key={`text2-${i}`} variant="caption" component="div" gutterBottom style={{fontSize:"10px"}}>{`🎤　ライブ名　`}</Typography>
                            <Typography key={`text1-${i}`} variant="caption" component="div" gutterBottom style={{fontSize:"11px"}}>{`${Convert.decodeHex(newMyRoomKeys[i][0].data.title)?? ""}`}</Typography>
                        </Box>
                        <Box key={`box1-title-${i}`} display="flex" flexDirection="row" alignContent="center" justifyContent="center" mt={1}>
                            <Typography key={`text1-${i}`} variant="caption" component="div" gutterBottom style={{fontSize:"10px"}}>{`🔑　参加に必要なToken`}</Typography>
                        </Box>
                        <Box id={newMyRoomKeys[i][0].data.mosaic.join(',')} display="flex" flexDirection="row" alignContent="center" justifyContent="center" mt={1}
                            onClick={copy2Click}
                        >
                        <Typography variant="caption" component="div" gutterBottom style={{fontSize:"10px"}}>
                        {
                            Object.keys(newMyRoomKeys[i][1]).map((key)=>(
                                <>
                                    {(newMyRoomKeys[i][1][key]["version"].indexOf('nft')>0)?`🎫`:(newMyRoomKeys[i][1][key]["version"].indexOf('ncft')>0)?`🎟`:`🆔`}
                                    {(newMyRoomKeys[i][1][key]["title"]!=="")?`${newMyRoomKeys[i][1][key]["title"]}`:key}
                                    {(newMyRoomKeys[i][1][key]["name"]!=="")?`　by ${newMyRoomKeys[i][1][key]["name"]}　`:"　"}
                                </>
                            ))
                        }
                        </Typography>
                        </Box>

                        <Box key={`box1-title-${i}`} display="flex" flexDirection="row" alignContent="center" justifyContent="center" mt={1}>
                            <Typography key={`text2-${i}`} variant="caption" component="div" gutterBottom style={{fontSize:"10px"}}>{`👤　主催者アドレス`}</Typography>
                        </Box>
                        <Box id={newMyRoomKeys[i][0].data.owner} key={`box1-title-${i}`} display="flex" flexDirection="row" alignContent="center" justifyContent="center"
                            onClick={copyClick}
                        >
                            <Typography key={`text1-${i}`} variant="caption" component="div" gutterBottom style={{fontSize:"10px"}}>{`${newMyRoomKeys[i][0].data.owner}`}</Typography>
                        </Box>
                    </Box>
                ]
            }

            setMyRoomKeys(newMyRoomKeys)
            setMenuBoxList(newBoxItemList)
            console.log(symbolMetasInfo)
            console.log(newMyRoomKeys)

            setIsSymbolSerialsInfo(true)
        }
    },[symbolMetasInfo,isSymbolSerialsInfo,stateCtx])

    return (
        <>
            {(isSymbolSerialsInfo)?
            <>
            <Popover
                id={copyPopoverid}
                open={copyPopoverOpen}
                onClose={copyClose}
                anchorEl={copyAnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Typography variant="caption" component="div" gutterBottom>主催者アドレスをコピーしました</Typography>
            </Popover>

            <Popover
                id={copy2Popoverid}
                open={copy2PopoverOpen}
                onClose={copy2Close}
                anchorEl={copy2AnchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Typography variant="caption" component="div" gutterBottom>MosaicID一覧をコピーしました</Typography>
            </Popover>

            {(!isMobile)?
            <Box flex="1" display="flex" flexDirection="column">
                <Box display="flex" justifyContent="center" flexDirection="row" alignContent="center">
                    <Box display="flex" justifyContent="center" flexDirection="column" alignContent="center">
                        <Box width={300} mt={3}>
                            <Carousel
                                NextIcon={<ArrowForwardIosSharpIcon/>} //矢印アイコンを別のアイコンに変更
                                PrevIcon={<ArrowBackIosSharpIcon/>} //矢印アイコンを別のアイコンに変更
                                autoPlay = {true} //自動でCarouselを動かすかどうか(true or false)
                                //stopAutoPlayOnHover = {true} Carouselの上にマウスを置いている間、自動スクロールを継続するかどうか
                                // interval = {4000} //自動でCarouselを動かす時間の間隔(ミリ秒単位)
                                //animation = {fade} (fade or slide) Carouselのアニメーションの種類を変更
                                // duration = {500} //アニメーションの長さを定義
                                //swipe = {true} スワイプで動かせるかどうか
                                indicators = {true}
                                //navButtonsAlwaysVisible = {true} //常に矢印アイコンを表示するかどうか
                                navButtonsAlwaysInvisible = {true} //常に矢印アイコンを非表示にするかどうか
                                //cycleNavigation = {true} //最後のスライドから「次へ」の矢印アイコンを押した時に最初にスライドに動かせるようにするかどうか
                                //fullHeightHover = {true} //次/前のボタンがItem要素の高さ全体をカバーし、ホバー時にボタンを表示するかどうか

                                indicatorContainerProps={{
                                style: {
                                margin: "3px 0px 0px 0px"
                                }
                                }}
                                indicatorIconButtonProps={{//アクティブでない下の丸いアイコンの設定
                                style: {
                                    padding: '10px',//位置調整
                                    color: Icon,
                                }
                                }}
                                activeIndicatorIconButtonProps={{//アクティブな下の丸いアイコンの設定
                                style: {
                                    color : ActiveIcon,
                                }
                                }}
                            >
                                {
                                    menuBoxList.map((item)=>item)
                                }
                            </Carousel>
                        </Box>
                        <Box width={300} mt={2}>
                            <Card
                                onClick={()=>{
                                    routerCtx.history.push("/loginSSS")
                                }}
                            >
                                <CardContent>
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <img style={{maxWidth:"15%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="SSS.png" alt="" />
                                    <Typography variant="body2" component="div" gutterBottom style={{fontWeight:"bold"}} >　SSS Extensionでログイン</Typography>
                                </Box>
                                </CardContent>
                            </Card>
                        </Box>
                        <Box width={300} mt={2}>
                            <Card
                                onClick={()=>{
                                    routerCtx.history.push("/loginAccount")
                                }}
                            >
                                <CardContent>
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <img style={{maxWidth:"15%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="symbol.png" alt="" />
                                    <Typography variant="body2" component="div" gutterBottom style={{fontWeight:"bold"}} >　アドレスのみでログイン</Typography>
                                </Box>
                                </CardContent>
                            </Card>
                        </Box>
                    </Box>
                </Box>
            </Box>
            :
            <Box flex="1" display="flex" flexDirection="column" justifyContent={"center"} alignItems={"center"}>
                <Box display={"flex"} justifyContent={"center"} mb={1}>
                    <img style={{height:"50px"}} src="logo.png" alt="" />
                </Box>
                <Box width={260}>
                    <Carousel
                        NextIcon={<ArrowForwardIosSharpIcon/>} //矢印アイコンを別のアイコンに変更
                        PrevIcon={<ArrowBackIosSharpIcon/>} //矢印アイコンを別のアイコンに変更
                        autoPlay = {true} //自動でCarouselを動かすかどうか(true or false)
                        //stopAutoPlayOnHover = {true} Carouselの上にマウスを置いている間、自動スクロールを継続するかどうか
                        // interval = {4000} //自動でCarouselを動かす時間の間隔(ミリ秒単位)
                        //animation = {fade} (fade or slide) Carouselのアニメーションの種類を変更
                        // duration = {500} //アニメーションの長さを定義
                        //swipe = {true} スワイプで動かせるかどうか
                        indicators = {true}
                        //navButtonsAlwaysVisible = {true} //常に矢印アイコンを表示するかどうか
                        navButtonsAlwaysInvisible = {true} //常に矢印アイコンを非表示にするかどうか
                        //cycleNavigation = {true} //最後のスライドから「次へ」の矢印アイコンを押した時に最初にスライドに動かせるようにするかどうか
                        //fullHeightHover = {true} //次/前のボタンがItem要素の高さ全体をカバーし、ホバー時にボタンを表示するかどうか

                        indicatorContainerProps={{
                        style: {
                        margin: "3px 0px 0px 0px"
                        }
                        }}
                        indicatorIconButtonProps={{//アクティブでない下の丸いアイコンの設定
                        style: {
                            padding: '10px',//位置調整
                            color: Icon,
                        }
                        }}
                        activeIndicatorIconButtonProps={{//アクティブな下の丸いアイコンの設定
                        style: {
                            color : ActiveIcon,
                        }
                        }}
                    >
                        {
                            menuBoxList.map((item)=>item)
                        }
                    </Carousel>
                </Box>
                <Box display={"flex"} justifyContent={"center"} height={"50px"} width={"200px"} marginTop={1} marginBottom={2}>
                    <Card
                        onClick={()=>{
                            routerCtx.history.push("/loginAccount")
                        }}
                    >
                        <CardContent>
                        <Box display="flex" flexDirection="row" alignItems="center" justifyContent={"space-around"}>
                            <img style={{maxWidth:"15%" , maxHeight:"100%" , width:"auto" , height:"auto"}} src="symbol.png" alt="" />
                            <Typography variant="body2" component="div" gutterBottom style={{fontWeight:"bold"}} >ログイン</Typography>
                            <Typography variant="body2" component="div" gutterBottom style={{fontWeight:"bold"}} ></Typography>
                        </Box>
                        </CardContent>
                    </Card>
                </Box>
                {/* <Box flex="1" display="flex" flexDirection="column" justifyContent={"center"} marginLeft={5} marginRight={5} marginBottom={3}>
                    <Typography variant="caption" component="div" style={{ color: 'gray' ,fontSize:10}} gutterBottom>
                        {`TokenLiveとは、Symbolのmosaicを活用したライブ配信サービスです。配信者が指定した特定のunique（NFT）、もしくはbundle（譲渡不可なFT）を所有している人のみが参加できます`}
                    </Typography>
                </Box> */}
                <Box display={"flex"} justifyContent={"space-around" }width={"250px"} >
                    <Link to="/howtouse" style={{ textDecoration: 'none' }}>

                            <Typography variant="caption" component="div" style={{ color: 'gray' }} gutterBottom>
                                {`TokenLiveの使い方`}
                            </Typography>
                    </Link>

                    <Link to="/terms" style={{ textDecoration: 'none' }}>

                            <Typography variant="caption" component="div" style={{ color: 'gray' }} gutterBottom>
                                {`利用規約`}
                            </Typography>
                    </Link>
                </Box>

            </Box>
            }
            </>
            :<>
            <Box
                flex="1"
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
            >
                <Bars height={50} width={50} color={"#44A2FC"}></Bars>
            </Box>
        </>
        }

        </>
    )
}
