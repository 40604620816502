import React, {useEffect} from 'react'
import {useGlobalState, useGlobalMutation} from '../utils/container'
import {makeStyles} from '@material-ui/core/styles'
import {Box, Container, Typography} from '@material-ui/core'
import IndexCard from './index/card'
import { isMobile } from "react-device-detect"

const useStyles = makeStyles(() => ({
    container: {
        height: '100%',
        width: '100%',
        minWidth: 800,
        minHeight: 600,
        boxSizing: 'content-box',
        display: 'flex',
        justifyContent: 'center'
    },
    mobaileContainer: {
        height: '100vh',
        width: '100wh',
        display: 'flex',
        justifyContent: 'center'
    }

}))

const Index = () => {
    const stateCtx = useGlobalState()
    const mutationCtx = useGlobalMutation()
    const classes = useStyles()

    useEffect(() => {
        if (stateCtx.loading === true) {
            mutationCtx.stopLoading()
        }
    }, [stateCtx.loading, mutationCtx])

    return (
        <Box display={"flex"} justifyContent={"center"} flexDirection={"column"} alignItems={"center"} flex={1}>
        <Container maxWidth="sm" className={(!isMobile)?classes.container:classes.mobaileContainer}>
            <IndexCard/>
        </Container>
        <Typography variant="body2" component="div" style={{ color: 'white' }} gutterBottom>
            <a href='https://magokoroai.com/' style={{ textDecoration: 'none'}}>magokoroai.inc © 2023</a>
        </Typography>
        </Box>
    
    )
}

export default Index
