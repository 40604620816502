import AgoraRTC from 'agora-rtc-sdk-ng'
import EventEmitter from 'events'
import axios from 'axios'

const appID = process.env.REACT_APP_AGORA_APP_ID
console.log(
    'agora sdk version: ' +
    AgoraRTC.VERSION +
    ' compatible: ' +
    AgoraRTC.checkSystemRequirements()
)

//アゴラへのログ送付用コマンド
AgoraRTC.enableLogUpload();

export default class RTCClient {

    constructor() {
        this._client = null
        this._joined = false
        this._leave = false
        this.mLocalAudioTrack = null
        this.mLocalVideoTrack = null
        this._uid = 0
        this._eventBus = new EventEmitter()
        this._created = false
    }

    createClient(data) {
        if (this._client != null) {
            return this._client
        }

        const config = {
            mode: data?.mode ? data.mode : 'live',
            codec: data?.codec ? data.codec : 'vp8'
        }
        console.debug('createClient() mode: ' + config.mode + ' codec: ' + config.codec)
        this._client = AgoraRTC.createClient(config)
        this._created = true
        return this._client
    }

    async setEncryptionConfig() {
        // Declare a function to convert Base64 to Uint8Array.
        function base64ToUint8Array(base64Str) {
            const raw = window.atob(base64Str);
            const result = new Uint8Array(new ArrayBuffer(raw.length));
            for (let i = 0; i < raw.length; i += 1) {
            result[i] = raw.charCodeAt(i);
            }
            return result;
        }

        // Declare a function to convert Hex to ASCII.
        function hex2ascii(hexx) {
            const hex = hexx.toString();//force conversion
            let str = '';
            for (let i = 0; i < hex.length; i += 2)
                str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
            return str;
        }

        async function getSecretAndSalt() {
            let secret = ""
            let salt = ""
            await axios.get("https://us-central1-tokenlive-b98bd.cloudfunctions.net/getSecretAndSaltFromServer").then((res)=>{
                secret = res.data.secret
                salt = res.data.salt
            })
            salt = base64ToUint8Array(salt);
            secret = hex2ascii(secret);
            return [secret, salt];
        }

        let [secret, salt] = await getSecretAndSalt();

        // Set the encryption mode as aes-256-gcm2, and pass in secret and salt
        this._client.setEncryptionConfig("aes-256-gcm2", secret, salt);
    }

    destroy() {
        console.debug('destroy()')
        this._created = false
        this._client = null
    }

    on(evt, callback) {
        this._client.on(evt, callback)
    }

    setClientRole(role,level) {
        console.debug('setClientRole() role: ' + role)
        if(role==="host"){
            this._client.setClientRole(role,)
        }else{
            this._client.setClientRole(role,{level})
        }
    }

    startLive(microphoneId, cameraId, quality) {
        return new Promise((resolve, reject) => {
            console.debug('startLive()')
            AgoraRTC.createMicrophoneAudioTrack({microphoneId: microphoneId})
            .then((track) => {
                this.mLocalAudioTrack = track
                this.audioMixingTrack = null
                if(cameraId!==""){
                    AgoraRTC.createCameraVideoTrack({cameraId: cameraId ,encoderConfig:(quality===0)?"360p_11":(quality===1)?"720p_2":"1080p_2"})
                    .then((track) => {
                        this.mLocalVideoTrack = track
                        AgoraRTC.createBufferSourceAudioTrack({ source: "no_sound.mp3"})
                        .then((res)=>{
                            this.audioEffectTrack = res
                            this._client.publish(this.audioEffectTrack)
                            this.audioEffectTrack.play();
                            this.audioEffectTrack.startProcessAudioBuffer({ loop: true });
                            resolve()
                        })
                    })
                }else{
                    AgoraRTC.createBufferSourceAudioTrack({ source: "no_sound.mp3"})
                    .then((res)=>{
                        this.audioEffectTrack = res
                        this._client.publish(this.audioEffectTrack)
                        this.audioEffectTrack.play();
                        this.audioEffectTrack.startProcessAudioBuffer({ loop: true });
                        resolve()
                    })
                }
            })
        })
    }

    startLiveDemo(microphoneId, cameraId, quality) {
        return new Promise((resolve, reject) => {
            console.debug('startLive()')
            AgoraRTC.createMicrophoneAudioTrack({microphoneId: microphoneId})
            .then((track) => {
                this.mLocalAudioTrack = track
                this.audioMixingTrack = null
                if(cameraId!==""){
                    AgoraRTC.createCameraVideoTrack({cameraId: cameraId ,encoderConfig:(quality===0)?"360p_11":(quality===1)?"720p_2":"1080p_2"})
                    .then((track) => {
                        this.mLocalVideoTrack = track
                        resolve()
                    })
                }else{
                    resolve()
                }
            })
        })
    }

    startLiveOnlyShareScrren(cameraId, quality) {
        return new Promise((resolve, reject) => {
            console.debug('startLive()')

            if(cameraId!==""){
                AgoraRTC.createCameraVideoTrack({cameraId: cameraId ,encoderConfig:(quality===0)?"360p_11":(quality===1)?"720p_2":"1080p_2"})
                .then((track) => {
                    this.mLocalVideoTrack = track
                    resolve()
                })
            }else{
                resolve()
            }
        })
    }

    stopLive() {
        console.debug('stopLive()')

        if (this.audioEffectTrack) {
            this._client.unpublish(this.audioEffectTrack)

            this.audioEffectTrack.stop()
            this.audioEffectTrack.close()
            this.audioEffectTrack = null
        }

        if (this.audioMixingTrack) {
            this._client.unpublish(this.audioMixingTrack)

            this.audioMixingTrack.stop()
            this.audioMixingTrack.close()
            this.audioMixingTrack = null
        }

        if (this.mLocalAudioTrack) {
            this._client.unpublish(this.mLocalAudioTrack)

            this.mLocalAudioTrack.stop()
            this.mLocalAudioTrack.close()
            this.mLocalAudioTrack = null
        }

        if (this.mLocalVideoTrack) {
            this._client.unpublish(this.mLocalVideoTrack)

            this.mLocalVideoTrack.stop()
            this.mLocalVideoTrack.close()
            this.mLocalVideoTrack = null
        }
    }

    stopLiveOnlyShareScrren() {
        console.debug('stopLive()')
        if (this.mLocalVideoTrack) {
            this._client.unpublish(this.mLocalVideoTrack)

            this.mLocalVideoTrack.stop()
            this.mLocalVideoTrack.close()
            this.mLocalVideoTrack = null
        }
    }

    stopLiveOnlyShareScrrenDemo() {
        console.debug('stopLive()')
        if (this.mLocalVideoTrack) {
            this.mLocalVideoTrack.stop()
            this.mLocalVideoTrack.close()
            this.mLocalVideoTrack = null
        }
    }

    async startShareScrren(quality) {
        [this.mLocalVideoTrack] = await Promise.all([
            AgoraRTC.createScreenVideoTrack({encoderConfig:(quality===0)?{width: 640,height: 360}:(quality===1)?"720p_2":"1080p_2"},"auto")
        ])

        if (this.mLocalVideoTrack) {
            this._client.publish(this.mLocalVideoTrack)
        }
    }

    async startShareScrrenDemo(quality) {
        [this.mLocalVideoTrack] = await Promise.all([
            AgoraRTC.createScreenVideoTrack({encoderConfig:(quality===0)?{width: 640,height: 360}:(quality===1)?"720p_2":"1080p_2"},"auto")
        ])
    }

    stopShareScrren() {
        console.debug('stopShareScrren()')
        if (this.mLocalVideoTrack) {
            this._client.unpublish(this.mLocalVideoTrack)
            if(this.mLocalVideoTrack instanceof Array){
                this.mLocalVideoTrack[0].stop()
                this.mLocalVideoTrack[0].close()
                this.mLocalVideoTrack[1].stop()
                this.mLocalVideoTrack[1].close()
                this.mLocalVideoTrack = null
            }else{
                this.mLocalVideoTrack.stop()
                this.mLocalVideoTrack.close()
                this.mLocalVideoTrack = null
            }
        }
    }

    stopShareScrrenDemo() {
        console.debug('stopShareScrren()')
        if (this.mLocalVideoTrack) {
            if(this.mLocalVideoTrack instanceof Array){
                this.mLocalVideoTrack[0].stop()
                this.mLocalVideoTrack[0].close()
                this.mLocalVideoTrack[1].stop()
                this.mLocalVideoTrack[1].close()
                this.mLocalVideoTrack = null
            }else{
                this.mLocalVideoTrack.stop()
                this.mLocalVideoTrack.close()
                this.mLocalVideoTrack = null
            }
        }
    }

    subscribe(user, mediaType) {
        return new Promise((resolve, reject) => {
            this._client.subscribe(user, mediaType)
                .then(mRemoteTrack => {
                    console.debug(`subscribe success user=${user.uid}, mediaType=${mediaType}`)
                    resolve(mRemoteTrack)
                })
                .catch(e => {
                    console.debug(`subscribe error user=${user.uid}, mediaType=${mediaType}`)
                })
        })
    }

    getDevices() { //use-deviceで使われている
        return new Promise((resolve, reject) => {
            if (!this._client) {
                this.createClient()
            }
            console.debug('getDevices()')
            AgoraRTC.getDevices().then(it => {
                resolve(it)
            })
        })
    }

    join(channel, token, UID) {
        return new Promise((resolve, reject) => {
            if (this._joined === true) {
                resolve(this._uid)
                return
            }
            this._client.enableDualStream();

            this._joined = true
            this._leave = false
            this._uid = 0
            console.debug('join appID: ' + appID + ',channel: ' + channel)
            this._client.join(appID, channel, token , UID).then(uid => {
                console.debug(
                    'join success, channel: ' + channel + ', uid: ' + uid
                )
                this._uid = uid
                this._joined = true
                resolve(uid)
            })
                .catch(e => {
                    this._joined = false
                    reject(e)
                    console.error('join error: ' + e)
                })
        })
    }

    leave() {
        return new Promise((resolve) => {
            if (this._leave === true) {
                resolve()
                return
            }

            console.debug('leave()')
            this._leave = true
            if (!this._client) return resolve()

            this._client.leave()
                .then(() => {
                    console.debug('leave() success')
                    this._joined = false
                    this._uid = null
                    resolve()
                })
                .catch((e) => {
                    console.error('leave failed: ' + e)
                })
        })
    }
}