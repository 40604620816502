import React from 'react'
import {makeStyles} from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import Box from '@material-ui/core/Box'
import {Link} from 'react-router-dom'
import { Typography } from '@mui/material'

const useStyles = makeStyles((theme) => ({
    menuTitle: {
        color: '#333333',
        textAlign: 'center',
        fontSize: 'h6.fontSize',
        position: 'relative',
        top: '7px'
    },
    menu: {
        margin: '0.25rem 0',
        position: 'relative',
        height: '39px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    line: {
        marginTop: '0.2rem',
        marginBottom: '0.5rem',
        borderBottom: '1px solid #EAEAEA'
    },
}))

export default function TermsCard() {
    const classes = useStyles()
    return (
        <Box flex="1" display="flex" flexDirection="column" height={"90vh"}>
            <Link to="/index" className="back-btn"/>
            <FormControl className={classes.menu}>
                <span className={classes.menuTitle}>利用規約</span>
            </FormControl>
            <Box className={classes.line} marginTop={3}></Box>
                <Box 
                    style={{overflowY: "auto"}}
                    flex="1"
                    display="flex"
                    flexDirection="column"
                    padding="0 1rem"
                    justifyContent="flex-start"
                >
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>この利用規約（以下，「本規約」といいます。）は，まごころAI製作所（以下，「当社」といいます。）がこのウェブサイト上で提供するサービス（以下，「本サービス」といいます。）の利用条件を定めるものです。ユーザーの皆さまには，本規約に従って，本サービスをご利用いただきます。</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>第1条（適用）</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>1.本規約は，ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。</Typography>
                    <Typography variant="caption" component="div" gutterBottom>2.当社は本サービスに関し，本規約のほか，ご利用にあたってのルール等，各種の定め（以下，「個別規定」といいます。）をすることがあります。これら個別規定はその名称のいかんに関わらず，本規約の一部を構成するものとします。</Typography>
                    <Typography variant="caption" component="div" gutterBottom>3.本規約の規定が前条の個別規定の規定と矛盾する場合には，個別規定において特段の定めなき限り，個別規定の規定が優先されるものとします。</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>第2条（利用登録）</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>1.本サービスにおいては、利用登録は必要ありません。利用するためには最低限Symbolのアカウントが必要になります</Typography>
                    <Typography variant="caption" component="div" gutterBottom>2.当社は，利用者に以下の事由があると判断した場合，アカウント制限を行う事があり，その理由については一切の開示義務を負わないものとします。</Typography>
                    <Typography variant="caption" component="div" gutterBottom>本規約に違反したことがあるアカウントである場合</Typography>
                    <Typography variant="caption" component="div" gutterBottom>その他，当社が利用相当でないと判断した場合</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>第3条（パスワードおよび秘密鍵の管理）</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>1.ユーザーは，自己の責任において，本サービスを使うために必要であるSSSのパスワードおよび秘密鍵を適切に管理するものとします。当社は一切管理を行いません</Typography>
                    <Typography variant="caption" component="div" gutterBottom>2.SSSのパスワードおよび秘密鍵の情報が第三者によって使用されたことによって生じた損害は，当社は一切の責任を負わないものとします。</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>第4条（利用料金および支払方法）</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>1.ユーザー（配信者）は，本サービスにおけるルームの予約時に定められた金額（XYM）を支払う必要があります</Typography>
                    <Typography variant="caption" component="div" gutterBottom>2.ユーザー（視聴者）は，本サービスにおける利用料金は発生しません。但しルーム内で配信者に対し直接投げ銭（XYM）を行う事は可能です</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>第5条（禁止事項）</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>1.ユーザーは，本サービスの利用にあたり，以下の行為をしてはなりません。</Typography>
                    <Typography variant="caption" component="div" gutterBottom>2.法令または公序良俗に違反する行為</Typography>
                    <Typography variant="caption" component="div" gutterBottom>3.犯罪行為に関連する行為</Typography>
                    <Typography variant="caption" component="div" gutterBottom>4.本サービスの内容等，本サービスに含まれる著作権，商標権ほか知的財産権を侵害する行為</Typography>
                    <Typography variant="caption" component="div" gutterBottom>5.当社，ほかのユーザー，またはその他第三者のサーバーまたはネットワークの機能を破壊したり，妨害したりする行為</Typography>
                    <Typography variant="caption" component="div" gutterBottom>6.本サービスによって得られた情報を商業的に利用する行為</Typography>
                    <Typography variant="caption" component="div" gutterBottom>7.当社のサービスの運営を妨害するおそれのある行為</Typography>
                    <Typography variant="caption" component="div" gutterBottom>8.不正アクセスをし，またはこれを試みる行為</Typography>
                    <Typography variant="caption" component="div" gutterBottom>9.他のユーザーに関する個人情報等を収集または蓄積する行為</Typography>
                    <Typography variant="caption" component="div" gutterBottom>10.不正な目的を持って本サービスを利用する行為</Typography>
                    <Typography variant="caption" component="div" gutterBottom>11.本サービスの他のユーザーまたはその他の第三者に不利益，損害，不快感を与える行為</Typography>
                    <Typography variant="caption" component="div" gutterBottom>12.他のユーザーに成りすます行為</Typography>
                    <Typography variant="caption" component="div" gutterBottom>13.当社が許諾しない本サービス上での宣伝，広告，勧誘，または営業行為</Typography>
                    <Typography variant="caption" component="div" gutterBottom>14.面識のない異性との出会いを目的とした行為</Typography>
                    <Typography variant="caption" component="div" gutterBottom>15.当社のサービスに関連して，反社会的勢力に対して直接または間接に利益を供与する行為</Typography>
                    <Typography variant="caption" component="div" gutterBottom>16.その他，当社が不適切と判断する行為</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>第6条（本サービスの提供の停止等）</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>当社は，以下のいずれかの事由があると判断した場合，ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。</Typography>
                    <Typography variant="caption" component="div" gutterBottom>1.本サービスにかかるコンピュータシステムの保守点検または更新を行う場合</Typography>
                    <Typography variant="caption" component="div" gutterBottom>2.地震，落雷，火災，停電または天災などの不可抗力により，本サービスの提供が困難となった場合</Typography>
                    <Typography variant="caption" component="div" gutterBottom>3.コンピュータまたは通信回線等が事故により停止した場合</Typography>
                    <Typography variant="caption" component="div" gutterBottom>4.その他，当社が本サービスの提供が困難と判断した場合</Typography>
                    <Typography variant="caption" component="div" gutterBottom>当社は，本サービスの提供の停止または中断により，ユーザーまたは第三者が被ったいかなる不利益または損害についても，一切の責任を負わないものとします。</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>第7条（利用制限）</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>1.当社は，ユーザーが以下のいずれかに該当する場合には，事前の通知なく，ユーザーに対して，本サービスの全部もしくは一部の利用を制限することができるものとします。</Typography>
                    <Typography variant="caption" component="div" gutterBottom>1.1.本規約のいずれかの条項に違反した場合</Typography>
                    <Typography variant="caption" component="div" gutterBottom>1.2.その他，当社が本サービスの利用を適当でないと判断した場合</Typography>
                    <Typography variant="caption" component="div" gutterBottom>2.当社は，本条に基づき当社が行った行為によりユーザーに生じた損害について，一切の責任を負いません。</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>第8条（退会）</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>本サービスはユーザーの情報を保持しないため退会処理はありません</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>第9条（保証の否認および免責事項）</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>当社は，本サービスに事実上または法律上の瑕疵（安全性，信頼性，正確性，完全性，有効性，特定の目的への適合性，セキュリティなどに関する欠陥，エラーやバグ，権利侵害などを含みます。）がないことを明示的にも黙示的にも保証しておりません。</Typography>
                    <Typography variant="caption" component="div" gutterBottom>当社は，本サービスに起因してユーザーに生じたあらゆる損害について、当社の故意又は重過失による場合を除き、一切の責任を負いません。ただし，本サービスに関する当社とユーザーとの間の契約（本規約を含みます。）が消費者契約法に定める消費者契約となる場合，この免責規定は適用されません。</Typography>
                    <Typography variant="caption" component="div" gutterBottom>前項ただし書に定める場合であっても，当社は，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害のうち特別な事情から生じた損害（当社またはユーザーが損害発生につき予見し，または予見し得た場合を含みます。）について一切の責任を負いません。また，当社の過失（重過失を除きます。）による債務不履行または不法行為によりユーザーに生じた損害の賠償は，ユーザーから当該損害が発生した月に受領した利用料の額を上限とします。</Typography>
                    <Typography variant="caption" component="div" gutterBottom>当社は，本サービスに関して，ユーザーと他のユーザーまたは第三者との間において生じた取引，連絡または紛争等について一切責任を負いません。</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>第10条（サービス内容の変更等）</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>当社は，ユーザーへの事前の告知をもって、本サービスの内容を変更、追加または廃止することがあり、ユーザーはこれを承諾するものとします。</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>第11条（利用規約の変更）</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>1.当社は以下の場合には、ユーザーの個別の同意を要せず、本規約を変更することができるものとします。</Typography>
                    <Typography variant="caption" component="div" gutterBottom>1.1.本規約の変更がユーザーの一般の利益に適合するとき。</Typography>
                    <Typography variant="caption" component="div" gutterBottom>1.2.本規約の変更が本サービス利用契約の目的に反せず、かつ、変更の必要性、変更後の内容の相当性その他の変更に係る事情に照らして合理的なものであるとき。</Typography>
                    <Typography variant="caption" component="div" gutterBottom>2.当社はユーザーに対し、前項による本規約の変更にあたり、事前に、本規約を変更する旨及び変更後の本規約の内容並びにその効力発生時期を通知します。</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>第12条（個人情報の取扱い）</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>1.当社は個人情報に該当するもの（ルーム内のチャットおよびビデオ通信を含む）を保持致しません</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>第13条（通知または連絡）</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>ユーザーと当社との間の通知または連絡は，本WEBページにて行い、個別に必要な場合は問い合わせ窓口（hossiii0117@gmail.com）へご連絡下さい</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>第14条（権利義務の譲渡の禁止）</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>ユーザーは，当社の書面による事前の承諾なく，利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し，または担保に供することはできません。</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>第15条（準拠法・裁判管轄）</Typography>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>1.本規約の解釈にあたっては，日本法を準拠法とします。</Typography>
                    <Typography variant="caption" component="div" gutterBottom>2.本サービスに関して紛争が生じた場合には，当社の本店所在地を管轄する裁判所を専属的合意管轄とします。</Typography>
                    <br/>
                    <br/>
                    <Typography variant="caption" component="div" gutterBottom>以上</Typography>
                    <br/>
                    <br/>
                    <Typography variant="body1" component="div" gutterBottom>初版 2022/05/11</Typography>
                    <br/><br/><br/><br/><br/><br/><br/><br/><br/>
                </Box>
        </Box>
    )
}
